import React from 'react';
import { Link } from 'react-router-dom';

const FooterMainNav = ({ list, dict }) => {
	return (
		<div className="footer__main-nav">
			<ul className="footer__main-nav__list">
				{list.map(listItem => <li className="footer__main-nav__list-item" key={listItem.id}>
					<Link to={`${listItem.link}`} className="footer__main-nav__list-item__link">
						{dict[`footer.nav_list.${listItem.name}`]}
					</Link>
				</li>)}
			</ul>
		</div>
	)
}

export default FooterMainNav;
