const initialState = {
	textData: [],
	error: null,
};

export default function manageText(state = initialState, action) {
	switch (action.type) {
		case 'RECEIVE_NEW_TEXT_DATA':
			return {
				...state,
				textData: action.data
			}

		case 'RECEIVE_LOAD_MANAGE_TEXT_LIST':
			return {
				...state,
				textData: action.data
			};
		case 'SET_TEXT_ERROR':
			return {
				...state,
				error: action.err
			};
		case 'RESET_TEXT_ERROR':
			return {
				...state,
				error: null
			};
		case 'RESET_TEXT':
			return {
				...state,
				textData: []
			};
		default:
			return state
	}
}
