
import { createContext } from 'react';

export const LangContext = createContext();
export const PathContext = createContext();
export const AnnotationContext = createContext();

export const HighlightContext = createContext();

export const EventContext = createContext([
    (_event, _cb) => { }, // subscribe
    (_event, _cb) => { }, // unsubscribe
    (_event, _payload) => { }, // dispatch
]);


export const ComparisonContext = createContext();
