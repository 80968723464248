import { setPageError, requestLoadApp } from 'actions';
import { history } from 'helpers/history.js';

const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';
export const receiveUserData = (data) => ({ type: RECEIVE_USER_DATA, isLoading: false, data });

const SET_USER_ERROR = 'SET_USER_ERROR';
export const setUserError = (err) => ({ type: SET_USER_ERROR, isLoading: false, err });

const RESET_USER_ERROR = 'RESET_USER_ERROR';
export const resetUserError = () => ({ type: RESET_USER_ERROR });

export const login = (values) => {
    const login = values.username;
    const password = values.password;

    return (dispatch, getState) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': getState().app.CSRFToken
            },
            body: JSON.stringify({ login, password })
        };

        dispatch(requestLoadApp())

        fetch(`/api/auth/login`, requestOptions)
            .then(handleResponse)
            .then(user => {
                if (user.error) {
                    dispatch(setUserError(user.error));
                }
                if (!user.error) {
                    localStorage.setItem('user', JSON.stringify(user));
                    dispatch(receiveUserData(user))
                    history.push('/');
                    // dispatch(setAppAlert('login_success'));
                }
            })
            .catch(err => dispatch(setPageError(err)));;
    }
}

export const logout = () => {
    return (dispatch) => {
        dispatch(requestLoadApp())

        fetch(`/api/auth/logout`)
            .then(handleResponse)
            .then(user => {
                localStorage.removeItem('user');
                dispatch(receiveUserData(user))
                history.push('/');
                // dispatch(setAppAlert('logout_success'));
            })
            .catch(err => dispatch(setPageError(err)));;
    }
}

export const checkLoggedIn = () => {
    return (dispatch) => {
        fetch(`/api/auth/is-logged-in`)
            .then(handleResponse)
            .then(user => {
                if (user && !(Object.keys(user).length === 0 && user.constructor === Object)) {
                    localStorage.setItem('user', JSON.stringify(user));
                } else {
                    localStorage.removeItem('user');
                }
                dispatch(receiveUserData(user))
            })
            .catch(err => dispatch(setPageError(err)));
    }
}

const handleResponse = (response) => {
    return response
        .text()
        .then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    logout();
                    window.location.reload();
                }

                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
}
