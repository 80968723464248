import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withContexts from 'helpers/contextsMerge'
import * as Actions from 'actions/index';
import { Formik, Field, Form } from 'formik';

import Loading from 'components/Loading.js';
import SelectField from 'components/form/SelectField';
import FileUploadField from 'components/form/FileUploadField.js';

class ModalAddNewText extends Component {
	componentDidMount() {
		const { actions } = this.props;
		actions.loadTextsNames();
	}

	submitForm = (values) => {
		const { actions } = this.props;
		actions.addNewText(values);
	}

	componentWillUnmount() {
		const { actions } = this.props;
		actions.resetTextsError();
	}

	render() {
		const { langContext: {
			dict
		}, textsNames, actions, error } = this.props;

		if (!textsNames || !textsNames.length) {
			return <Loading />;
		}

		const initialValues = {
			code: textsNames[0].code,
			file: ''
		};

		return (
			<div className="modal--form__inner">
				<div className="modal--form__header">
					{dict[`manage_texts.new_text`]}
				</div>
				<div className="modal--form__content">
					<Formik
						initialValues={initialValues}
						validate={values => {
							let errors = {};
							if (!values.code) {
								errors.code = dict[`form.required`];
							}
							if (!values.file) {
								errors.file = dict[`form.required`];
							}

							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							this.submitForm(values);
							setSubmitting(false);
						}}>
						{({ resetForm, isSubmitting, errors, setFieldValue }) => (
							<Form>
								{error && <div className="modal--form__error">{error}</div>}
								{errors.form && <div className="modal--form__error">{errors.form}</div>}
								<div className="modal--form__field">
									<Field
										name={'code'}
										component={SelectField}
										options={textsNames.map(item => { return { value: item.code, label: item.name } })}
										handleChange={(value) => {
											setFieldValue('code', value.value)
										}}
                                        dict={dict}
									/>
								</div>
								<div className="modal--form__field">
									<Field type="file" name="file"
										label={dict[`manage_text.form.file`]}
										component={FileUploadField}
										handleUpload={(event) => {
											setFieldValue("file", event.currentTarget.files[0]);
										}}
									/>
								</div>
								<div className="modal--form__buttons">
									<button
										onClick={() => {
											resetForm();
											actions.resetUsersError();
											actions.resetAppAlert();
										}}
										className="modal--form__button--clear" type="button">
										{dict[`form.cancel`]}
									</button>
									<button type="submit" disabled={isSubmitting} className="modal--form__button">
										{dict[`form.create`]}
									</button>
								</div>
							</Form>)}
					</Formik>
				</div>
			</div>
		);
	}
}

ModalAddNewText.propTypes = {
	error: PropTypes.string,
	textsNames: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string,
		name: PropTypes.string,
		active: PropTypes.bool,
		ord: PropTypes.number,
	}))
};

const mapStateToProps = state => ({
	textsNames: state.manageTexts.textsNames,
	error: state.manageTexts.error,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withContexts(connect(mapStateToProps, mapDispatchToProps)(ModalAddNewText));
