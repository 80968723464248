const initialState = {
    tabs: [],
    tabData: '',
    insertImage: false
};
  
export default function textTabs(state = initialState, action) {
    switch (action.type) {
        case 'SET_TABS':
            return {
                ...state,
                tabs: action.data
            };   
        case 'SET_TABS_ERROR':
            return {
                ...state,
                error: action.data
            };   
        case 'RESET_TABS_ERROR':
            return {
                ...state,
                error: null
            };
        case 'SET_TAB_DATA':
            return {
                ...state,
                // tabData: JSON.parse(action.data)
                tabData: action.data
            };
        case 'RECEIVE_TAB_IMAGE_BASE':
            return {
                ...state,
                imageTab: action.data,
                insertImage: true
            };   
        case 'RESET_ADD_IMAGE':
            return {
                ...state,
                insertImage: false
            };   
        default:
          return state
    }
}
  