import React from 'react';

const HeaderMenuOpen = ({toggleMenu}) => {
  return (
    <button className={`header__top__menu-toggle`} onClick={() => toggleMenu()}>
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
}

export default React.memo(HeaderMenuOpen);
