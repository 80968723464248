const initialState = {
  texts: [],
  openedText: {
    cards: [],
    verses: []
  }
};

export default function texts(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_LOAD_TEXTS':
      return {
        ...state,
        texts: action.data
      };   
    case 'SET_OPENED_TEXT':
      return {
        ...state,
        openedText: {...state.openedText, ...action.data}
      };   
    case 'RESET_OPENED_TEXT':
      return {
        ...state,
        openedText: {
          cards: [],
          verses: []
        }
      };   
    case 'RECEIVE_LOAD_OPENED_TEXT_CARDS':
      return {
        ...state,
        openedText: {...state.openedText, cards: action.data}
      };   
    case 'RECEIVE_LOAD_OPENED_TEXT_VERSES':
      const isVerseNotSet = !state.openedText.verses.filter(verse => verse.cardAddress === action.cardAddress).length;
      return {
        ...state,
        openedText: {
                      ...state.openedText, 
                      verses: isVerseNotSet ? 
                        [
                          ...state.openedText.verses, 
                          {cardAddress: action.cardAddress, list: action.data}
                        ] 
                          : state.openedText.verses
                          // state.openedText.verses.map(verse => verse.cardAddress === action.cardAddress ?
                          //                                         {
                          //                                           ...verse,
                          //                                           list: action.data
                          //                                         } :verse )
                    }
      };   
    default:
      return state
  }
}
