import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({ children, ...rest }) => (
    <Route {...rest} render={props => (
        !localStorage.getItem('user')
            ? <>{children}</>
            : <Redirect to={{ pathname: '/users', state: { from: props.location } }} />
    )} />
)

export default PublicRoute;