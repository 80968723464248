import React from 'react';

import { useDispatch } from 'react-redux';

import HeaderTopFonts from 'containers/header/HeaderTopFonts.js';
import HeaderTopContrast from 'containers/header/HeaderTopContrast.js';
import HeaderTopLang from 'containers/header/HeaderTopLang.js';
import HeaderUserPanel from 'containers/header/HeaderUserPanel.js';
import HeaderSearchPanel from 'containers/header/HeaderSearchPanel.js';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import * as actions from 'actions'

const HeaderTopNav = (props) => {
    const { setLang, logout, user, currentKey, navItems, isSearchOpened, toggleSearch, changeFontSize, fontSize, setContrast, isContrast, dict, currentFilters, langs } = props;
    const dispatch = useDispatch();

    const setSearchKey = React.useCallback((key) => {
        dispatch(actions.setSearchParams({ q: key, filters: currentFilters, page: 1 }))
    }, [actions, dispatch]);

    return (
        <nav className="header__top__nav">
            <div className="header__main-nav--mobile">
                <ul className="header__main-nav__list">
                    {navItems.map(listItem => <li className="header__main-nav__list-item" key={listItem.id}>
                        <NavLink
                            to={`${listItem.link[0]}`}
                            className={"header__main-nav__list-item__button" + ((listItem.disabled) ? " disabled" : "")}
                            activeClassName="active"
                            title={dict[`header.nav_list.${listItem.name}`]}>
                            <span>{dict[`header.nav_list.${listItem.name}`]}</span>
                            <span className="header__main-nav__active-bar"></span>
                        </NavLink>
                    </li>)}
                </ul>
            </div>
            <div className="header__part-container">
                <div className="header__top__nav__links">
                    <Link to={`/about`} className="header__top__nav__links__link" title={dict[`header.top.about`]}>
                        {dict[`header.top.about`]}
                    </Link>
                    <Link to={`/elabo`} className="header__top__nav__links__link" title={dict[`header.top.descs`]}>
                        {dict[`header.top.descs`]}
                    </Link>
                </div>
            </div>
            <div className="header__part-container">
                <HeaderSearchPanel
                    setSearchKey={setSearchKey}
                    currentKey={currentKey}
                    isSearchOpened={isSearchOpened}
                    dict={dict}
                />
                <button onClick={() => toggleSearch()} className="header__top__search__toggle">
                    <span className="icon-search"></span>
                </button>
            </div>
            <div className="header__part-container">
                <div className="header__top__buttons">
                    <HeaderTopFonts changeFontSize={changeFontSize} fontSize={fontSize} isContrast={isContrast} dict={dict} />
                    <HeaderTopContrast setContrast={setContrast} isContrast={isContrast} dict={dict} />
                    <HeaderTopLang setLang={setLang} langs={langs} dict={dict} />
                </div>
            </div>
            <HeaderUserPanel logout={logout} user={user} dict={dict} />
        </nav>
    )
}

export default React.memo(HeaderTopNav);
