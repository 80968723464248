import getCSRFToken from 'helpers/CSRFToken.js';

const initialState = {
    title: '',
    langs: [],
    currentLang: '',
    dict: {},
    isAppError: false,
    isContrast: false,
    CSRFToken: getCSRFToken(),
    alerts: {isModal: false, modalName: null, modalClassName: null},
    isLoading: false
};

export default function app(state = initialState, action) {
    switch (action.type) {
        case 'SET_APP_TOKEN':
            return {
                ...state,
                CSRFToken: action.token
            };

        case 'RECEIVE_APP_LANG':
            const currentLang = action.data.langs.filter(lang => lang.active)[0];
            return {
                ...state,
                langs: action.data.langs,
                currentLang: currentLang ? currentLang.lang : state.currentLang,
                dict: currentLang ? action.data[currentLang.lang] : state.dict
            };
        case 'RECEIVE_APP_TITLE':
            return {
                ...state,
                title: action.data.content
            };
        case 'SET_APP_LOADING':
            return {
                ...state,
                isLoading: true
            };
        case 'RESET_APP_LOADING':
            return {
                ...state,
                isLoading: false
            };
        case 'SET_NEW_LANG':
            return {
                ...state,
                langs: state.langs.map(lang => {return {...lang, active: lang.id === action.newLang.id}})
            };
        case 'SET_APP_ERROR':
            return {
                ...state,
                isAppError: true
            };
        case 'RESET_APP_ERROR':
            return {
                ...state,
                isAppError: false
            };
        case 'SET_CONTRAST':
            return {
                ...state,
                isContrast: !state.isContrast
            };

        case 'SET_APP_ALERT':
            return {
                ...state,
                alerts: {...state.alerts,
                    isModal: true,
                    [`${action.alertType}`]: true,
                    modalName: (action.modalName) ? action.modalName : null,
                    modalClassName: ( action.modalClassName ) ? action.modalClassName: null
                }
            };
        case 'RESET_APP_ALERT':
            return {
                ...state,
                alerts: {isModal: false, modalName: null, modalClassName: null}
            };
        default:
            return state
    }
}
