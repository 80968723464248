import fetch from 'isomorphic-fetch';

import { modalAdditionalInfo, sourceNewPackageModal, sourceChooseVerses, sourceNewFolderModal, sourceMoveDown, sourceMoveUp, getSourceNodes, getSourceNodesChildren, sourcesUpload, sourceEditFolder, sourceAddFolder, sourceDeleteNode, sourceDeleteModal, getSourceResult } from './source.actions.js';

export { modalAdditionalInfo, sourceNewPackageModal, sourceChooseVerses, sourceNewFolderModal, sourceMoveDown, sourceMoveUp, getSourceNodes, getSourceNodesChildren, sourcesUpload, sourceEditFolder, sourceAddFolder, sourceDeleteNode, sourceDeleteModal, getSourceResult }


//APP

const REQUEST_LOAD_APP = 'REQUEST_LOAD_APP';
export const requestLoadApp = () => ({ type: REQUEST_LOAD_APP, isLoading: true });

const SET_APP_ERROR = 'SET_APP_ERROR';
export const setAppError = (err) => ({ type: SET_APP_ERROR, isLoading: false, err });

const RESET_APP_ERROR = 'RESET_APP_ERROR';
export const resetAppError = (err) => ({ type: RESET_APP_ERROR, isLoading: false, err });

const SET_APP_LOADING = 'SET_APP_LOADING';
export const setAppLoading = () => ({ type: SET_APP_LOADING });

const RESET_APP_LOADING = 'RESET_APP_LOADING';
export const resetAppLoading = () => ({ type: RESET_APP_LOADING });

const RECEIVE_APP_TITLE = 'RECEIVE_APP_TITLE';
export const receiveAppTitle = (data) => ({ type: RECEIVE_APP_TITLE, isLoading: false, data });

export const getAppTitle = () => {
	return (dispatch) => {
		fetch('/api/app-title', { accept: "application/json" }).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		})
			.then(json => dispatch(receiveAppTitle(json)))
			.catch(err => dispatch(setAppError(err)));
	}
};

const RECEIVE_APP_LANG = 'RECEIVE_APP_LANG';
export const receiveAppLang = (data) => ({ type: RECEIVE_APP_LANG, isLoading: false, data });

const SET_APP_TOKEN = 'SET_APP_TOKEN';
export const setAppToken = (data) => ({ type: SET_APP_TOKEN, token: data });

export const getAppLang = () => {
	return (dispatch) => {
		fetch('/api/language', { accept: "application/json" }).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		})
			.then(json => dispatch(receiveAppLang(json)))
			.catch(err => dispatch(setAppError(err)));
	}
};

export const setNewLang = newLang => {
	return (dispatch, getState) => {
		if (getState().app.currentLang !== newLang) {
			dispatch(requestLoadApp())
			fetch('/api/language', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ lang: newLang })
			}).then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			})
				.then(json => dispatch(receiveAppLang(json)))
				.catch(err => dispatch(setAppError(err)));
		}
	}
};

const SET_CONTRAST = 'SET_CONTRAST';
export const setContrast = () => ({ type: SET_CONTRAST });

const SET_APP_ALERT = 'SET_APP_ALERT';
export const setAppAlert = ( alertType, modalName=null, modalClassName=null ) => {
	return ({ type: SET_APP_ALERT, isLoading: false, alertType, modalName, modalClassName });
}

const RESET_APP_ALERT = 'RESET_APP_ALERT';
export const resetAppAlert = () => ({ type: RESET_APP_ALERT });

const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const setBreadCrumbs = (data) => ({ type: SET_BREADCRUMBS, isLoading: false, data })

const UPDATE_BREADCRUMBS = 'UPDATE_BREADCRUMBS';
export const updateBreadCrumbs = (data) => ({ type: UPDATE_BREADCRUMBS, isLoading: false, data });

const RESET_BREADCRUMBS = 'RESET_BREADCRUMBS';
export const resetBreadCrumbs = () => ({ type: RESET_BREADCRUMBS });


const SET_ALTERNATIVE_PAGE_TITLE = 'SET_ALTERNATIVE_PAGE_TITLE';
export const setAlternativePageTitle = (data) => ({type: SET_ALTERNATIVE_PAGE_TITLE, data});

const RESET_ALTERNATIVE_PAGE_TITLE = 'RESET_ALTERNATIVE_PAGE_TITLE';
export const resetAlternativePageTitle = () => ({type: RESET_ALTERNATIVE_PAGE_TITLE});


const SET_PAGE_TITLE_COMMENT = 'SET_PAGE_TITLE_COMMENT';
export const setPageTitleComment = (data) => ({type: SET_PAGE_TITLE_COMMENT, data});

const RESET_PAGE_TITLE_COMMENT = 'RESET_PAGE_TITLE_COMMENT';
export const resetPageTitleComment = () => ({type: RESET_PAGE_TITLE_COMMENT});

//PAGE

const SET_PAGE_ERROR = 'SET_PAGE_ERROR';
export const setPageError = (err) => ({ type: SET_PAGE_ERROR, isLoading: false, err });

const RESET_PAGE_ERROR = 'RESET_PAGE_ERROR';
export const resetPageError = () => ({ type: RESET_PAGE_ERROR });

//TEXTS
const RECEIVE_LOAD_TEXTS = 'RECEIVE_LOAD_TEXTS';
export const receiveLoadTexts = (data) => ({ type: RECEIVE_LOAD_TEXTS, isLoading: false, data });

const SET_OPENED_TEXT = 'SET_OPENED_TEXT';
export const setOpenedText = (data) => ({ type: SET_OPENED_TEXT, isLoading: false, data });

const RESET_OPENED_TEXT = 'RESET_OPENED_TEXT';
export const resetOpenedText = (data) => ({ type: RESET_OPENED_TEXT, isLoading: false, data });

export const loadTexts = () => {
	return (dispatch) => {
		dispatch(requestLoadApp())
		fetch('/api/texts/texts-names', { accept: "application/json" }).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		}).then(json => {
			dispatch(receiveLoadTexts(json))
			dispatch(resetPageError())
		}).catch(err => dispatch(setPageError(err)));
	}
};

const RECEIVE_LOAD_OPENED_TEXT_CARDS = 'RECEIVE_LOAD_OPENED_TEXT_CARDS';
export const receiveLoadOpenedTextCards = (data) => ({ type: RECEIVE_LOAD_OPENED_TEXT_CARDS, isLoading: false, data });

export const loadOpenedTextCards = (code) => {
	return (dispatch) => {
		dispatch(requestLoadApp())
		fetch(`/api/texts/transcription/list-cards-numbers/${code}`,
			{ accept: "application/json" }).then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				dispatch(receiveLoadOpenedTextCards(json))
				dispatch(resetPageError())
			}).catch(err => dispatch(setPageError(err)));
	}
};

const RECEIVE_LOAD_OPENED_TEXT_VERSES = 'RECEIVE_LOAD_OPENED_TEXT_VERSES';
export const receiveLoadOpenedTextVerses = (cardAddress, data) => ({ type: RECEIVE_LOAD_OPENED_TEXT_VERSES, isLoading: false, cardAddress, data });

export const loadOpenedTextVerses = (code, cardAddress) => {
	return (dispatch) => {
		dispatch(requestLoadApp())
		fetch(`api/texts/transcription/list-verses-numbers/${code}/${cardAddress}`,
			{ accept: "application/json" }).then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				dispatch(receiveLoadOpenedTextVerses(cardAddress, json))
				dispatch(resetPageError())
			}).catch(err => dispatch(setPageError(err)));
	}
};

//TEXT

const RECEIVE_LOAD_TEXT = 'RECEIVE_LOAD_TEXT';
export const receiveLoadText = (data) => ({ type: RECEIVE_LOAD_TEXT, isLoading: false, data });

export const loadText = (
	code,
	startCardNumber,
	endCardNumber,
	endVerseNo,
	startVerseNo,
	mode,
	grammar = false
) => {
	return (dispatch) => {
		dispatch(requestLoadApp())
		fetch(`/api/texts/text/${code}/${startCardNumber}/${startVerseNo}/${endCardNumber}/${endVerseNo}?mode=${mode}&grammar=${grammar}`, { accept: "application/json" }).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		}).then(json => {
			dispatch(receiveLoadText(json))
			// dispatch(updateBreadCrumbs([{ name: json.name }]))
			dispatch(resetPageError())
		}).catch(err => dispatch(setPageError(err)));
	}
};

//MANAGE-TEXTS
const RECEIVE_LOAD_MANAGE_TEXTS_LIST = 'RECEIVE_LOAD_MANAGE_TEXTS_LIST';
export const receiveLoadManageTextsList = (data) => ({ type: RECEIVE_LOAD_MANAGE_TEXTS_LIST, isLoading: false, data });

export const loadManageTextsList = () => {
	return (dispatch) => {
		dispatch(requestLoadApp())
		fetch('/api/texts/active-texts-names', { accept: "application/json" }).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		}).then(json => {
			dispatch(receiveLoadManageTextsList(json))
			dispatch(resetPageError())
		}).catch(err => dispatch(setPageError(err)));
	}
};

const RECEIVE_TEXTS_NAMES = 'RECEIVE_TEXTS_NAMES';
export const receiveTextsNames = (data) => ({ type: RECEIVE_TEXTS_NAMES, isLoading: false, data });

export const loadTextsNames = () => {
	return (dispatch) => {
		dispatch(requestLoadApp())
		fetch('/api/texts/list-texts-names', { accept: "application/json" }).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		}).then(json => {
			dispatch(receiveTextsNames(json))
			dispatch(resetPageError())
		}).catch(err => dispatch(setPageError(err)));
	}
};

const SET_TEXTS_ERROR = 'SET_TEXTS_ERROR';
export const setTextsError = (err) => ({ type: SET_TEXTS_ERROR, isLoading: false, err });

const RESET_TEXTS_ERROR = 'RESET_TEXTS_ERROR';
export const resetTextsError = () => ({ type: RESET_TEXTS_ERROR, isLoading: false });

export const addNewText = (text) => {
	return (dispatch, getState) => {
		const formData = new FormData();
		formData.append('zip', text.file);

		fetch(`/api/import/add-transcription/${text.code}`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'X-CSRF-TOKEN': getState().app.CSRFToken
			},
			body: formData
		}).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		})
			.then(json => {
				if (json.error) {
					dispatch(setTextsError(json.error));
				} else {
					// dispatch(setAppAlert('add_text_success'));
					dispatch(receiveLoadManageTextsList(json));
					dispatch(resetAppAlert());
				}
			})
			.catch(err => {
				dispatch(setPageError(err));
				dispatch(resetAppAlert());
			});
	}
};

//MANAGE-TEXT

const RECEIVE_LOAD_MANAGE_TEXT_LIST = 'RECEIVE_LOAD_MANAGE_TEXT_LIST';
export const receiveLoadManageTextList = (data) => ({ type: RECEIVE_LOAD_MANAGE_TEXT_LIST, isLoading: false, data });

const RECEIVE_NEW_TEXT_DATA = 'RECEIVE_NEW_TEXT_DATA';
export const receiveNewTextData = (data) => ({ type: RECEIVE_NEW_TEXT_DATA, isLoading: false, data });

const RESET_TEXT = 'RESET_TEXT';
export const resetText = () => ({ type: RESET_TEXT, isLoading: false });

export const loadManageTextList = (code) => {
	return (dispatch) => {
		dispatch(requestLoadApp())
		fetch(`/api/import/text-status/${code}`, { accept: "application/json" }).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		}).then(json => {
			dispatch(receiveLoadManageTextList(json))
			dispatch(updateBreadCrumbs([{ name: json.name }]))
			dispatch(resetPageError())
		}).catch(err => dispatch(setPageError(err)));
	}
};

const SET_TEXT_ERROR = 'SET_TEXT_ERROR';
export const setTextError = (err) => ({ type: SET_TEXT_ERROR, isLoading: false, err });

const RESET_TEXT_ERROR = 'RESET_TEXT_ERROR';
export const resetTextError = () => ({ type: RESET_TEXT_ERROR, isLoading: false });

export const updateText = (file, code) => {
	return (dispatch, getState) => {
		const formData = new FormData();
		dispatch(requestLoadApp())
		formData.append('zip', file);

		fetch(`/api/import/update-transcription/${code}`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'X-CSRF-TOKEN': getState().app.CSRFToken
			},
			body: formData
		}).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		})
			.then(json => {
				if (json.error) {
					dispatch(setTextError(json.error));
				} else {
					dispatch(receiveLoadManageTextList(json))
					dispatch(resetAppAlert());
				}
			})
			.catch(err => {
				dispatch(setPageError(err));
				dispatch(resetAppAlert());
			});
	}
};

export const uploadTrans = (file, code, isUpdate = false) => {
	return (dispatch, getState) => {
		const formData = new FormData();
		formData.append('zip', file);
		dispatch(requestLoadApp())

		const address = (isUpdate ? `/api/import/update-transliteration/${code}` : `/api/import/add-transliteration/${code}`)

		fetch(address, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'X-CSRF-TOKEN': getState().app.CSRFToken
			},
			body: formData
		}).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		})
			.then(json => {
				if (json.error) {
					dispatch(setTextError(json.error));
				} else {
					dispatch(receiveLoadManageTextList(json))
					dispatch(resetAppAlert());
				}
			})
			.catch(err => {
				dispatch(setPageError(err));
				dispatch(resetAppAlert());
			});
	}
};

// export const updateTrans = (file, code) => {
// 	return (dispatch, getState) => {
// 		const formData = new FormData();
// 		formData.append('zip', file);
// 		dispatch(requestLoadApp())

// 		fetch(`/api/import/update-transliteration/${code}`, {
// 			method: 'POST',
// 			headers: {
// 				'Accept': 'application/json',
// 				'X-CSRF-TOKEN': getState().app.CSRFToken
// 			},
// 			body: formData
// 		}).then(response => {
// 			if (response.status >= 200 && response.status < 300) {
// 				return response.json();
// 			}
// 			throw new Error(response.status);
// 		})
// 			.then(json => {
// 				if (json.error) {
// 					dispatch(setTextError(json.error));
// 				} else {
// 					dispatch(receiveLoadManageTextList(json))
// 					dispatch(resetAppAlert());
// 				}
// 			})
// 			.catch(err => {
// 				dispatch(setPageError(err));
// 				dispatch(resetAppAlert());
// 			});
// 	}
// };

// SOURCES and MANAGE-SOURCES




//CARDS

const RECEIVE_CARDS = 'RECEIVE_CARDS';
export const receiveCards = (data) => ({ type: RECEIVE_CARDS, isLoading: false, data });

const RESET_CARDS = 'RESET_CARDS';
export const resetCards = (data) => ({ type: RESET_CARDS, isLoading: false, data });

export const loadCards = ({code, type, designation, page = 2}) => {
	return (dispatch) => {
		dispatch(requestLoadApp());
		fetch(`/api/texts/${type}/cards-numbers/${code}${designation ? `/${designation}` : ''}?page=${page}`,
			{ accept: "application/json" }).then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				dispatch(receiveCards(json))
				dispatch(updateBreadCrumbs(
					json.designation ?
						[{ name: json.name, link: `/manage-texts/text?code=${json.code}` }, { name: json.textType.toLowerCase(), link: `/manage-texts/other-transcript?code=${json.code}` }, { name: json.designation }]
						: [{ name: json.name, link: `/manage-texts/text?code=${json.code}` }, { name: json.textType.toLowerCase() }]
				))
				dispatch(resetPageError())
			}).catch(err => dispatch(setPageError(err)));
	}
};

const RECEIVE_CARD = 'RECEIVE_CARD';
export const receiveCard = (data) => ({ type: RECEIVE_CARD, isLoading: false, data });

const RESET_CARD = 'RESET_CARD';
export const resetCard = (data) => ({ type: RESET_CARD, isLoading: false, data });

const RESET_TEI = 'RESET_TEI';
export const resetTei = (data) => ({ type: RESET_TEI, isLoading: false, data });

export const loadCard = ({code, textType, cardAddress, designation = null}) => {
	return (dispatch) => {
		dispatch(requestLoadApp());
        const address = `/api/texts/${textType}/card-content/${code}/${designation ? `${designation}/` : ''}${cardAddress}`;
		fetch(address,
			{ accept: "application/json" }).then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				dispatch(receiveCard(json))
				const type = json.textType.replace('_', '-').toLowerCase()
				dispatch(
					json.designation ?
						updateBreadCrumbs([{ name: json.name, link: `/manage-texts/text?code=${json.code}` },
						{ name: json.textType.toLowerCase(), link: `/manage-texts/${type === 'other-transcript' ? `${type}?` : `cards?type=${type}&`}code=${json.code}` },
						{ name: json.designation, link: `/manage-texts/${type === 'other-transcript' ? `${type}?` : `cards?type=${type}&`}code=${json.code}&designation=${json.designation}` },
						{ name: json.cardAddress },
						])
						: updateBreadCrumbs([{ name: json.name, link: `/manage-texts/text?code=${json.code}` },
						{ name: json.textType.toLowerCase(), link: `/manage-texts/${type === 'other-transcript' ? `${type}?` : `cards?type=${type}&`}code=${json.code}` },
						{ name: json.cardAddress },
						]))
				dispatch(resetPageError())
			}).catch(err => dispatch(setPageError(err)));
	}
};

const SET_CARD_ERROR = 'SET_CARD_ERROR';
export const setCardError = (data) => ({ type: SET_CARD_ERROR, isLoading: false, data });

const RESET_CARD_ERROR = 'RESET_CARD_ERROR';
export const resetCardError = () => ({ type: RESET_CARD_ERROR, isLoading: false });

export const saveTei = (tei, code, textType, cardAddress, designation) => {
	return (dispatch, getState) => {
		dispatch(resetCardError())
		dispatch(requestLoadApp())

		fetch(`/api/import/${textType}/change-card-content/${code}/${designation ? `${designation}/` : ''}${cardAddress}`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded',
				'X-CSRF-TOKEN': getState().app.CSRFToken,
			},
			body: `content=${encodeURIComponent(tei)}`
		}).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		})
			.then(json => {
				if (json.error) {
					dispatch(setCardError(json.error));
				} else {
					dispatch(setAppAlert('update_card_success'));
					dispatch(receiveCard(json))
				}
			})
			.catch(err => {
				dispatch(setPageError(err));
			});
	}
};


//OTHER TRANS

const SET_TRANS_TO_DELETE = 'SET_TRANS_TO_DELETE';
export const setTransToDelete = (data) => ({ type: SET_TRANS_TO_DELETE, isLoading: false, data });

const SET_TRANS_TO_UPDATE = 'SET_TRANS_TO_UPDATE';
export const setTransToUpdate = (data) => ({ type: SET_TRANS_TO_UPDATE, isLoading: false, data });

const RECEIVE_OTHER_TRANS = 'RECEIVE_OTHER_TRANS';
export const receiveOtherTrans = (data) => ({ type: RECEIVE_OTHER_TRANS, isLoading: false, data });

export const loadOtherTrans = (code) => {
	return (dispatch) => {
		dispatch(requestLoadApp())
		fetch(`/api/import/other-transcripts/${code}`,
			{ accept: "application/json" }).then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				dispatch(receiveOtherTrans(json))
				dispatch(updateBreadCrumbs([{ name: json.name, link: `/manage-texts/text?code=${json.code}` }, { name: 'other_transcript' }]))
				dispatch(resetPageError())
			}).catch(err => dispatch(setPageError(err)));
	}
};

export const deleteOtherTrans = (code, designation) => {
	return (dispatch, getState) => {
		dispatch(requestLoadApp())
		dispatch(resetPageError())
		fetch(`/api/import/delete-other-transcript/${code}/${designation}`,
			{
				method: 'DELETE',
				headers: {
					'Accept': 'application/json',
					'X-CSRF-TOKEN': getState().app.CSRFToken,
				},
			}).then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				dispatch(receiveOtherTrans(json))
				dispatch(resetAppAlert())
			}).catch(err => dispatch(setPageError(err)));
	}
};

const SET_OTHER_TRANS_ERROR = 'SET_OTHER_TRANS_ERROR';
export const setOtherTransError = (data) => ({ type: SET_OTHER_TRANS_ERROR, isLoading: false, data });

const RESET_OTHER_TRANS_ERROR = 'RESET_OTHER_TRANS_ERROR';
export const resetOtherTransError = (data) => ({ type: RESET_OTHER_TRANS_ERROR, isLoading: false, data });

export const addNewOtherTrans = (val, code) => {
	return (dispatch, getState) => {
		dispatch(resetOtherTransError);
		const formData = new FormData();
		formData.append('zip', val.file);

		fetch(`/api/import/add-other-transcript/${code}/${val.name}`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'X-CSRF-TOKEN': getState().app.CSRFToken
			},
			body: formData
		}).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		})
			.then(json => {
				if (json.error) {
					dispatch(setOtherTransError(json.error));
				} else {
					dispatch(receiveOtherTrans({ items: json }))
					dispatch(resetAppAlert());
				}
			})
			.catch(err => {
				dispatch(setPageError(err));
				dispatch(resetAppAlert());
			});
	}
};
export const updateOtherTrans = (file, code, designation) => {
	return (dispatch, getState) => {
		const formData = new FormData();
		formData.append('zip', file);
		dispatch(requestLoadApp())

		fetch(`/api/import/update-other-transcript/${code}/${designation}`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'X-CSRF-TOKEN': getState().app.CSRFToken
			},
			body: formData
		}).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		})
			.then(json => {
				if (json.error) {
					dispatch(setOtherTransError(json.error));
				} else {
					dispatch(receiveOtherTrans(json))
					dispatch(resetAppAlert());
				}
			})
			.catch(err => {
				dispatch(setPageError(err));
				dispatch(resetAppAlert());
			});
	}
};

//USERS
const RECEIVE_USERS = 'RECEIVE_USERS';
export const receiveUsers = (data) => ({ type: RECEIVE_USERS, isLoading: false, data });

export const loadUsers = () => {
	return (dispatch) => {
		dispatch(requestLoadApp())
		fetch('/api/auth/users',
			{ accept: "application/json" })
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				dispatch(receiveUsers(json))
				dispatch(resetPageError())
			}).catch(err => dispatch(setPageError(err)));
	}
};

const RECEIVE_USERS_ROLES = 'RECEIVE_USERS_ROLES';
export const receiveUsersRoles = (data) => ({ type: RECEIVE_USERS_ROLES, isLoading: false, data });

export const loadRoles = () => {
	return (dispatch, getState) => {
		if (!getState().users.users.length) {
			fetch('/api/auth/user-roles',
				{ accept: "application/json" })
				.then(response => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					}
					throw new Error(response.status);
				}).then(json => {
					dispatch(receiveUsersRoles(json))
					dispatch(resetPageError())
				}).catch(err => dispatch(setPageError(err)));
		}
	}
};

export const changeUsersData = (login, fieldName, fieldVal) => {
	const getApiUrl = () => {
		switch (fieldName) {
			case 'email':
				return '/api/auth/change-email'
			case 'active':
				return '/api/auth/change-active'
			case 'role':
				return '/api/auth/change-role'
			case 'password':
				return '/api/auth/change-password'
			default:
				break;
		}
	}

	return (dispatch, getState) => {
		dispatch(resetUsersError())
		dispatch(requestLoadApp())
		fetch(getApiUrl(), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'X-CSRF-TOKEN': getState().app.CSRFToken
			},
			body: JSON.stringify(fieldName === 'password' ?
				{ login: login, [`password1`]: fieldVal['newPassword'], [`password2`]: fieldVal['repeatPassword'] }
				: { login: login, [`${fieldName}`]: fieldVal })
		}).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		})
			.then(json => {
				if (json.error) {
					dispatch(setUsersError(json.error));
				} else {
					dispatch(setAppAlert('users_success'));
					dispatch(loadUsers());
				}
			})
			.catch(err => {
				dispatch(setPageError(err));
				dispatch(resetAppAlert());
			});
	}
};

const SET_USER_TO_CHANGE = 'SET_USER_TO_CHANGE';
export const setUserToChange = (user) => ({ type: SET_USER_TO_CHANGE, user });

const RESET_USER_TO_CHANGE = 'RESET_USER_TO_CHANGE';
export const resetUserToChange = () => ({ type: RESET_USER_TO_CHANGE });

const SET_USERS_ERROR = 'SET_USERS_ERROR';
export const setUsersError = (err) => ({ type: SET_USERS_ERROR, isLoading: false, err });

const RESET_USERS_ERROR = 'RESET_USERS_ERROR';
export const resetUsersError = () => ({ type: RESET_USERS_ERROR, isLoading: false });

export const addNewUser = (user) => {
	return (dispatch, getState) => {
		dispatch(requestLoadApp())

		fetch('/api/auth/add-user', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'X-CSRF-TOKEN': getState().app.CSRFToken
			},
			body: JSON.stringify({ login: user.login, email: user.email, role: user.role, password1: user.newPassword, password2: user.repeatPassword })
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			})
			.then(json => {
				if (json.error) {
					dispatch(setUsersError(json.error));
				} else {
					dispatch(setAppAlert('add_users_success'));
					dispatch(loadUsers());
				}
			})
			.catch(err => {
				dispatch(setPageError(err));
				dispatch(resetAppAlert());
			});
	}
};

//TABS

const SET_TABS = 'SET_TABS';
export const setTabs = (data) => ({ type: SET_TABS, isLoading: false, data });

export const loadTabs = (type, id) => {
	return (dispatch) => {
		dispatch(requestLoadApp())

		fetch(`/api/flat/titles/${type}`,
			{ accept: "application/json" })
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				dispatch(setTabs(json))
				dispatch(resetPageError())
				dispatch(loadTabData(type, id))
			}).catch(err => dispatch(setPageError(err)));
	}
};

const SET_TAB_DATA = 'SET_TAB_DATA';
export const setTabData = (data) => ({ type: SET_TAB_DATA, isLoading: false, data });

export const loadTabData = (type, id) => {
	return (dispatch) => {
		dispatch(requestLoadApp())

		fetch(`/api/flat/content/${type}/${id}`,
			{ accept: "application/json" })
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.text();
				}
				throw new Error(response.status);
			}).then(text => {
				dispatch(setTabData(text))
				dispatch(resetPageError())
			}).catch(err => dispatch(setPageError(err)));
	}
};

const SET_TABS_ERROR = 'SET_TABS_ERROR';
export const setTabsError = (data) => ({ type: SET_TABS_ERROR, isLoading: false, data });

const RESET_TABS_ERROR = 'RESET_TABS_ERROR';
export const resetTabsError = (data) => ({ type: RESET_TABS_ERROR, isLoading: false, data });

export const saveTabText = (text, id, textType) => {
	return (dispatch, getState) => {
		dispatch(requestLoadApp())
		dispatch(resetTabsError())
		fetch(`/api/flat/change-content/${textType}/${id}`, {
			method: 'POST',
			headers: {
				'Accept': 'text/plain; charset=utf-8',
				'Content-Type': 'text/plain; charset=utf-8',
				'X-CSRF-TOKEN': getState().app.CSRFToken,
			},
			body: text
		}).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return;
			}
			throw new Error(response.status);
		})
			.then(() => {
				dispatch(loadTabs(textType, id));
				dispatch(setAppAlert('update_tab_success'));
			})
			.catch(err => {
				dispatch(setPageError(err));
			});
	}
};

const RECEIVE_TAB_IMAGE_BASE = 'RECEIVE_TAB_IMAGE_BASE';
export const receiveTabImageBase = (data) => ({ type: RECEIVE_TAB_IMAGE_BASE, isLoading: false, data });

const RESET_ADD_IMAGE = 'RESET_ADD_IMAGE';
export const resetAddImage = (data) => ({ type: RESET_ADD_IMAGE });

export const convertImage = (file) => {
	return (dispatch, getState) => {
		const formData = new FormData();
		formData.append('image', file);
		dispatch(requestLoadApp())

		fetch(`/api/flat/convert-image`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'X-CSRF-TOKEN': getState().app.CSRFToken
			},
			body: formData
		}).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.text();
			}
			throw new Error(response.status);
		})
			.then(text => {
				if (IsJsonString(text) && JSON.parse(text).error) {
					dispatch(setTabsError(JSON.parse(text).error));
				} else {
					dispatch(receiveTabImageBase(text))
				}
			})
			.catch(err => {
				dispatch(setPageError(err));
			});
	}
};

function IsJsonString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

// RESULTS

const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS';
export const setSearchParams = (params) => {
	return ({ type: SET_SEARCH_PARAMS, params })
};

// const RESET_SEARCH_PARAMS = 'RESET_SEARCH_PARAMS';
export const resetSearchParams = (params) => {
	return ({ type: SET_SEARCH_PARAMS, params })
};

const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const setSearchResults = (data) => ({ type: SET_SEARCH_RESULTS, isLoading: false, data });

export const searchByPhrase = (key, filters, page) => {
	return (dispatch) => {
		dispatch(resetPageError())
		dispatch(requestLoadApp())
		let encodedKey = encodeURIComponent(key)

		fetch(`/api/search/sentences?q=${encodedKey}&texts=${filters}&page=${page}`,
			{ accept: "application/json" })
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				dispatch(setSearchResults(json))
			}).catch(err => dispatch(setPageError(err)));
	}
};


// export const searchSources = (id, verseFrom, verseTo, texts, page) => {
// 	return (dispatch) => {
// 		dispatch(resetPageError())
// 		dispatch(requestLoadApp())
// 		fetch(`/api/search/sources/${id}?verseFrom=${verseFrom}&verseTo=${verseTo}&texts=${texts}&page=${page}`,
// 			{ accept: "application/json" })
// 			.then(response => {
// 				if (response.status >= 200 && response.status < 300) {
// 					return response.json();
// 				}
// 				throw new Error(response.status);
// 			}).then(json => {
// 				console.log(json);
// 				//dispatch(setSearchResults(json))
// 			}).catch(err => dispatch(setPageError(err)));
// 	}
// };


/* PARALLLELS & THEMES */
const receiveParallelsList = (data) => ({ type: 'RECEIVE_PARALLELS_THEMES_LIST', isLoading: false, data });
export const loadParallels = () => {
	return (dispatch) => {
		dispatch(requestLoadApp())
		fetch('/api/import/other-imports', { accept: "application/json" })
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			})
			.then(json => {
				dispatch(receiveParallelsList(json));
				dispatch(resetPageError());
			})
			.catch(err => dispatch(setPageError(err)));
	}
};

const setParallelsThemesModalData = (data) => ({ type: 'SET_PARALLELS_THEMES_MODAL_DATA', isLoading: false, data });
export const showImportParallelsModal = (data) => {
	return (dispatch) => {
		dispatch(setParallelsThemesModalData(data));
		dispatch(setAppAlert('upload_parallels_themes'));
	}
}

const getAddress = (name, mode) => {
	if (name === "PARALLEL_FRAGMENTS" && mode === "upload")
		return "/api/import/add-parellel-locations";
	if (name === "PARALLEL_FRAGMENTS" && mode === "update")
		return '/api/import/update-parellel-locations';
	if (name === "THEMES" && mode === "upload")
		return "/api/import/add-themes";
	if (name === "THEMES" && mode === "update")
		return '/api/import/update-themes';
	if (name === "PHONETIC_VARIANTS" && mode === "upload")
		return "/api/import/add-phonetic-variants";
	if (name === "PHONETIC_VARIANTS" && mode === "update")
		return '/api/import/update-phonetic-variants';
}
export const uploadParallels = ({ file, mode, name }) => {
	return (dispatch, getState) => {
		const formData = new FormData();
		dispatch(requestLoadApp())
		formData.append('zip', file);

		fetch(getAddress(name, mode), {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'X-CSRF-TOKEN': getState().app.CSRFToken
			},
			body: formData
		}).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		})
			.then(json => {
				if (json.error) {
					dispatch(setTextError(json.error));
				} else {
					dispatch(receiveLoadManageTextList(json));
					dispatch(resetAppAlert());
					dispatch(receiveParallelsList(json));
				}
			})
			.catch(err => {
				dispatch(setPageError(err));
				dispatch(resetAppAlert());
			});
	}
}

export const deleteTransilterationConfirmed = (code) => {
	return (dispatch, getState) => {
		fetch(`/api/import/delete-transliteration/${code}`, {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'X-CSRF-TOKEN': getState().app.CSRFToken,
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			})
			.then(json => {
				dispatch(resetAppAlert());
				dispatch(receiveNewTextData(json));
			})
			.catch(err => dispatch(setPageError(err)));
	}
}


const RECEIVE_TRANSCRIPTION_DELETION_CONFIRMATION = 'RECEIVE_TRANSCRIPTION_DELETION_CONFIRMATION';
export const receiveTranscriptionDeletionConfirmation = (data) => ({ type: RECEIVE_TRANSCRIPTION_DELETION_CONFIRMATION, isLoading: false, data });

export const deleteTranscriptionConfirmed = (code) => {
	return (dispatch, getState) => {
        dispatch(setTranscriptionDeletionIsPending(true));
		fetch(`/api/import/delete-transcription/${code}`, {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'X-CSRF-TOKEN': getState().app.CSRFToken,
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			})
			.then(json => {
				dispatch(resetAppAlert());
				dispatch(receiveTranscriptionDeletionConfirmation());
			})
			.catch(err => dispatch(setPageError(err)))
            .finally(() => dispatch(setTranscriptionDeletionIsPending(false)));
	}
}

export const setTranscriptionDeletionIsPending = (isPending) => {
    return (dispatch) => {
        dispatch({ type: 'SET_DELETION_IS_PENDING', isPending });
    }
}
