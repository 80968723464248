const initialState = {
    events: [],
    persons: [],
    eventsIsLoading: false,
    personsIsLoading: false,
    themesResults: {}
}

export default function themes(state = initialState, action) {
    switch (action.type) {
        case 'SET_THEME_EVENTS':
            return {
                ...state, events: action.data
            }
        case 'SET_THEME_PERSONS':
            return {
                ...state, persons: action.data
            }
        case 'SET_THEMES_PERSONS_ISLOADING':
            return {
                ...state, personsIsLoading: action.data
            }
        case 'SET_THEMES_EVENTS_ISLOADING':
            return {
                ...state, eventsIsLoading: action.data
            }
        case 'SET_THEMES_RESULTS':
            return {
                ...state, themesResults: action.data
            }
        default:
            return state
    }
}