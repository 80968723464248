const initialState = {
  users: [],
  roles: [],
  userToChange: {},
  error: null,
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_USERS':
      return {
        ...state,
        users: action.data
      };
    case 'RECEIVE_USERS_ROLES':
      return {
        ...state,
        roles: action.data
      };
   case 'SET_USER_TO_CHANGE':
      return {
        ...state,
        userToChange: action.user
      };
   case 'RESET_USER_TO_CHANGE':
      return {
        ...state,
        userToChange: {}
      };
   case 'SET_USERS_ERROR':
      return {
        ...state,
        error: action.err
      };
   case 'RESET_USERS_ERROR':
      return {
        ...state,
        error: null
      };
    default:
      return state
  }
}
