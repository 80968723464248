import React from 'react';
import withContexts from 'helpers/contextsMerge';
import ModalResetPassword from './ModalResetPassword.js';
import ModalAddNewUser from './ModalAddNewUser.js';
import ModalAddNewSourceFolder from './ModalAddSourceFolder';
import ModalAddSourcePackage from './ModalAddSourcePackage';
import ModalSourcesChooseVerses from './ModalSourcesChooseVerses';
import ModalSourceDelete from './ModalSourceDelete';
import ModalAddNewText from './ModalAddNewText';
import ModalAddNewTrans from './ModalAddNewTrans';
import ModalUpdateText from './ModalUpdateText';
import ModalUploadTrans from './ModalUploadTrans';
import ModalUploadParallels from './ModalUploadParallels';

// import ModalUpdateTrans from './ModalUpdateTrans.js';
import ModalUpdateOtherTrans from './ModalUpdateOtherTrans';
import ModalDeleteOtherTrans from './ModalDeleteOtherTrans';
import ModalDeleteTranscription from './ModalDeleteTranscription';
import ModalDeleteTransliteration from './ModalDeleteTransliteration';
import ModalOpenedText from './ModalOpenedText';

// annotation Modals
import ModalAnnotationSplit from 'containers/annotation/annotation-modals/ModalAnnotationSplit';
import ModalAnnotationAnnotate from 'containers/annotation/annotation-modals/ModalAnnotationAnnotate';
import ModalAnnotationConfirmLeave from 'containers/annotation/annotation-modals/ModalAnnotationConfirmLeave';


const ModalContent = ({ alerts, langContext: {
	dict
} }) => {
	if (alerts.login_success)
		return dict[`form.login.success`]

	if (alerts.logout_success)
		return dict[`form.logout.success`]

	if (alerts.users_success)
		return dict[`form.users.success`]

	if (alerts.add_users_success)
		return dict[`form.users.add.success`]

	if (alerts.add_text_success)
		return dict[`form.texts.add.success`]

	if (alerts.update_card_success)
		return dict[`card.update.success`]

	if (alerts.update_tab_success)
		return dict[`tabs.update.success`]

	if (alerts.user_password)
		return <ModalResetPassword dict={dict}/>;

	if (alerts.new_user)
		return <ModalAddNewUser dict={dict}/>;

	if (alerts.new_text)
		return <ModalAddNewText dict={dict}/>;

	if (alerts.update_text)
		return <ModalUpdateText dict={dict}/>;

	if (alerts.new_user)
		return <ModalAddNewUser dict={dict}/>;

	if (alerts.upload_transliteraion)
		return <ModalUploadTrans dict={dict}/>;

	if (alerts.update_transliteraion)
		return <ModalUploadTrans isUpdate={true} dict={dict}/>;

	if (alerts.source_new_folder) {
		return <ModalAddNewSourceFolder dict={dict}/>;
	}
	if (alerts.source_new_package) {
		return <ModalAddSourcePackage dict={dict}/>
	}
	if (alerts.source_choose_verses) {
		return <ModalSourcesChooseVerses dict={dict}/>
	}
	if (alerts.source_delete) {
		return <ModalSourceDelete dict={dict}/>;
	}

	if (alerts.new_trans)
		return <ModalAddNewTrans dict={dict}/>;

	if (alerts.update_other_trans)
		return <ModalUpdateOtherTrans dict={dict}/>;

	if (alerts.delete_trans)
		return <ModalDeleteOtherTrans dict={dict}/>;

	if (alerts.delete_transcription)
		return <ModalDeleteTranscription dict={dict}/>;

	if (alerts.delete_transliteration)
		return <ModalDeleteTransliteration dict={dict}/>;

	if (alerts.text_params)
		return <ModalOpenedText dict={dict}/>;

	if (alerts.upload_parallels_themes)
		return <ModalUploadParallels dict={dict}/>

    // annotations:
    if (alerts.annotation_split){
        return <ModalAnnotationSplit dict={dict}/>
    }
    if (alerts.annotation_annotate){
        return <ModalAnnotationAnnotate dict={dict}/>
    }
    if (alerts.annotation_confirm){
        return <ModalAnnotationConfirmLeave dict={dict}/>
    }

	return null;
}

export default withContexts(ModalContent);
