import React from 'react';
import withContexts from 'helpers/contextsMerge';
import FileUploadLoader from './FileUploadLoader.js';

const FileUploadField = ({
  field, // { name, value, onChange, onBlur }
  form: {
    touched,
    errors
  }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  langContext: {
    dict
  },
  handleUpload,
  label,
  ...props
}) => (
    <div className="form__field">
      {field.type}
      {label && (
        <label htmlFor={field.name} className="form__field__label">
          {label ? <span dangerouslySetInnerHTML={{ __html: label }}></span> : `${dict[`form.${field.name}`]}:`}
        </label>
      )}

      <div className="form__field__file__box">
        <div className="form__field__file__box-inner">
          <div className="form__field__file--visible">
            <button className="form__field__file--visible__button" type="button">
              {field.value ? dict[`form.changeFile`] : dict[`form.chooseFile`]}
            </button>
          </div>

          <input name={field.name} type="file" className="form__field__file" onChange={handleUpload} />
        </div>
        <div className="form__field__file__box__file-name">
          {field.value && <span>{field.value.name}</span>}
        </div>
        <FileUploadLoader file={field.value} />
      </div>
      {touched[field.name] && errors[field.name] &&
        <div className="form__field__error">
          {errors[field.name]}
        </div>}
    </div>
  );

export default withContexts(FileUploadField);