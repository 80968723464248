import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';

import { useDispatch, useSelector } from 'react-redux';

import * as Actions from 'actions/index';
import SelectField from 'components/form/SelectField';
import Loading from 'components/Loading.js';


const ModalSourcesChooseVerses = ({dict}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [verseFrom, setVerseFrom] = useState();
    const [verseTo, setVerseTo] = useState();
    const [verseFromList, setVerseFromList] = useState();
    const [verseToList, setVerseToList] = useState();
    const verses = useSelector(state => state.manageSources.modalAdditionalInfo.verses);
    const vId = useSelector(state => state.manageSources.modalAdditionalInfo.id)

    useEffect(() => {
        if (verses) {
            setVerseFromList(verses.versesFrom.map(item => ({ value: item, label: item })));
            setVerseToList(verses.versesTo.map(item => ({ value: item, label: item })));
            setVerseFrom({ value: verses.versesFrom[0], label: verses.versesFrom[0] });
            setVerseTo({ value: verses.versesTo[0], label: verses.versesTo[0] });
        }
    }, [verses]);

    useEffect(() => {
        if (verseFrom) {
            const index = verseFromList.indexOf(verseFrom);
            if (index > -1) {
                setVerseToList(verses.versesTo.slice(index).map(item => ({ value: item, label: item })));
            }
        }
    }, [verseFrom]);

    useEffect(() => {
        if (verseTo) {
            if (verseToList.filter(item => verseTo.value === item.value).length === 0) {
                setVerseTo(verseToList[0]);
            }
        }
    }, [verseToList]);
    if (!(verses && verseFrom && verseTo && verseFromList && verseToList)) {
        return (<Loading />)
    }


    return (
        <div className="modal--form__inner">
            <div className="modal--form__header">
                {dict[`sources.chooseVerses`]}
            </div>
            {}
            <div className="modal--form__content">
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            verseFrom: verseFrom,
                            verseTo: verseTo
                        }
                    }
                    validate={values => {
                        let errors = {};
                        return errors;
                    }}
                >
                    {({ errors, setFieldValue, values }) => (
                        <Form>
                            <div className="modal--form__inner-group">
                                <div className="modal--form__field__group">
                                    <div className="modal--form__field-selects">
                                        <Field
                                            defaultValue={verseFrom}
                                            name={'verseFrom'}
                                            component={SelectField}
                                            options={ verseFromList }
                                            autoFocus={true}
                                            tabIndex={1}
                                            handleChange={(value) => {
                                                setVerseFrom(value);
                                            }}
                                            dict={dict}
                                        />
                                    </div>
                                    <div className="modal--form__field-selects">
                                        <Field
                                            defaultValue={verseTo}
                                            name={'verseTo'}
                                            component={SelectField}
                                            options={verseToList}
                                            tabIndex={2}
                                            handleChange={(value) => {
                                                setVerseTo(value);
                                            }}
                                            dict={dict}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal--form__buttons">
                                <button tabIndex={"4"}
                                    onClick={() => {
                                        dispatch(Actions.modalAdditionalInfo({}));
                                        dispatch(Actions.resetAppAlert());
                                    }}
                                    className="modal--form__button--clear" type="button">
                                    {dict[`form.cancel`]}
                                </button>
                                <button tabIndex={"3"}
                                    onClick={() => {
                                        history.push(`sources/search/${vId}?verseFrom=${verseFrom.value}&verseTo=${verseTo.value}`);
                                    }}
                                    className="modal--form__button--clear" type="button">
                                    {dict[`form.show`]}
                                </button>
                            </div>
                        </Form>)}
                </Formik>
            </div>
        </div>
    )
}

export default ModalSourcesChooseVerses
