const initialState = {
    isPageError: false,
    results: {},
    params: {q: '', filters: '', page: 1},
};
  
export default function results(state = initialState, action) {
    switch (action.type) {
        case 'SET_SEARCH_PARAMS':
            return {
                ...state,
                params: {...action.params}
            };
        case 'SET_SEARCH_RESULTS':
            // console.log('setSearchResult:');
            // console.log(action.data);
            return {
                ...state,
                results: {...action.data}
            };
        default:
          return state
    }
}
  