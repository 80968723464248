const initialState = {
    mode: null,
    texts: [],
    sentences: [],
    editorData: null,
    modalData: null,
    keyboardRequestIsPending: false,
    annotateTogetherModal: null,
    annotateTogetherRequestPending: false,
    tokensData: {
        spans: [],
        selectedSpans: [],
        selectedAnnotations: [],
        shortcutsVisible: false,
    },
    setActiveAnnotationById: null,
    keystrokesEnabled: true,
}
export default function annotation(state = initialState, action) {
    switch (action.type) {
        case 'ANNOTATION_RECEIVE_TEXTS':
            return {
                ...state,
                texts: action.data
            }
        case 'RECEIVE_SENTENCES':
            return {
                ...state,
                sentences: action.data
            }

        case 'RECEIVE_ANNOTATION_EDITOR_DATA':
            const mode = (action.data.data.singleAnnotations) ? 'single' : 'double';
            const currentAnnotations = action.data.data.singleAnnotations || action.data.data.doubleAnnotations;

            if (mode === 'double') {
                // don't set first annotation active
                return {
                    ...state,
                    mode,
                    editorData: action.data,
                }
            }

            const firstSelectedAnnotation = Object.keys(currentAnnotations)[0];
            const firstSelectedSpans = currentAnnotations[firstSelectedAnnotation].subtokenIds;
            return {
                ...state,
                mode: mode,
                editorData: action.data,
                tokensData: {
                    ...state.tokensData,
                    selectedAnnotations: [firstSelectedAnnotation],
                    selectedSpans: firstSelectedSpans,
                }
            }

        case 'ANNOTATION_SET_SINGLE_MODE':
            return {
                ...state,
                mode: 'single'
            }
        case 'ANNOTATION_SET_DOUBLE_MODE':
            return {
                ...state,
                mode: 'double'
            }

        case 'CLEAR_SELECTED_SPANS':
            return {
                ...state,
                tokensData: {
                    ...state.tokensData,
                    selectedAnnotations: [],
                    selectedSpans: [],
                }
            }

        case 'CLEAR_EDITOR_DATA':
            return initialState


        case 'RECEIVED_ANNOTATION_RELOADED_EDITOR_DATA':
            return {
                ...state,
                editorData: {
                    ...state.editorData,
                    data: action.data
                },
                tokensData: {
                    ...state.tokensData,
                    selectedSpans: [],
                    selectedAnnotations: [],
                },
                modalData: null,
                annotateTogetherModal: null,
                keystrokesEnabled: true
            }



        case 'ANNOTATION_SET_SPANS':
            return {
                ...state,
                tokensData: {
                    ...state.tokensData,
                    spans: action.payload.spans
                }
            }

        case 'ANNOTATION_SET_SELECTED_SPANS':
            return {
                ...state,
                tokensData: {
                    ...state.tokensData,
                    selectedSpans: action.payload.selectedSpans
                }
            }

        // subtoken (left) click without ctrl:
        case 'ANNOTATION_SELECT_SUBTOKEN':
            return {
                ...state,
                tokensData: {
                    ...state.tokensData,
                    selectedAnnotations: [...action.payload.annotationId.split(',')],
                    selectedSpans: (state.mode === 'single' ?
                        state.editorData.data.singleTokens[action.payload.tokenId].subtokensIds :
                        state.editorData.data.doubleTokens[action.payload.tokenId].subtokensIds)
                }
            }

        // subtoken (left) click with ctrl
        case 'ANNOTATION_SELECTED_SPANS_ADD_REMOVE':
            // check if this span (or any of related whith it spans) IS already in selectedSpans
            const allRelatedSpans = state.mode === 'single' ? state.editorData.data.singleTokens[action.payload.tokenId].subtokensIds : state.editorData.data.doubleTokens[action.payload.tokenId].subtokensIds;
            let copyOfSelectedSpans = [...state.tokensData.selectedSpans];
            allRelatedSpans.forEach(spanId => {
                if (copyOfSelectedSpans.indexOf(spanId) > -1) {
                    // we need to remove only one representation of span
                    copyOfSelectedSpans.splice(copyOfSelectedSpans.indexOf(spanId), 1);
                } else {
                    if (state.tokensData.selectedSpans.length > 4) return state;
                    copyOfSelectedSpans = [...copyOfSelectedSpans, spanId];
                }
            });
            return {
                ...state,
                tokensData: {
                    ...state.tokensData,
                    selectedSpans: copyOfSelectedSpans,
                    selectedAnnotations: Array.from(new Set(copyOfSelectedSpans.reduce((total, it) => [...total, ...state.editorData.data.transcription.spans.filter(span => span.spanId === it)[0].annotationIds], [])))
                }
            }

        case 'ANNOTATION_SELECT_NEXT_SPAN': {
            if (state.tokensData.selectedSpans.length === 0) return { ...state } // TODO - select first span?
            const stateSpans = Object.keys(state.editorData.data.transcription.spans).map(it => state.editorData.data.transcription.spans[it]);
            const lastIndex = stateSpans.reduce(
                (total, item, index) => {
                    if (state.tokensData.selectedSpans.indexOf(item.spanId) > -1) {
                        if (index > total) {
                            return index
                        }
                    }
                    return total
                }, 0
            );

            // if currentSelected or last of currentSelected is last in stateSpans:
            if (lastIndex >= stateSpans.length - 1) {
                return state;
            }
            const currentTokens = state.editorData.data.singleTokens || state.editorData.data.doubleTokens;
            return {
                ...state,
                tokensData: {
                    ...state.tokensData,
                    selectedSpans: currentTokens[stateSpans[lastIndex + 1].tokenId].subtokensIds,
                    selectedAnnotations: stateSpans[lastIndex + 1].annotationIds
                }
            }
        }

        case 'ANNOTATION_SELECT_PREV_SPAN': {
            if (state.tokensData.selectedSpans.length === 0) return { ...state }
            const stateSpansA = Object.keys(state.editorData.data.transcription.spans).map(it => state.editorData.data.transcription.spans[it]);
            const firstIndex = stateSpansA.reduce(
                (total, item, index) => {
                    if (state.tokensData.selectedSpans.indexOf(item.spanId) > -1) {
                        if (index < total) {
                            return index
                        }
                    }
                    return total
                }, 9999999
            );
            if (firstIndex === 0) return { ...state }
            const currentTokens = state.editorData.data.singleTokens || state.editorData.data.doubleTokens
            return {
                ...state,
                tokensData: {
                    ...state.tokensData,
                    selectedSpans: currentTokens[stateSpansA[firstIndex - 1].tokenId].subtokensIds,
                    selectedAnnotations: stateSpansA[firstIndex - 1].annotationIds
                }
            }
        }

        // clicked on right - select this annotation and highlight related spans
        case 'ANNOTATION_SELECT_ANNOTATION': {
            const currentAnnotations = state.editorData.data.singleAnnotations || state.editorData.data.doubleAnnotations;
            // in double mode there may be no annotation
            if (Object.keys(currentAnnotations).length === 0) return state
            return {
                ...state,
                tokensData: {
                    ...state.tokensData,
                    selectedAnnotations: [action.payload.annotationId],
                    selectedSpans: currentAnnotations[action.payload.annotationId].subtokenIds,
                }
            }
        }
        case 'ANNOTATION_TOGGLE_SHORTCUTS':
            return {
                ...state,
                tokensData: {
                    ...state.tokensData,
                    shortcutsVisible: !state.tokensData.shortcutsVisible
                }
            }

        /// MODALS:
        case 'ANNOTATION_SET_MODAL_SPLIT':
            const { tokenId } = action.data;
            return {
                ...state,
                modalData: {
                    tokenId,
                    loading: false,
                    errors: false,
                },
                keystrokesEnabled: false
            }

        case 'ANNOTATION_SET_MODAL_ANNOTATE':
            return {
                ...state,
                modalData: {
                    type: 'annotate',
                    ...action.data,
                    loading: false,
                    errors: false,
                },
                keystrokesEnabled: false
            }

        case 'ANNOTATION_POS_CLASSES_IS_LOADING':
            return {
                ...state,
                modalData: {
                    ...state.modalData,
                    posClassesIsLoading: true,
                }
            }

        case 'ANNOTATION_SET_MODAL_CONFIRM':
            return {
                ...state,
                modalData: {
                    type: 'confirm',
                    link: action.data.link,
                    next: action.data.next,
                    loading: false,
                    errors: false,
                },
                keystrokesEnabled: false
            }


        case 'ANNOTATION_SET_MODAL_LOADING':
            return {
                ...state,
                modalData: (state.modalData === null && !action.loading ) ? null : {
                    ...state.modalData,
                    loading: action.loading,
                }
            }


        case 'ANNOTATION_SET_MODAL_ERROR':
            return {
                ...state,
                modalData: {
                    ...state.modalData,
                    errors: action.payload.errors,
                    loading: false,
                }
            }

        case 'ANNOTATION_RESET_MODAL_DATA':
            return {
                ...state,
                modalData: null,
                annotateTogetherModal: null,
                keystrokesEnabled: true
            }

        case 'ANNOTATIONS_MODAL_RECEIVED_POS_CLASSES':
            return {
                ...state,
                modalData: {
                    ...state.modalData,
                    posClasses: action.payload.posClasses,
                    loading: false,
                }
            }
        case 'ANNOTATIONS_MODAL_SET_SINGLE_CATEGORIES':
            return {
                ...state,
                modalData: {
                    ...state.modalData,
                    singleCategories: action.payload.singleCategories,
                    loading: false,
                }
            }
        case 'ANNOTATION_MODAL_CLEAR_SINGLE_CATEGORIES':
            return {
                ...state,
                modalData: {
                    ...state.modalData,
                    singleCategories: null,
                }
            }

        case 'ANNOTATION_ANNOTATE_TOGETHER_RES':
            return {
                ...state,
                annotateTogetherModal: action.payload,
            }

        case 'ANNOTATE_TOGETHER_REQUEST_PENDING':
            return {
                ...state,
                annotateTogetherRequestPending: action.payload.isPending
            }

        case 'ANNOTATION_SET_ACTIVE_ANNOTATION_BY_ID':
            return {
                ...state,
                setActiveAnnotationById: action.payload
            }


        case 'SET_KEYSTROKES_ENABLED':
            return {
                ...state,
                keystrokesEnabled: action.payload.keystrokesEnabled
            }



        default:
            return state
    }
}
