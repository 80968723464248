import React, { memo } from 'react';
import FooterMainNav from './FooterMainNav';

import logoNCN from 'images/logo_NCN.svg';
import FooterPartContainer from './FooterPartContainer';

const Footer = ({dict, navItems}) => {
    if (!dict) return null;

    return (
        <footer className="footer">
            <div className="footer__inner">
                <FooterPartContainer>
                    <FooterMainNav list={navItems} dict={dict}/>
                </FooterPartContainer>
                <FooterPartContainer>
                    <span className="footer__page__name">{dict[`footer.page_1.name`]}</span>
                    <a href={dict[`addresses.uamHjp`]}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer__page__link">
                        {dict[`footer.page_1.link`]}
                        <span className="footer__page__link-icon icon-new-window"></span>
                    </a>
                </FooterPartContainer>
                <FooterPartContainer>
                    <a href={dict[`addresses.ncn`]} target="_blank"
                        rel="noopener noreferrer"
                        className="footer__page__logo-link">
                        <img className="footer__page__logo" src={logoNCN} alt="" />
                        <span className="footer__page__link-icon icon-new-window"></span>
                    </a>
                </FooterPartContainer>
                <div className="footer__part-container--right">
                    <span className="footer__page__credits">{dict[`footer.credits.name`]}</span>
                    <a href={dict[`addresses.pcss`]} target="_blank"
                        rel="noopener noreferrer"
                        className="footer__page__credits-link">
                        {dict[`footer.credits.link`]}
                        <span className="footer__page__link-icon icon-new-window"></span>
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default memo(Footer);
