import React from 'react';

import langIconEn from 'images/icons/A_iko_2_EN.svg';
import langIconEnHover from 'images/icons/A_iko_2_EN_hover.svg';
import langIconEnActive from 'images/icons/A_iko_2_EN_active.svg';

import langIcon from 'images/icons/A_iko_2_PL.svg';
import langIconHover from 'images/icons/A_iko_2_PL_hover.svg';
import langIconActive from 'images/icons/A_iko_2_PL_active.svg';

const HeaderTopLang = ({ setLang, dict, langs }) => {
    const getLangIcons = React.useCallback((lang) => {
        switch (lang) {
            case 'pl':
                return <>
                    <img src={langIcon} className="icon regular" alt={dict[`header.top.lang.pl`]}/>
                    <img src={langIconHover} className="icon hover" alt={dict[`header.top.lang.pl`]}/>
                    <img src={langIconActive} className="icon active" alt={dict[`header.top.lang.pl`]}/>
                </>
            case 'en':
                return <>
                    <img src={langIconEn} className="icon regular" alt={dict[`header.top.lang.en`]}/>
                    <img src={langIconEnHover} className="icon hover" alt={dict[`header.top.lang.en`]}/>
                    <img src={langIconEnActive} className="icon active" alt={dict[`header.top.lang.en`]}/>
                </>
            default:
                break;
        }
    }, []);
    return (
        langs.map(lang =>
            !lang.active ?
                <button className="header__top__lang" onClick={() => setLang(lang.lang)} key={lang.id}
                    title={dict[`header.top.lang`]}>
                    {getLangIcons(lang.lang)}
                </button>:
                null)
    )
}



export default React.memo(HeaderTopLang);
