import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withContexts from 'helpers/contextsMerge'
import * as Actions from 'actions/index';
import { Formik, Field, Form } from 'formik';
import SelectField from 'components/form/SelectField';
import { Link } from 'react-router-dom';

import Loading from 'components/Loading.js';
import Tooltip from 'components/elements/Tooltip.js';

class ModalOpenedText extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cardFrom: null,
			cardTo: null,
			verseFrom: null,
			verseTo: null,
			verseFromList: [],
			verseToList: []
		};
	}

	componentDidMount() {
		const { actions, openedText: { code } } = this.props;
		actions.loadOpenedTextCards(code);
	}

	componentDidUpdate(prevProps, prevState) {
		const { actions, openedText: { code, cards, verses } } = this.props;
		const { cardFrom, cardTo, verseTo, verseFrom, verseFromList, verseToList } = this.state;

		//set cards values when cards info is loaded
		if (!prevProps.openedText.cards.length && cards.length) {
			const initVal = { value: cards[0].cardAddress, label: cards[0].cardNumber, ord: cards[0].ord };
			this.setState({ cardFrom: initVal, cardTo: initVal });
		}

		//when card is changed load verses for current card
		if (this.state.cardFrom !== prevState.cardFrom) {
			actions.loadOpenedTextVerses(code, this.state.cardFrom.value);
			this.setState((state) => ({ verseFrom: { ...state.verseFrom, value: '' } }));
		}

		if (this.state.cardTo !== prevState.cardTo) {
			actions.loadOpenedTextVerses(code, this.state.cardTo.value);
			this.setState((state) => ({ verseTo: { ...state.verseTo, value: '' } }));
		}

		//get current available verses list for both cards
		if (verses !== prevProps.openedText.verses || this.state.cardTo !== prevState.cardTo || this.state.cardFrom !== prevState.cardFrom) {
			const from = verses.filter(verse => verse.cardAddress === cardFrom.value)[0];
			const to = verses.filter(verse => verse.cardAddress === cardTo.value)[0];

			this.setState({
				verseFromList: from ? from.list.map(item => { return { value: item, label: item } }) : [],
				verseToList: to ? to.list.map(item => { return { value: item, label: item } }) : [],
			});
		}

		//when current verse from/to list is changed set new value in field (first option)
		if (verseFromList !== prevState.verseFromList && verseFromList[0]) {
			this.setState((state) => ({ verseFrom: verseFrom && verseFrom.value ? verseFrom : verseFromList[0] }));
		}

		if (verseToList !== prevState.verseToList && verseToList[0]) {
			this.setState((state) => ({ verseTo: verseTo && verseTo.value ? verseTo : verseToList[0] }));
		}

		if (!cardFrom || !cardTo || !verseFrom || !verseTo) {
			return;
		}

		//spacial cases:
		//when cards from and to are equal adjust verses values
		if (cardFrom.ord === cardTo.ord && verseTo.value < verseFrom.value) {
			this.setState({ verseTo: verseFrom });
		}

		//adjust card to value to card from value
		if (cardTo.ord < cardFrom.ord) {
			this.setState({ cardTo: cardFrom });
		}
	}

	submitForm = (values) => {
		const { actions } = this.props;
		actions.addNewText(values);
	}

	componentWillUnmount() {
		const { actions } = this.props;
		actions.resetOpenedText();
	}

	resetForm = () => {
		const { openedText: { cards } } = this.props;
		const initVal = { value: cards[0].cardAddress, label: cards[0].cardNumber, ord: cards[0].ord };
			this.setState({ cardFrom: initVal, cardTo: initVal });
	}

	render() {
		const { langContext: {
			dict
		}, openedText: { name, cards, code }, error } = this.props;
		const { cardFrom, cardTo, verseFrom, verseTo, verseFromList, verseToList } = this.state;

		if (!cards || !cardFrom || !cardTo || !verseFrom || !verseTo || !verseFromList || !verseToList) {
			return <Loading />;
		}

		//filter for options, so that data remains untouched and options meet the requirements for the specialk conditions
		const optionsCodeFrom = cards.map(item => { return { value: item.cardAddress, label: item.cardNumber, ord: item.ord } });
		const optionsCodeTo = optionsCodeFrom.filter(option => option.ord >= cardFrom.ord);

		const optionsVerseFrom = verseFromList;
		const optionsVerseTo = cardFrom.value === cardTo.value ? verseToList.filter(item => item.value >= verseFrom.value) : verseToList;

		//initial values ale base on values in state, so they can be changed according to different data and conditions,
		//and then applied to form thanks to enableReinitialize prop

		const initialValues = {
			codeFrom: cardFrom.value,
			codeTo: cardTo.value,
			verseFrom: verseFrom.value,
			verseTo: verseTo.value
		};

		return (
			<div className="modal--form__inner">
				<div className="modal--form__header">
					{dict[`text.text`]}: <span dangerouslySetInnerHTML={{ __html: name }}></span>
				</div>
				<div className="modal--form__content">
					<Formik
						enableReinitialize
						initialValues={initialValues}
						validate={values => {
							let errors = {};
							return errors;
						}}
					>
						{({ errors, setFieldValue, values }) => (
							<Form>
								{error && <div className="modal--form__error">{error}</div>}
								{errors.form && <div className="modal--form__error">{errors.form}</div>}
								<div className="modal--form__inner-group">
									<div className="modal--form__field__group">
										<div className="modal--form__field__tooltip">
											<Tooltip
												triggerIcon={<span className="icon-light"></span>}
												triggerClass={"modal--form__field__tooltip__button"}
												window={
													<div>
														<div className="tooltip__text__header">{dict[`text.tooltip.choose`]}</div>
														<div className="tooltip__text__inner">{dict[`text.tooltip.info`]}</div>
													</div>
												}>
											</Tooltip>
										</div>
										<div className="modal--form__field">
											<Field
												name={'codeFrom'}
												component={SelectField}
												options={optionsCodeFrom}
												tabIndex={1}
												autoFocus={true}
												handleChange={(value) => {
													this.setState({ cardFrom: value });
													setFieldValue('codeFrom', value.value)
												}}
                                                dict={dict}
											/>
										</div>
										<div className="modal--form__field">
											<Field
												name={'codeTo'}
												component={SelectField}
												options={optionsCodeTo}
												tabIndex={3}
												handleChange={(value) => {
													this.setState({ cardTo: value });
													setFieldValue('codeTo', value.value)
												}}
                                                dict={dict}
											/>
										</div>
									</div>
									<div className="modal--form__field__group">
										<div className="modal--form__field">
											<Field
												name={'verseFrom'}
												component={SelectField}
												options={optionsVerseFrom}
												tabIndex={2}
												handleChange={(value) => {
													this.setState({ verseFrom: value });
													setFieldValue('verseFrom', value.value)
												}}
												menuIsOpen={true}
                                                dict={dict}
											/>
										</div>
										<div className="modal--form__field">
											<Field
												name={'verseTo'}
												component={SelectField}
												options={optionsVerseTo}
												tabIndex={4}
												handleChange={(value) => {
													this.setState({ verseTo: value });
													setFieldValue('verseTo', value.value)
												}}
                                                dict={dict}
											/>
										</div>
									</div>
								</div>
								<div className="modal--form__buttons">
									<button tabIndex={"6"}
										onClick={
											this.resetForm
											// actions.resetAppAlert();
										}
										className="modal--form__button--clear" type="button">
										{dict[`form.clear`]}
									</button>
									<Link tabIndex="5" className="modal--form__button" to={`/texts/item?code=${code}&startCardNumber=${cardFrom.label}&startVerseNo=${verseFrom.value}&endCardNumber=${cardTo.label}&endVerseNo=${verseTo.value}&mode=transcription&grammar=false`}>
										{dict[`form.show`]}
									</Link>
								</div>
							</Form>)}
					</Formik>
				</div>
			</div>
		);
	}
}

ModalOpenedText.propTypes = {
	openedText: PropTypes.shape({
		code: PropTypes.string,
		name: PropTypes.string,
		cards: PropTypes.array.isRequired,
		verses: PropTypes.array.isRequired,
	})
};

const mapStateToProps = state => ({
	openedText: state.texts.openedText
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch),
});
export default withContexts(connect(mapStateToProps, mapDispatchToProps)(ModalOpenedText));
