import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Actions from 'actions/index';

import Loading from 'components/Loading.js';

const ModalDeleteTranscription = ({ dict }) => {
    const dispatch = useDispatch();
    const { modalAdditionalInfo, deletionIsPending } = useSelector(state => state.manageSources);

    return (

        <div className="modal--form__inner">
            { deletionIsPending && (<Loading/>)}

            {!deletionIsPending && (
                <div>
                    <div className="modal--form__info">
                        {dict[`manage_text.delete_transcription`]} "{modalAdditionalInfo.name}"? {dict[`manage_text.delete_transcription_annotation`]}
                    </div>
                    <button
                        onClick={() => {
                            dispatch(Actions.resetAppAlert());
                            dispatch(Actions.modalAdditionalInfo({}));
                        }}
                        className="modal--form__button--clear" type="button">
                        {dict[`form.cancel`]}
                    </button>
                    <button type="submit" className="modal--form__button"
                        onClick={() => dispatch(Actions.deleteTranscriptionConfirmed(modalAdditionalInfo.code))}
                    >
                        {dict[`manage_text.delete`]}
                    </button>
                </div>
            )
            }
        </div>
    )
}

export default ModalDeleteTranscription;
