import React, { useContext } from 'react';
import { PathContext } from 'helpers/contexts';
import { Link } from 'react-router-dom';

const HeaderUserPanelLink = ({ path, name }) => {
	const { currentPath } = useContext(PathContext);

	return (
		<li className="header__user-panel__list-item">
			<Link
				to={path}
				className={`header__user-panel__link ${currentPath === path
					? 'active'
					: ''}`}>
				{currentPath === path && <span className="icon-accept icon"></span>}
				{name}
			</Link>
		</li>
	)
}

export default HeaderUserPanelLink;
