import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';

import KeyboardSelectField from 'components/form/KeyboardSelectField';

import { LangContext } from 'helpers/contexts';

import FormInputComponentColumn from 'components/form/InputFieldColumn.js';

import FormikReduxValidator from 'helpers/FormikReduxValidator';

import {
    annotationModalGetPosClasses,
    annotationModalGetSingleCategories,
    annotationModalClearSingleCategories,
    annotationModalSendAnnotation,
    annotationModalReset
} from 'actions/annotation.actions';
import Loading from 'components/Loading.js';


const ModalAnnotationAnnotate = () => {
    const dispatch = useDispatch();
    const { dict, currentLang } = useContext(LangContext);

    const { modalData: { posClasses, singleCategories, tokens: incomingTokens, edit = false, errors }, annotateTogetherModal } = useSelector(state => state.annotation);
    const { singleAnnotations, singleTokens, doubleAnnotations, doubleTokens, transcription: { spans } } = useSelector(state => state.annotation.editorData.data);

    const [editInitialData, setEditInitialData] = useState(null);
    const [initalDataIsSet, setInitialDataIsSet] = useState(false);

    const [formikInitialValues, setFormikInitialValues] = useState(null);

    const [extendedPosClasses, setExtendedPosClasses] = useState(null);
    const [selectedPosClass, setSelectedPosClass] = useState(null);

    const [tokens, setTokens] = useState(null);
    const [annotations, setAnnotatations] = useState(null);

    const [currentTokens, setCurrentTokens] = useState(null);
    const [currentAnnotations, setCurrentAnnotations] = useState(null);

    const [requestIsPending, setRequestIsPending] = useState(false);
    const [loadingSingleCategories, setLoadingSingleCategories] = useState(false);

    // editInitialData on loaded singleCategories is used only once - on second select it is clear

    const [lemma, setLemma] = useState('');

    // console.log('reload');

    useEffect(() => {
        // first get available posClasses
        dispatch(annotationModalGetPosClasses());
        return () => {
            // remove modal data also on X click
            dispatch(annotationModalReset());
        }
    }, []);

    useEffect(() => {
        setCurrentTokens( singleTokens || doubleTokens );
    }, [singleTokens, doubleTokens]);

    useEffect(() => {
        setCurrentAnnotations(singleAnnotations || doubleAnnotations);
    }, [singleAnnotations, doubleAnnotations]);

    useEffect(() => {
        if (incomingTokens) {
            setTokens([...new Set(incomingTokens.map(it => spans.filter(span => span.spanId === it)[0].tokenId))]);
            setAnnotatations([...new Set(incomingTokens.reduce(
                (total, it) =>
                    [
                        ...total,
                        ...spans.filter(
                            span => span.spanId === it
                        )[0].annotationIds]
                , []))]);
        }
    }, [incomingTokens]);


    useEffect(() => {
        if (annotations && annotations.length === 1 && currentAnnotations[annotations[0]]) {
            const ann = currentAnnotations[annotations[0]];
            setEditInitialData({
                posClass: ann.posClass,
                categoryMap: ann.categoryMap,
                lemma: ann.lemma,
            })
        }
    }, [annotations]);

    useEffect(() => {
        // on received posClasses set initialValue
        // if creating new annotation - set value to first and lemma to ''
        // otherwise (edit extisting) : immediately setSelectedPosClass and load single categories for it
        if (posClasses) {
            let initialPosClass = null;
            const pos = [
                { value: 0, label: '----' },
                ...posClasses.map((it, index) => {
                    if (editInitialData && editInitialData.posClass === it) {
                        initialPosClass = { value: (index + 1), label: it }
                    }
                    return ({
                        value: (index + 1),
                        label: it
                    })
                })
            ];
            setExtendedPosClasses(pos);
            setFormikInitialValues((initialPosClass) ?
                ({
                    posClass: initialPosClass,
                    lemma: (editInitialData.lemma ? editInitialData.lemma : '')
                }) :
                ({
                    posClass: pos[0],
                    lemma: ''
                })
            )
            // if there is editInitialData load single categories immediately
            if (initialPosClass) {
                setSelectedPosClass(initialPosClass);
                dispatch(annotationModalGetSingleCategories(initialPosClass.label));
            }
        }
    }, [posClasses]);

    const onSelectPosClass = (posClass) => {
        setSelectedPosClass(posClass);
        if (posClass.label === '----') return dispatch(annotationModalClearSingleCategories());
        setLoadingSingleCategories(true);
        dispatch(annotationModalGetSingleCategories(posClass.label));
    }

    useEffect(() => {
        if (singleCategories) {
            setLoadingSingleCategories(false);
            // for edit purposes
            const reduced = singleCategories.reduce((total, item) => {
                let initialSelectedItem = null;
                if (editInitialData && editInitialData.categoryMap[item.tag]) {
                    initialSelectedItem = item.values.filter(val => {
                        return val.tag === editInitialData.categoryMap[item.tag];
                    })[0];
                }
                return {
                    ...total,
                    // change to another posClass and back to initial causes the initialSelectedItem is not set
                    [item.tag]: ((initialSelectedItem && !initalDataIsSet) ? { value: initialSelectedItem.tag, label: initialSelectedItem.tag } : { value: 'null', label: '----' })
                }
            }, {});
            setFormikInitialValues(state => {
                return ({
                    posClass: selectedPosClass,
                    lemma: (lemma ? lemma : state.lemma),
                    ...reduced
                })
            });
            setInitialDataIsSet(true);
        }
    }, [singleCategories]);

    useEffect(() => {
        // request is pending - on success - hide whole modal, on error:
        setRequestIsPending(false);
    }, [errors]);

    const onLemmaChange = useCallback((lemma) => {
        setLemma(lemma);
    }, []);

    const handleSubmit = (values) => {
        setRequestIsPending(true);
        const params = {
            tokens: tokens.reduce((total, token) => [...total, currentTokens[token].tokenNo], []),
            posTag: (values.posClass.label === '----' ? null : values.posClass.label),
            categories: (
                singleCategories ?
                    singleCategories.reduce((total, item) => {
                        return ({
                            ...total,
                            [item.tag]: values[item.tag].value
                        })
                    }, {})
                    : null
            ),
            lemma: values.lemma,
        }
        dispatch(annotationModalSendAnnotation({ params, editing: edit }));
    }

    const sendClear = () => {
        const params = {
            tokens: tokens.reduce((total, token) => [...total, currentTokens[token].tokenNo], []),
            posTag: null,
            categories: null,
            lemma: null,
        }
        dispatch(annotationModalSendAnnotation({ params, editing: true }));
    }

    if (!tokens || Object.keys(tokens).length < 1) {
        return <Loading />
    }

    return (
        <div className="modal--form__inner">
            <div className="ann-modal__header">
                <div className="ann-modal__header--name">Token: </div>
                <div className="ann-modal__header--content">
                    {
                        (tokens && tokens.length > 1 && annotateTogetherModal) ?
                            annotateTogetherModal.normalized :
                            tokens.map(item => currentTokens[item].token).join(' ')
                    }
                </div>
            </div>

            { (!extendedPosClasses || requestIsPending) && <Loading />}

            { extendedPosClasses && (
                <div className="ann-modal__edit-form" style={requestIsPending ? { display: 'none' } : {}}>


                    { (errors.annotation || errors.splittedToken) &&
                        <div className="ann-modal__edit-form__error">{errors.annotation || errors.splittedToken}</div>
                    }

                    <Formik
                        enableReinitialize={true}
                        initialValues={formikInitialValues}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, setFieldValue }) => (
                            <Form className="ann-modal__split__form">
                                <div className="ann-modal__edit-form--content">
                                    <div className="ann-modal__edit-form__field__group">
                                        {extendedPosClasses && (
                                            <Field
                                                name={'posClass'}
                                                propName={dict[`annotation.modal.class`]}
                                                component={KeyboardSelectField}
                                                options={extendedPosClasses}
                                                autoFocus={true}
                                                tabIndex={1}
                                                handleChange={(value) => {
                                                    onSelectPosClass(value);
                                                    setFieldValue('posClass', value);
                                                }}
                                                additionalClassName={'ann-select-keyboard'}
                                                error={errors['posClass']}
                                                dict={dict}
                                            />
                                        )}
                                    </div>

                                    {loadingSingleCategories && (
                                        <div className="ann-modal__edit-form__field__group" style={{ display: 'block' }}>
                                            <Loading />
                                        </div>
                                    )}

                                    {singleCategories &&
                                        <div
                                            className="ann-modal__edit-form__field__group"
                                            style={(loadingSingleCategories || singleCategories.length < 1) ? { display: 'none' } : {}}
                                        >
                                            {singleCategories.map((category, index) => {
                                                return (
                                                    <Field
                                                        key={category.tag}
                                                        name={category.tag}
                                                        propName={(category[currentLang].charAt(0).toUpperCase() + category[currentLang].slice(1)) + ':'}
                                                        component={KeyboardSelectField}
                                                        options={[
                                                            {
                                                                value: 'null',
                                                                label: '----'
                                                            },
                                                            ...category.values.map(({ tag }) => ({
                                                                value: tag,
                                                                label: tag
                                                            }))
                                                        ]}
                                                        autoFocus={false}
                                                        // autoFocus={index === 0 ? true : false}
                                                        tabIndex={index + 2}
                                                        handleChange={(value) => {
                                                            setFieldValue(category.tag, value);
                                                        }}
                                                        additionalClassName={'ann-select-keyboard'}
                                                        error={errors[category.tag]}
                                                        dict={dict}
                                                    //menuIsOpen={true}
                                                    />
                                                )
                                            })}
                                        </div>
                                    }
                                    <div className="ann-modal__edit-form__field__group">
                                        <Field
                                            type="text"
                                            tabIndex={10}
                                            name="lemma"
                                            component={FormInputComponentColumn}
                                            label={dict[`annotation.modal.lemma`]}
                                            additionalClassName="ann-modal__edit-form__text-input"
                                            onBlur={(ev) => onLemmaChange(ev.nativeEvent.target.value)}
                                            dict={dict}
                                            // onChangeValue={onLemmaChange}
                                        />
                                    </div>
                                </div>
                                <div className="ann-modal__edit-form__buttons">
                                    {edit && (
                                        <button
                                            // disabled={isSubmitting}
                                            type="button"
                                            tabIndex={11}
                                            className="modal--form__button"
                                            onClick={() => sendClear()}
                                        >
                                            {dict['form.clear']}
                                        </button>
                                    )}
                                    <button type="submit"
                                        // disabled={isSubmitting}
                                        tabIndex={12}
                                        className="modal--form__button"
                                    >
                                        {dict[`form.confirm`]}
                                    </button>
                                </div>
                                <FormikReduxValidator />
                            </Form>
                        )}
                    </Formik>
                </div>
            )
            }
        </div >
    )
}
export default ModalAnnotationAnnotate
