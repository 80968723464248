import React from 'react';
import Error from './Error.js';
import withContexts from 'helpers/contextsMerge'

const GeneralError = ({errMsg, langContext: {
    dict
  }}) => {
  return (
    <Error title={`${dict[`error.message.${errMsg}`]}`} errMsg={errMsg}>
      <h2 className="error__subheader">{`${dict[`error`]} ${errMsg}`}</h2>
      <div
        dangerouslySetInnerHTML={{
        __html: dict[`error.${errMsg}`]
      }}></div>
    </Error>
  );
}

export default withContexts(GeneralError);
