import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withContexts from 'helpers/contextsMerge'
import * as Actions from 'actions';
import { Formik, Field, Form } from 'formik';
import FormInputComponent from 'components/form/InputField.js';
import SelectField from 'components/form/SelectField';

class ModalAddNewUser extends Component {
	submitForm = (values) => {
		const { actions } = this.props;
		actions.addNewUser(values);
	}

	componentWillUnmount() {
		const { actions } = this.props;
		actions.resetUsersError();
	}

	render() {
		const { langContext: {
			dict
		}, error, actions, roles } = this.props;

		const initialValues = {
			login: '',
			email: '',
			role: 'editor',
			newPassword: '',
			repeatPassword: ''
		};

		return (
			<div className="modal--form__inner">
				<div className="modal--form__header">
					{dict[`user.new_user`]}
				</div>
				<div className="modal--form__content">
					<Formik
						initialValues={initialValues}
						validate={values => {
							let errors = {};
							if (!values.login) {
								errors.login = dict[`form.required`];
							}
							if (!values.email) {
								errors.email = dict[`form.required`];
							}
							if (!values.newPassword) {
								errors.newPassword = dict[`form.required`];
							}
							if (!values.repeatPassword) {
								errors.repeatPassword = dict[`form.required`];
							}

							return errors;
						}}
						onSubmit={(values, { setSubmitting, setErrors }) => {
							if (values.newPassword !== values.repeatPassword) {
								setErrors({ form: dict[`form.not_equal`] });
							} else {
								this.submitForm(values);
							}
							setSubmitting(false);
						}}>
						{({ resetForm, isSubmitting, errors, setFieldValue }) => (
							<Form>
								{error && <div className="modal--form__error">{error}</div>}
								{errors.form && <div className="modal--form__error">{errors.form}</div>}
								<div className="modal--form__field">
									<Field type="text" name="login" dict={dict} component={FormInputComponent} />
								</div>
								<div className="modal--form__field">
									<Field type="text" name="email" dict={dict} component={FormInputComponent} />
								</div>
								<div className="modal--form__field">
									<Field
										name={'role'}
										component={SelectField}
										options={roles.map(role => { return { value: role.code, label: dict[`user.roles.${role.code}`] } })}
										handleChange={(value) => {
											setFieldValue('role', value.value)
										}}
                                        dict={dict}
									/>
								</div>
								<div className="modal--form__field">
									<Field type="password" name="newPassword" dict={dict} component={FormInputComponent} />
								</div>
								<div className="modal--form__field">
									<Field type="password" name="repeatPassword" dict={dict} component={FormInputComponent} />
								</div>
								<div className="modal--form__buttons">
									<button
										onClick={() => {
											resetForm();
											actions.resetUsersError();
											actions.resetAppAlert();
										}}
										className="modal--form__button--clear" type="button">
										{dict[`form.cancel`]}
									</button>
									<button type="submit" disabled={isSubmitting} className="modal--form__button">
										{dict[`form.create`]}
									</button>
								</div>
							</Form>
                            )}
					</Formik>
				</div>
			</div>
		);
	}
}

ModalAddNewUser.propTypes = {
	error: PropTypes.string,
	roles: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string,
		dictType: PropTypes.string,
		ord: PropTypes.number,
		value: PropTypes.string,
	}))
};

const mapStateToProps = state => ({
	roles: state.users.roles,
	error: state.users.error,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withContexts(connect(mapStateToProps, mapDispatchToProps)(ModalAddNewUser));
