export default [
    {
      id: 1,
      name: 'contact',
      link: '/about?id=5'
    },
//    {
//      id: 2,
//      name: 'sitemap',
//      link: '/sitemap'
//    },
    {
      id: 2,
      name: 'policy',
      link: '/policy'
    },
    {
      id: 3,
      name: 'accessibility',
      link: '/policy?id=2'
    },
]
