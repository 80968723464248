import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as AppActions from 'actions';


const ModalSourceDelete = ({dict}) => {
    const parentId = useSelector((state) => state.manageSources.modalAdditionalInfo.id);
    const dispatch = useDispatch();

    return (
        <div className="modal--form__inner-wide">
            <div className="modal--form__content">
                <div className="modal--form__inner-group">
                    {dict[`sources.confirmDelete`]}
                </div>
                <div className="modal--form__buttons">
                    <button
                        onClick={() => {
                            dispatch(AppActions.resetUsersError());
                            dispatch(AppActions.resetAppAlert());
                        }}
                        className="modal--form__button--clear" type="button">
                        {dict[`form.cancel`]}
                    </button>
                    <button
                        type="button"
                        onClick={() => dispatch(AppActions.sourceDeleteNode(parentId))}className="modal--form__button">
                        {dict[`sources.remove`]}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ModalSourceDelete;
