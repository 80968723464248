import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import GeneralError from './errors/GeneralError.js';

const AdminRoute = ({ children, user, ...rest }) => (
    <Route {...rest} render={props => (
        user.isSet && !user.role ? 
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            : !user.isSet ?
                <></>
                : (user.role.code === 'admin' || user.role.code === 'editor') ? 
                    <>{children}</>
                    : <GeneralError errMsg="403" />
    )} />
)

export default AdminRoute;