import React from 'react';
// import { useLocation } from 'react-router';
import withContexts from 'helpers/contextsMerge'
import { NavLink } from 'react-router-dom';

import sitemap from 'constants/sitemap.js';

const Sitemap = ({ langContext: { dict } }) => {
	return (
		<div className={`sitemap`}>
			<ul className="sitemap__list">
				{sitemap.map(listItem => <li className="sitemap__list-item" key={listItem.id} >
					<NavLink
						to={`${listItem.link}`}
						className={"sitemap__list-item__link" + ((listItem.disabled) ? " disabled" : "")}
						activeClassName="active"
						title={dict[`sitemap.nav_list.${listItem.name}`]}>
						<span>{dict[`sitemap.nav_list.${listItem.name}`]}</span>
					</NavLink>
				</li>)}
			</ul>
		</div>
	)
}

export default withContexts(Sitemap);
