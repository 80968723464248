import React from 'react';
import { useDispatch } from 'react-redux';

import { keystrokesEnabled } from 'actions/annotation.actions';

const HeaderSearchPanelInput = ({
    field, // { name, value, onChange, onBlur }
    form: {
        touched,
        errors
    }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    placeholder,
    label,
    dict,
    ...props
}) => {
    const dispatch = useDispatch();
    return (
        <div className="header__form__field">
            <label htmlFor={field.name} className="header__form__field__label">
                {label ? <span dangerouslySetInnerHTML={{ __html: label }}></span> : `${dict[`form.${field.name}`]}:`}
            </label>
            <input
                {...field}
                {...props}
                className="header__form__field__input"
                placeholder={placeholder}
                onFocus={() => dispatch(keystrokesEnabled(false))}
                onBlur={() => dispatch(keystrokesEnabled(true))}
            />
            {touched[field.name] && errors[field.name] &&
                <div className="header__form__field__error">
                    {errors[field.name]}
                </div>}
        </div>
    )
};

export default HeaderSearchPanelInput;
