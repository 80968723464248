import React, { memo, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const HeaderMainNav = ({ list, isMainPage, dict }) => {
    const { pathname } = useLocation();
    const [currentPath, setCurrentPath] = useState('');
    useEffect(() => {
        if (pathname.split('/').length > 3) {
            return setCurrentPath('/' + pathname.split('/').slice(1, 3).join('/'));
        }
        setCurrentPath(pathname);
    }, [pathname]);
    return (
        <nav className={`header__main-nav ${!isMainPage ? 'subpage' : ''}`}>
            {currentPath && (
                <ul className="header__main-nav__list">
                    {list.map(listItem => {
                        return (
                            <li className="header__main-nav__list-item" key={listItem.id} >
                                <NavLink
                                    to={`${listItem.link[0]}`}
                                    className={"header__main-nav__list-item__button" + ((listItem.disabled) ? " disabled" : "")}
                                    activeClassName="active"
                                    title={dict[`header.nav_list.${listItem.name}`]}
                                    isActive={() => listItem.link.includes(currentPath)}
                                >
                                    <span>{dict[`header.nav_list.${listItem.name}`]}</span>
                                    <span className="header__main-nav__active-bar"></span>
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
            )}
        </nav>
    )
}

export default memo(HeaderMainNav);
