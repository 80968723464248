import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAppToken } from 'actions/index';

import getCSRFToken from './CSRFToken';
import useInterval from './useInterval';

const TokenTest = () => {
    const dispatch = useDispatch();
    const cToken = useSelector((state) => state.app.CSRFToken);
    const [removeInterval, setRemoveInterval] = useState(false);
    useInterval(() => {
        if (getCSRFToken() && !cToken){
            dispatch(setAppToken(getCSRFToken()));
        }else{
            setRemoveInterval(true);
        }
    }, 100, removeInterval);

    return null;
};

export default TokenTest
