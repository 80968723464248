import { combineReducers } from 'redux'
import page from './page'
import texts from './texts'
import text from './text'
import app from './app'
import user from './user'
import users from './users'
import manageTexts from './manage-texts'
import manageSources from './manage-sources'
import manageText from './manage-text'
import textTabs from './text-tabs'
import cards from './cards'
import otherTrans from './other-trans'
import results from './results'
import parallels from './parallels'
import themes from './themes'
import annotation from './annotation';
import { loadingBarReducer } from 'react-redux-loading-bar'

export default combineReducers({
    texts,
    text,
    app,
    page,
    user,
    users,
    textTabs,
    manageTexts,
    manageText,
    manageSources,
    cards,
    otherTrans,
    results,
    parallels,
    themes,
    annotation,
    loadingBar: loadingBarReducer
})
