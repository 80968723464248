import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'actions/index';
import * as AppActions from 'actions';
import { Formik, Field, Form } from 'formik';
import Loading from 'components/Loading.js';
import getNodeById from 'helpers/getNodeById';
import FileUploadField from 'components/form/FileUploadField.js';
import FormInputComponentColumn from 'components/form/InputFieldColumn.js';
import TableCheckboxFieldLabelRight from 'components/form/TableCheckboxFieldLabelRight.js';


const ModalAddSourcePackage = ({dict}) => {
    const isEditing = useSelector((state) => state.manageSources.modalAdditionalInfo.edit);
    const parentId = useSelector((state) => state.manageSources.modalAdditionalInfo.id);
    const modalError = useSelector((state) => state.manageSources.modalAdditionalInfo.error);
    const isLoading = useSelector((state) => state.manageSources.modalAdditionalInfo.isLoading);
    const nodes = useSelector((state) => state.manageSources.tree);
    const dispatch = useDispatch();

    const [initialValues, setInitialValues] = useState();

    const submitForm = (values) => {
        dispatch(Actions.sourcesUpload(values, parentId, isEditing));
    }

    useEffect(() => {
        if (isEditing) {
            const node = getNodeById(parentId, nodes);
            setInitialValues(
                {
                    file: '',
                    namePl: node.name.pl,
                    nameEn: node.name.en,
                    abbrPl: node.abbr.pl,
                    abbrEn: node.abbr.en,
                    show: node.show,
                })
        } else {
            setInitialValues(
                {
                    file: '',
                    namePl: '',
                    nameEn: '',
                    abbrPl: '',
                    abbrEn: '',
                    show: false
                })
        }
    }, [isEditing]);

    return (
        <div className="modal--form__inner-wide">
            <div className="modal--form__header">
                {!isEditing ? dict[`sources.addPackage`] : dict[`sources.editPackage`]}
            </div>
            {isLoading && (
                <div className="modal--loading">
                    <Loading />
                </div>
            )}
            <div className="modal--form__content" style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
                {initialValues &&
                    <Formik
                        initialValues={initialValues}
                        validate={values => {
                            let errors = {};
                            if (!values.file) {
                                errors.file = dict[`form.required`];
                            }
                            return errors;
                        }}
                        onSubmit={(values) => {
                            submitForm(values);
                        }}>
                        {({ resetForm, isSubmitting, errors, setFieldValue }) => (
                            <Form>
                                {/* {error && <div className="modal--form__error">{error}</div>} */}
                                {errors.form && <div className="modal--form__error">{errors.form}</div>}
                                <div className="modal--form__row">
                                    <div />
                                    <div className='modal-checkbox'>
                                        <Field
                                            name={`show`}
                                            component={TableCheckboxFieldLabelRight}
                                            setValues={(values) => {
                                                setFieldValue(`show`, values.show)
                                            }}
                                            label={dict[`sources.show`]}
                                        />
                                    </div>
                                </div>
                                <div className="modal--form__row">
                                    <div className="modal--form__field-column">
                                        <Field
                                            type="text"
                                            name="namePl"
                                            autoFocus={true}
                                            tabIndex={1}
                                            component={FormInputComponentColumn}
                                            dict={dict}
                                            label={dict[`sources.namePl`]}
                                        />
                                    </div>
                                    <div className="modal--form__field-column">
                                        <Field
                                            type="text"
                                            name="abbrPl"
                                            tabIndex={3}
                                            dict={dict}
                                            component={FormInputComponentColumn}
                                            label={dict[`sources.abbrPl`]} />
                                    </div>
                                </div>
                                <div className="modal--form__row">
                                    <div className="modal--form__field-column">
                                        <Field
                                            type="text"
                                            name="nameEn"
                                            tabIndex={2}
                                            component={FormInputComponentColumn}
                                            label={dict[`sources.nameEn`]}
                                            dict={dict}
                                        />
                                    </div>
                                    <div className="modal--form__field-column">
                                        <Field
                                            type="text"
                                            tabIndex={4}
                                            name="abbrEn"
                                            component={FormInputComponentColumn}
                                            label={dict[`sources.abbrEn`]}
                                            dict={dict}
                                        />
                                    </div>
                                </div>

                                <div className="modal--form__upload">
                                    <Field type="file" name="file"
                                        label={!isEditing ? dict[`sources.uploadZip`] : dict[`sources.updateZip`]}
                                        component={FileUploadField}
                                        handleUpload={(event) => {
                                            setFieldValue("file", event.currentTarget.files[0]);
                                        }}
                                    />
                                </div>
                                {!!modalError && (
                                    <div className="modal--form__error">{modalError}</div>
                                )}
                                <div className="modal--form__buttons">
                                    <button
                                        onClick={() => {
                                            resetForm();
                                            dispatch(AppActions.resetUsersError());
                                            dispatch(AppActions.resetAppAlert());
                                        }}
                                        className="modal--form__button--clear" type="button">
                                        {dict[`form.cancel`]}
                                    </button>
                                    <button type="submit" className="modal--form__button">
                                        {!isEditing ? dict[`form.create`] : dict[`sources.update`]}
                                    </button>
                                </div>
                            </Form>)}
                    </Formik>
                }
            </div>
        </div>
    )
}

export default ModalAddSourcePackage;
