import React from 'react';

const FormInputComponent = ({
    field, // { name, value, onChange, onBlur }
    form: {
        touched,
        errors
    }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    dict,
    ...props
}) => {
    return (
        <div className="form__field">
            <label htmlFor={field.name} className="form__field__label">
                {label ? <span dangerouslySetInnerHTML={{ __html: label }}></span> : `${dict[`form.${field.name}`]}:`}
            </label>
            <input {...field} {...props} className="form__field__input" />
            {touched[field.name] && errors[field.name] &&
                <div className="form__field__error">
                    {errors[field.name]}
                </div>}
        </div>
    )
}

export default React.memo(FormInputComponent);
