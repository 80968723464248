import React from 'react';
import { withRouter } from 'react-router';
import withContexts from 'helpers/contextsMerge';

import chevronDown from 'images/icons/A_iko_2_strzalka_dol.svg'
import chevronDownActive from 'images/icons/A_iko_2_strzalka_dol_active.svg'

import chevronUp from 'images/icons/A_iko_2_strzalka_gora.svg'
import chevronUpActive from 'images/icons/A_iko_2_strzalka_gora_active.svg'

class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: false
        };
    }

    toggleOpened = () => {
        this.setState((state) => ({
            isOpened: !state.isOpened
        }))
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        const { pathcontext: { currentPath } } = this.props;

        if (currentPath !== prevProps.pathcontext.currentPath) {
            this.setState({ isOpened: false })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.node && !this.node.contains(event.target)) {
            this.setState({ isOpened: false });
        }
    }

    render() {
        const { children, btnText, langContext: { dict } } = this.props;
        const { isOpened } = this.state;

        return (
            <div className="dropdown" ref={node => this.node = node}>
                <button className={`dropdown__button ${isOpened ? 'active' : ''}`} onClick={() => this.toggleOpened()}
                    title={dict[`home.user.panel`]}>
                    {btnText}
                    {!isOpened && <img className="dropdown__button__img regular" src={chevronDown} alt="" />}
                    {!isOpened && <img className="dropdown__button__img hover" src={chevronDownActive} alt="" />}
                    {isOpened && <img className="dropdown__button__img regular" src={chevronUp} alt="" />}
                    {isOpened && <img className="dropdown__button__img hover" src={chevronUpActive} alt="" />}
                </button>
                {isOpened && <div className="dropdown__box">
                    {children}
                </div>}
            </div>
        );
    }
}

export default withContexts(withRouter(Dropdown));
