import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withContexts from 'helpers/contextsMerge'
import * as Actions from 'actions/index';
import { Formik, Field, Form } from 'formik';
import FormInputComponentColumn from 'components/form/InputFieldColumn.js';
import getNodeById from 'helpers/getNodeById';

import TableCheckboxFieldLabelRight from 'components/form/TableCheckboxFieldLabelRight';

class ModalAddSourceFolder extends Component {
    submitForm = (values) => {
        const { actions, parentId, isEditing } = this.props;
        const sendObj = {
            name: {
                pl: values.namePl,
                en: values.nameEn
            },
            abbr: {
                pl: values.abbrPl,
                en: values.abbrEn
            },
            show: values.show
        }
        if (isEditing) {
            actions.sourceEditFolder(sendObj, parentId);
        } else {
            actions.sourceAddFolder(sendObj, parentId);
        }
    }

    componentWillUnmount() {
        const { actions } = this.props;
        actions.resetUsersError();
    }
    render() {
        const { langContext: {
            dict
        }, error, actions, isEditing, nodes, parentId, modalError } = this.props;

        const initialValues = {
            namePl: '',
            nameEn: '',
            abbrPl: '',
            abbrEn: '',
            show: false,
        };

        if (isEditing) {
            const node = getNodeById(parentId, nodes);
            initialValues.namePl = node.name.pl ? node.name.pl : "";
            initialValues.nameEn = node.name.en ? node.name.en : "";
            initialValues.abbrPl = node.abbr.pl ? node.abbr.pl : "";
            initialValues.abbrEn = node.abbr.en ? node.abbr.en : "";
            initialValues.show = !!node.show;
        }

        return (
            <div className="modal--form__inner-wide">
                <div className="modal--form__header">
                    {dict[isEditing ? `sources.editSubfolder` : `sources.addSubfolder`]}
                </div>
                <div className="modal--form__content">
                    <Formik
                        initialValues={initialValues}
                        //   validate={values => {
                        //     let errors = {};
                        //     if (!values.login) {
                        //       errors.login = dict[`form.required`];
                        //     }
                        //     if (!values.email) {
                        //       errors.email = dict[`form.required`];
                        //     }
                        //     if (!values.newPassword) {
                        //       errors.newPassword = dict[`form.required`];
                        //     }
                        //     if (!values.repeatPassword) {
                        //       errors.repeatPassword = dict[`form.required`];
                        //     }
                        //     return errors;
                        //   }}
                        onSubmit={(values, { setSubmitting, setErrors }) => {
                            // if (values.newPassword !== values.repeatPassword) {
                            // 	setErrors({ form: dict[`form.not_equal`] });
                            // } else {
                            // 	this.submitForm(values);
                            // }
                            // setSubmitting(false);
                            this.submitForm(values);
                        }}>
                        {({ resetForm, errors, setFieldValue }) => (
                            <Form>
                                {error && <div className="modal--form__error">{error}</div>}
                                {errors.form && <div className="modal--form__error">{errors.form}</div>}
                                <div className="modal--form__row">
                                    <div className="klik"></div>
                                    <div className='modal-checkbox'>
                                        <Field
                                            name={`show`}
                                            component={TableCheckboxFieldLabelRight}
                                            tabIndex={5}
                                            setValues={(values) => {
                                                setFieldValue(`show`, values.show)
                                            }}
                                            label={dict[`sources.show`]}
                                        />
                                    </div>
                                </div>

                                <div className="modal--form__row">
                                    <div className="modal--form__field-column">
                                        <Field
                                            type="text" name="namePl"
                                            autoFocus={true}
                                            tabIndex={1}
                                            dict={dict}
                                            component={FormInputComponentColumn}
                                            label={dict[`sources.namePl`]}
                                        />
                                    </div>
                                    <div className="modal--form__field-column">
                                        <Field
                                            type="text"
                                            tabIndex={3}
                                            name="abbrPl"
                                            dict={dict}
                                            component={FormInputComponentColumn}
                                            label={dict[`sources.abbrPl`]}
                                        />
                                    </div>
                                </div>
                                <div className="modal--form__row">
                                    <div className="modal--form__field-column">
                                        <Field
                                            type="text"
                                            tabIndex={2}
                                            name="nameEn"
                                            dict={dict}
                                            component={FormInputComponentColumn}
                                            label={dict[`sources.nameEn`]}
                                        />
                                    </div>

                                    <div className="modal--form__field-column">
                                        <Field
                                            type="text"
                                            tabIndex={4}
                                            name="abbrEn"
                                            dict={dict}
                                            component={FormInputComponentColumn}
                                            label={dict[`sources.abbrEn`]}
                                        />
                                    </div>
                                </div>
                                {!!modalError && (
                                    <div className="modal--form__error">{modalError}</div>
                                )}
                                <div className="modal--form__buttons">
                                    <button
                                        onClick={() => {
                                            resetForm();
                                            actions.resetAppAlert();
                                        }}
                                        className="modal--form__button--clear" type="button">
                                        {dict[`form.cancel`]}
                                    </button>
                                    <button type="submit"
                                        // disabled={isSubmitting}
                                        tabIndex={5}
                                        className="modal--form__button">
                                        {isEditing ? dict[`form.save`] : dict[`form.add`]}
                                    </button>
                                </div>
                            </Form>)}
                    </Formik>
                </div>
            </div>
        );
    }
}

ModalAddSourceFolder.propTypes = {
    // error: PropTypes.string,
    // roles: PropTypes.arrayOf(PropTypes.shape({
    // 	code: PropTypes.string,
    // 	dictType: PropTypes.string,
    // 	ord: PropTypes.number,
    // 	value: PropTypes.string,
    // }))
};

const mapStateToProps = state => ({
    parentId: state.manageSources.modalAdditionalInfo.id,
    isEditing: state.manageSources.modalAdditionalInfo.edit,
    modalError: state.manageSources.modalAdditionalInfo.error,
    nodes: state.manageSources.tree
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
});

export default withContexts(connect(mapStateToProps, mapDispatchToProps)(ModalAddSourceFolder));
