import React from 'react';
import PropTypes from 'prop-types';

import contrastIcon from 'images/icons/A_iko_2_kontrast.svg';
import contrastIconHover from 'images/icons/A_iko_2_kontrast__hover.svg';
import contrastIconActive from 'images/icons/A_iko_2_kontrast__active.svg';
import contrastIconChecked from 'images/icons/A_iko_2_kontrast_active_kontrast.svg';

const HeaderTopContrast = ({ setContrast, isContrast, dict }) => {
    return (
        <button className="header__top__contrast" title={dict[`header.top.contrast`]} onClick={() => setContrast()}>
            {!isContrast && <img src={contrastIcon} className="icon regular" alt={dict[`header.top.contrast.regular`]} />}
            {!isContrast && <img src={contrastIconHover} className="icon hover" alt={dict[`header.top.contrast.regular`]} />}
            {!isContrast && <img src={contrastIconActive} className="icon active" alt={dict[`header.top.contrast.regular`]} />}
            {isContrast && <img src={contrastIconChecked} className="icon checked" alt={dict[`header.top.contrast.checked`]} />}
        </button>
    )
}
HeaderTopContrast.propTypes = {
    setContrast: PropTypes.func,
    isContrast: PropTypes.bool,
    dict: PropTypes.object
}

export default React.memo(HeaderTopContrast);
