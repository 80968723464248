import React from 'react';

const FooterPartContainer = ({children}) => {
  return (
    <div className="footer__part-container">
      {children}
    </div>
  )
}

export default FooterPartContainer;
