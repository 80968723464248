import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Field, Form } from 'formik';
import * as Actions from 'actions/index';
import FileUploadField from 'components/form/FileUploadField.js';

const initialValues = {
	file: ''
};

const ModalUploadParallels = ({dict}) => {
	const dispatch = useDispatch();
	const { modalData } = useSelector(state => state.parallels);
	// const { error } = useSelector(state => state.manageText.error);

	return (
		<>
			{modalData && (
				<div className="modal--form__inner">
					<div className="modal--form__header">
						{(modalData.name === "THEMES" && modalData.mode === "upload") &&
							dict[`parallels.uploadThemes`]
						}
						{(modalData.name === "THEMES" && modalData.mode === "update") &&
							dict[`parallels.updateThemes`]
						}
						{(modalData.name === "PARALLEL_FRAGMENTS" && modalData.mode === "upload") &&
							dict[`parallels.uploadParallels`]
						}
						{(modalData.name === "PARALLEL_FRAGMENTS" && modalData.mode === "update") &&
							dict[`parallels.updateParallels`]
						}

						{(modalData.name === "PHONETIC_VARIANTS" && modalData.mode === "upload") &&
							dict[`parallels.uploadPhoneticVariants`]
						}
						{(modalData.name === "PHONETIC_VARIANTS" && modalData.mode === "update") &&
							dict[`parallels.updatePhoneticVariants`]
						}

					</div>
					<div className="modal--form__content">
						<Formik
							initialValues={initialValues}
							validate={values => {
								let errors = {};
								if (!values.file) {
									errors.file = dict[`form.required`];
								}

								return errors;
							}}
							onSubmit={(values, { setSubmitting }) => {
								dispatch(Actions.uploadParallels({ file: values.file, name: modalData.name, mode: modalData.mode }))
								setSubmitting(false);
							}} >
							{({ resetForm, isSubmitting, errors, setFieldValue }) => (
								<Form>
									{/* {error && <div className="modal--form__error">{error}</div>}
									{errors.form && <div className="modal--form__error">{errors.form}</div>} */}
									<div className="modal--form__field">
										<Field type="file" name="file"
											label={dict[`parallels.selectFile`]}
											component={FileUploadField}
											handleUpload={(event) => {
												setFieldValue("file", event.currentTarget.files[0]);
											}}
										/>
									</div>
									<div className="modal--form__buttons">
										<button
											onClick={() => {
												resetForm();
												dispatch(Actions.resetUsersError());
												dispatch(Actions.resetAppAlert());
											}}
											className="modal--form__button--clear" type="button">
											{dict[`form.cancel`]}
										</button>
										<button type="submit" disabled={isSubmitting} className="modal--form__button">
											{dict[`form.create`]}
										</button>
									</div>
								</Form>)}
						</Formik>
					</div>
				</div>
			)}
		</>
	)
}

export default ModalUploadParallels;
