import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import {
    annotationModalConfirmClose, annotationModalReset
} from 'actions/annotation.actions.js';

const ModalAnnotationConfirmLeave = ({dict}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { modalData } = useSelector(state => state.annotation);

    const confirmLeaving = () => {
        if (modalData.link) {
            dispatch(annotationModalConfirmClose());
            dispatch(annotationModalReset());
            history.push(modalData.link);
        }
    }
    const cancelLeaving = () => {
        dispatch(annotationModalConfirmClose());
        dispatch(annotationModalReset());
    }

    return (
        <div className="modal--form__inner">
            <div className="ann-modal__leave">
                <div className="ann-modal__leave__text">
                    {(modalData && modalData.next) ? (
                        <div>{dict['annotation.modal.confirmLeave.comment1.next']}</div>
                    ) : (
                        <div>{dict['annotation.modal.confirmLeave.comment1.prev']}</div>
                    )}

                    <div>{dict['annotation.modal.confirmLeave.comment2']}</div>
                </div>

                <div className="ann-modal__edit-form__buttons">
                    <button
                        // disabled={isSubmitting}
                        type="button"
                        className="modal--form__button"
                        onClick={() => cancelLeaving()}
                    >
                        {dict['form.cancel']}
                    </button>
                    <button
                        type="button"
                        className="modal--form__button"
                        onClick={() => confirmLeaving()}
                    >
                        {dict[`annotation.modal.confirmLeave.confirm`]}
                    </button>
                </div>

            </div>
        </div>
    )
}
export default ModalAnnotationConfirmLeave;
