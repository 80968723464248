const initialState = {
    parallelsThemesData: [],
    modalData: {
        mode: null,
        name: null
    }
};

export default function parallels(state = initialState, action) {
    switch (action.type) {
        case 'RECEIVE_PARALLELS_THEMES_LIST':
            return {
                ...state,
                parallelsThemesData: action.data
            };
        case 'SET_PARALLELS_THEMES_MODAL_DATA':
            return{
                ...state,
                modalData: action.data
            }
        default:
            return state
    }
}
