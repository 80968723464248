import React from 'react';

import fontIcon from 'images/icons/A_iko_2_czcionka.svg';
import fontIconActive from 'images/icons/A_iko_2_czcionka_active.svg';
import fontIconHover from 'images/icons/A_iko_2_czcionka_hover.svg';
import fontIconChecked from 'images/icons/A_iko_2_czcionka_active_1.svg';
import fontIconChecked2 from 'images/icons/A_iko_2_czcionka_active_2.svg';
import fontIconCheckedContrast from 'images/icons/A_iko_2_czcionka_active_kontrast.svg';

const HeaderTopFonts = ({ changeFontSize, fontSize, isContrast, dict }) => {
	return (
		<button className="header__top__font" title={dict[`header.top.font`]} onClick={() => changeFontSize()}>
			{fontSize === '' && <img src={fontIcon} className="icon regular" alt={dict[`header.top.font.regular`]} />}
			{fontSize === '' && <img src={fontIconHover} className="icon hover" alt={dict[`header.top.font.regular`]} />}
			{fontSize === '' && <img src={fontIconActive} className="icon active" alt={dict[`header.top.font.regular`]} />}
			{!isContrast && fontSize === 'medium' && <img src={fontIconChecked} className="icon checked" alt={dict[`header.top.font.checked`]} />}
			{fontSize === 'big' && <img src={fontIconChecked2} className="icon checked_2" alt={dict[`header.top.font.checked_2`]} />}
			{isContrast && fontSize === 'medium' && <img src={fontIconCheckedContrast} className="icon checked contrast" alt={dict[`header.top.font.checked`]} />}
		</button>
	)
}

export default HeaderTopFonts;
