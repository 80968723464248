const initialState = {
  transToDelete: {},
  transToUpdate: {},
  error: null,
  trans: {}
};

export default function texts(state = initialState, action) {
  switch (action.type) {
    case 'SET_TRANS_TO_DELETE':
      return {
        ...state,
        transToDelete: {...action.data}
      };
    case 'SET_TRANS_TO_UPDATE':
      return {
        ...state,
        transToUpdate: {...action.data}
      };
    case 'SET_OTHER_TRANS_ERROR':
      return {
        ...state,
        error: action.data
      };
    case 'RESET_OTHER_TRANS_ERROR':
      return {
        ...state,
        error: null
      };
    case 'RECEIVE_OTHER_TRANS':
      return {
        ...state,
        trans: {
            ...state.trans,
            ...action.data
        }
      };
    default:
      return state
  }
}