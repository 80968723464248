import { requestLoadApp, setPageError, resetPageError, resetAppAlert, setAppAlert } from './index';

/* GRAMMATIC ANNOTATIONS */
const annotationReceiveTexts = (data) => {
    return (dispatch) => {
        dispatch({ type: 'ANNOTATION_RECEIVE_TEXTS', isLoading: false, data });
    }
}
export const annotationLoadTexts = () => {
    return (dispatch) => {
        dispatch(requestLoadApp());
        fetch('/api/texts/active-texts-names', { accept: "application/json" }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            }
            throw new Error(response.status);
        }).then(json => {
            dispatch(annotationReceiveTexts(json))
            dispatch(resetPageError())
        }).catch(err => dispatch(setPageError(err)));
    }
}


const receiveSentences = (data) => {
    return (dispatch) => {
        dispatch({ type: 'RECEIVE_SENTENCES', isLoading: false, data });
    }
};
export const loadSentences = (query) => {
    return (dispatch) => {
        dispatch(requestLoadApp());
        fetch(`/api/annotation/sentences/${query}`)
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                throw new Error(response.status);
            })
            .then(json => {
                // dispatch(resetPageError());
                dispatch(receiveSentences(json));
            })
            .catch(err => dispatch(setPageError(err)));
    }
};

export const loadAnnotationEditorData = (query = null) => {
    return (dispatch) => {
        if (!query) return
        dispatch(clearEditorData());
        fetch(`/api/annotation/editor/${query}`)
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                throw new Error(response.status);
            })
            .then(json => {
                dispatch(receiveAnnotationEditorData(json));
            })
            .catch(err => dispatch(setPageError(err)));
    }
}
const receiveAnnotationEditorData = (data) => {
    return (dispatch) => {
        dispatch({ type: 'RECEIVE_ANNOTATION_EDITOR_DATA', isLoading: false, data });
    }
}
export const clearEditorData = () => {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_EDITOR_DATA' })
    }
}



// editor data
const receiveAnnotationReloadedEditorData = (data) => {
    return (dispatch) => {
        dispatch({ type: 'RECEIVED_ANNOTATION_RELOADED_EDITOR_DATA', data });
    }
}

export const putAnnotationModalSplit = (data) => {
    return (dispatch, getState) => {
        dispatch(annotationModalSetLoading(true));
        fetch(`/api/annotation/split-word/${data.code}/${data.sentenceNo}/${data.wordNo}`,
            {
                method: 'PUT',
                headers: {
                    'X-CSRF-TOKEN': getState().app.CSRFToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ partition: data.partition })
            })
            .then(response => {
                if ((response.status >= 200 && response.status < 300) || response.status === 400) {
                    return response.json();
                }
                throw new Error(response.status);
            }).then(json => {
                if (json.error) {
                    return dispatch(annotationModalError({ errors: { annotationSplit: json.error } }));
                } else {
                    dispatch(receiveAnnotationReloadedEditorData(json.data));
                    dispatch(resetAppAlert());
                    dispatch(annotationModalSetLoading(false));
                    if (json.activeAnnotation) {
                        dispatch(annotationSelectAnnotation({ annotationId: json.activeAnnotation }));
                    }
                }
            }).catch(err => {
                return dispatch(annotationModalError({ errors: { annotationSplit: "Błąd serwera, proszę spróbować później" } }));
            });
    }
}



export const annotationSetSpans = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'ANNOTATION_SET_SPANS',
            payload
        })
    }
}

// export const annotationSetSelectedSpans = ({ selectedSpans }) => {
//     return (dispatch) => {
//         dispatch({
//             type: 'ANNOTATION_SET_SELECTED_SPANS',
//             payload: { selectedSpans }
//         })
//     }
// }

// subtoken (left) click without ctrl:
export const annotationSelectSubtoken = ({ annotationId, tokenId }) => {
    return (dispatch) => {
        dispatch({
            type: 'ANNOTATION_SELECT_SUBTOKEN',
            payload: { annotationId, tokenId }
        })
    }
}

// subtoken (left) click with ctrl
export const annotationSelectedSpansAddRemove = ({ tokenId }) => {
    return (dispatch) => {
        dispatch({
            type: 'ANNOTATION_SELECTED_SPANS_ADD_REMOVE',
            payload: { tokenId }
        })
    }
}

export const annotationSelectNextSpan = () => {
    return (dispatch, getState) => {
        if (getState().annotation.modalData) return
        // when modal keyboard is disabled
        dispatch({
            type: 'ANNOTATION_SELECT_NEXT_SPAN'
        })
    }
}

export const annotationSelectPrevSpan = () => {
    return (dispatch, getState) => {
        if (getState().annotation.modalData) return
        // when modal keyboard is disabled
        dispatch({
            type: 'ANNOTATION_SELECT_PREV_SPAN'
        })
    }
}

// clicked on right - select this annotation and highlight related spans
export const annotationSelectAnnotation = ({ annotationId }) => {
    return (dispatch) => {
        dispatch({
            type: 'ANNOTATION_SELECT_ANNOTATION',
            payload: { annotationId }
        })
    }
}

export const annotationToggleShortcuts = () => {
    return (dispatch) => {
        dispatch({
            type: 'ANNOTATION_TOGGLE_SHORTCUTS'
        })
    }
}


// annotation modals
export const annotationModalSplit = (data) => {
    return (dispatch, getState) => {
        if (getState().annotation.modalData) return
        // when modal keyboard is disabled
        dispatch({ type: 'ANNOTATION_SET_MODAL_SPLIT', data });
        dispatch(setAppAlert(data.setAppAlertData.modalClass,
            data.setAppAlertData.modalName,
            data.setAppAlertData.modalClassName,
        ));
    }
}
export const annotationModalAnnotate = ({ tokens, edit, setAppAlertData, annotationId }) => {
    return (dispatch, getState) => {
        if (getState().annotation.modalData) return
        // when modal keyboard is disabled
        dispatch({
            type: 'ANNOTATION_SET_MODAL_ANNOTATE', data: {
                tokens,
                annotationId,
                edit
            }
        });
        dispatch(setAppAlert(
            setAppAlertData.modalClass,
            setAppAlertData.modalName,
            setAppAlertData.modalClassName,
        ));
    }
}

export const onKeyAnnotationModalAnnotate = (data) => {
    return (dispatch, getState) => {
        const selectedArr = getState().annotation.tokensData.selectedSpans;
        const selectedAnns = getState().annotation.tokensData.selectedAnnotations;
        const singleAnns = getState().annotation.editorData.data.singleAnnotations;
        const editMode = (selectedAnns.length === 1 && singleAnns && singleAnns[selectedAnns].id) ? true : false;
        const addId = editMode ? singleAnns[selectedAnns[0]].id : null;

        dispatch(annotationModalAnnotate({
            tokens: selectedArr,
            annotationId: addId,
            // edit: editMode,
            setAppAlertData: data.setAppAlertData
        }))
    }
}

export const onKeyAnnotationModalSplit = (data) => {
    return (dispatch, getState) => {
        if (getState().annotation.mode !== 'single' || getState().annotation.modalData) return

        const selSpans = getState().annotation.tokensData.selectedSpans;
        // if (selSpan.length > 1) return

        const selToken = getState().annotation.editorData.data.singleTokens[getState().annotation.tokensData.spans.find(item => item.spanId === selSpans[0]).tokenId];
        if (selToken.subtokensIds.length !== selSpans.length || selToken.splittable.toString() !== 'true') return

        if (selSpans.length > 1 && selSpans.filter(item => !selSpans.includes(item)).length > 0) return

        // console.log(getState().annotation.editorData.data.singleTokens[selSubToken].splittable.toString() !== 'true');
        // const curSelToken = getState().annotation.editorData.data.singleTokens[selSubToken];
        // if ((curSelToken.subtokensIds.length === 1) && curSelToken.token.toString().length < 2 && curSelToken.originalTokenId === null) return
        dispatch(annotationModalSplit({ ...data, tokenId: getState().annotation.tokensData.selectedAnnotations[0] }));
    }
}

export const annotationModalError = (payload) => {
    return (dispatch) => {
        dispatch({ type: 'ANNOTATION_SET_MODAL_ERROR', payload })
    }
}
export const annotationModalSetLoading = (loading = false) => {
    return (dispatch) => {
        dispatch({ type: 'ANNOTATION_SET_MODAL_LOADING', loading });
    }
}
export const annotationModalReset = () => {
    return (dispatch) => {
        dispatch({ type: 'ANNOTATION_RESET_MODAL_DATA' });
    }
}

const annotationsModalReceivedPosclasses = (posClasses) => {
    return (dispatch) => {
        dispatch({ type: 'ANNOTATIONS_MODAL_RECEIVED_POS_CLASSES', payload: { posClasses } });
    }
}

export const annotationModalGetPosClasses = () => {
    return (dispatch) => {
        fetch(`/api/annotation/pos-classes`)
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                throw new Error(response.status);
            }).then(json => {
                if (json.error) {
                    return dispatch(annotationModalError({ errors: { posClasses: json.error } }));
                }
                dispatch(annotationsModalReceivedPosclasses(json));
            }).catch(err => {
                return dispatch(annotationModalError({ errors: { posClasses: "Internal server error" } }));
            });
    }
}
export const annotationModalGetSingleCategories = (className) => {
    return (dispatch) => {
        dispatch(annotationModalSetLoading(true));
        fetch(`/api/annotation/single-categories/${className}`)
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                throw new Error(response.status);
            }).then(json => {
                if (json.error) {
                    return dispatch(annotationModalError({ errors: { posClasses: json.error } }));
                }
                dispatch(annotationsModalReceivedSingleCategories(json));
                dispatch(annotationModalSetLoading(false));
            }).catch(err => {
                return dispatch(annotationModalError({ errors: { posClasses: "Internal server error" } }));
            });
    }
}
const annotationsModalReceivedSingleCategories = (singleCategories) => {
    return (dispatch) =>
        dispatch({ type: 'ANNOTATIONS_MODAL_SET_SINGLE_CATEGORIES', payload: { singleCategories } });
}
export const annotationModalClearSingleCategories = () => {
    return (dispatch) => dispatch({ type: 'ANNOTATION_MODAL_CLEAR_SINGLE_CATEGORIES' });
}

export const annotationModalSendAnnotation = (data) => {
    return (dispatch, getState) => {
        if (getState().annotation.modalData && getState().annotation.modalData.loading) return;
        dispatch(annotationModalSetLoading(true));
        fetch(`/api/annotation/single/annotate/${getState().annotation.editorData.code}/${getState().annotation.editorData.sentenceNo}`,
            {
                method: 'PUT',
                headers: {
                    'X-CSRF-TOKEN': getState().app.CSRFToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data.params)
            })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                throw new Error(response.status);
            }).then(json => {
                if (json.error) {
                    return dispatch(annotationModalError({ errors: { annotation: json.error } }));
                } else {
                    dispatch(resetAppAlert());
                    dispatch(receiveAnnotationReloadedEditorData(json.data));
                    dispatch(annotationModalSetLoading(false));
                    if (json.activeAnnotation) {
                        dispatch(annotationSelectAnnotation({ annotationId: json.activeAnnotation }));
                    }
                }
            }).catch(err => {
                return dispatch(annotationModalError({ errors: { splittedToken: err.toString() } }));
                // return dispatch(annotationModalError({ errors: { splittedToken: "Nieoczekiwany błąd serwera" } }));
            });
    }
}

export const receiveAnnotateTogetherData = (data) => {
    return (dispatch) => dispatch({ type: 'ANNOTATION_ANNOTATE_TOGETHER_RES', payload: data });
}

export const annotateTogetherRequestPending = (isPending) => {
    return (dispatch) => dispatch({ type: 'ANNOTATE_TOGETHER_REQUEST_PENDING', payload: { isPending } })
}

export const clearSelectedSpans = () => {
    return (dispatch) => dispatch({ type: 'CLEAR_SELECTED_SPANS' })
}

export const requestAnnotatateTogether = ({ tokens, doubleMode = false }) => {
    return (dispatch, getState) => {
        if (getState().annotation.annotateTogetherRequestPending) return;

        dispatch(receiveAnnotateTogetherData(null));
        dispatch(annotateTogetherRequestPending(true));
        fetch(`/api/annotation/${doubleMode ? 'double/rule/' : 'single/hint/'}${getState().annotation.editorData.code}/${getState().annotation.editorData.sentenceNo}`, {
            method: 'PUT',
            headers: {
                'X-CSRF-TOKEN': getState().app.CSRFToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ tokens })
        })
            .then(response => {
                dispatch(annotateTogetherRequestPending(false));
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                throw new Error(response.status);
            })
            .then(json => {
                dispatch(receiveAnnotateTogetherData({ ...json, tokens }));
            })
            .catch(err => dispatch(setPageError(err)));
    }
}


export const annotationModalConfirm = ({ link, next, setAppAlertData }) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'ANNOTATION_SET_MODAL_CONFIRM', data: {
                link, next
            }
        });

        dispatch(setAppAlert(
            setAppAlertData.modalClass,
            setAppAlertData.modalName,
            setAppAlertData.modalClassName,
        ));
    }
}

export const annotationModalConfirmClose = () => {
    return (dispatch) => {
        dispatch(resetAppAlert());
        dispatch(annotationModalReset());
    }
}


export const annotationModalAnnotateDouble = (tokens) => {
    return (dispatch, getState) => {
        dispatch(annotationModalSetLoading(true));
        fetch(`/api/annotation/double/add-new/${getState().annotation.editorData.code}/${getState().annotation.editorData.sentenceNo}`,
            {
                method: 'PUT',
                headers: {
                    'X-CSRF-TOKEN': getState().app.CSRFToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ tokens: tokens })
            })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                throw new Error(response.status);
            }).then(json => {
                if (json.error) {
                    return dispatch(annotationModalError({ errors: { annotation: json.error } }));
                } else {
                    dispatch(resetAppAlert());
                    dispatch(receiveAnnotationReloadedEditorData(json.data));
                    dispatch(annotationModalSetLoading(false));
                    if (json.activeAnnotation) {
                        dispatch(annotationSelectAnnotation({ annotationId: json.activeAnnotation }));
                    }
                }
            }).catch(err => {
                return dispatch(annotationModalError({ errors: { splittedToken: "Internal server error" } }));
            });
    }
}

export const annotationDoubleDeleteAnnotation = (tokenId) => {
    return (dispatch, getState) => {
        fetch(`/api/annotation/double/delete/${getState().annotation.editorData.code}/${getState().annotation.editorData.sentenceNo}/${tokenId}`,
            {
                method: 'DELETE',
                headers: {
                    'X-CSRF-TOKEN': getState().app.CSRFToken,
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                }
                throw new Error(response.status);
            }).then(json => {
                if (json.error) {
                    return dispatch(annotationModalError({ errors: { annotation: json.error } }));
                } else {
                    dispatch(receiveAnnotationReloadedEditorData(json.data));
                    if (json.activeAnnotation) {
                        dispatch(annotationSelectAnnotation({ annotationId: json.activeAnnotation }));
                    }
                }
            }).catch(err => {
                return dispatch(annotationModalError({ errors: { splittedToken: "Internal server error" } }));
            });
    }
}

export const onKeyDoubleAnnotate = () => {
    return (dispatch, getState) => {
        const { status, tokens } = getState().annotation.annotateTogetherModal;
        if (status === 'ANNOTATION_POSSIBLE' && tokens) {
            dispatch(annotationModalAnnotateDouble(tokens));
        }
    }
}

export const keystrokesEnabled = (enabled = false) => {
    return (dispatch) => {
        dispatch({
            type: 'SET_KEYSTROKES_ENABLED', payload: {
                keystrokesEnabled: enabled
            }
        });
    }
}
