import React, { Component, Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from 'actions';
import * as UserActions from '../services/userService.js';
import PropTypes from 'prop-types';
import { instanceOf } from 'prop-types';
import LoadingBar from 'react-redux-loading-bar'
import { withCookies, Cookies } from 'react-cookie'
import { withRouter } from 'react-router';

import Header from '../containers/header/Header';
import Footer from './footer/Footer';
import CookiesBar from '../components/Cookies';
import { LangContext, PathContext } from 'helpers/contexts';
import AppError from '../components/errors/AppError.js';
import GeneralError from '../components/errors/GeneralError.js';
import Loading from '../components/Loading.js';
import PrivateRoute from '../components/PrivateRoute.js';
import AdminRoute from '../components/AdminRoute.js';
import PublicRoute from '../components/PublicRoute.js';
import Modal from '../containers/modal/Modal.js';
import Sitemap from '../containers/sitemap/Sitemap.js';

import AnchorWrapper from './page/AnchorWrapper';

import menuNavSecondary from 'constants/menuNavSecondary.js';
import menuNav from 'constants/menuNav.js';

import TokenTest from 'helpers/TokenTest'

// import AnchorTest from '../containers/sitemap/AnchorTest.js';

const Page = (lazy(() => import('../containers/page/Page')));
const ModalPage = (lazy(() => import('../containers/page/ModalPage')));
const Home = (lazy(() => import('../containers/home/Home')));
const Texts = (lazy(() => import('../containers/texts/Texts')));
const Text = (lazy(() => import('../containers/text/Text')));
const SourcesTree = (lazy(() => import('./sources/SourcesTree')));
const SourcesResultsPage = (lazy(() => import('./sources-results/SourcesResultsPage')));
const SourcesComparePage = (lazy(() => import('./sources-compare/SourcesComparePage')));
const Login = (lazy(() => import('../containers/user/Login')));
const Users = (lazy(() => import('../containers/users/Users')));
const ManageTexts = (lazy(() => import('../containers/manage-texts/ManageTexts')));
const ManageText = (lazy(() => import('../containers/manage-texts/ManageText')));
const ManageSources = (lazy(() => import('../containers/manage-sources/ManageSources')));
const ParallelsImport = (lazy(() => import('../containers/parallels/ParallelsImport')));
const ThemesPage = (lazy(() => import('../containers/themes/ThemesPage')));
const ThemesResultsPage = (lazy(() => import('../containers/themes/ThemesResultsPage')));
const AnnotationSelectText = (lazy(() => import('../containers/annotation/AnnotationSelectText')));
const AnnotationSelectSentence = (lazy(() => import('../containers/annotation/AnnotationSelectSentence')));
const AnnotationEditor = (lazy(() => import('../containers/annotation/AnnotationEditor')));

const Cards = (lazy(() => import('./cards/Cards')));
const OtherTrans = (lazy(() => import('./cards/OtherTrans')));
const Card = (lazy(() => import('./cards/Card')));
const ResultsPage = (lazy(() => import('./results/ResultsPage')));
const WordsPage = (lazy(() => import('./words/WordsPage')));
const TextTabs = (lazy(() => import('./text-tabs/TextTabs')));


class App extends Component {
    constructor(props) {
        super(props);
        const { cookies } = props;

        this.state = { isSetCookies: cookies.get('policy'), currentPath: '', query: '' };
    }

    static getDerivedStateFromProps(props, state) {
        const pathName = props.history.location.pathname;
        const query = props.history.location.search;

        if (pathName !== state.currentPath || query !== state.query) {
            return {
                currentPath: pathName,
                query: query
            };
        }

        return null;
    }

    componentDidMount() {
        const { actions, userActions } = this.props;

        actions.getAppTitle();
        actions.getAppLang();
        userActions.checkLoggedIn();

        // cookies.remove('policy')
    }

    componentDidUpdate(prevProps, prevState) {
        const { actions, currentLang } = this.props;

        if (this.state.currentPath !== prevState.currentPath) {
            this.props.actions.resetAppAlert();
            this.props.actions.resetAppError();
            this.props.actions.resetPageError();
        }

        if (currentLang && currentLang !== prevProps.currentLang) {
            actions.getAppTitle();
        }

        if (prevProps.title !== this.props.title) {
            document.title = this.props.title;
        }

    }

    setCookie = () => {
        const { cookies } = this.props;

        cookies.set('policy', true, { path: '/' });
        this.setState({ isSetCookies: true });
    }

    setLang = newLang => {
        const { actions } = this.props;

        actions.setNewLang(newLang);
    }

    setContrast = () => {
        const { actions, isContrast } = this.props;

        const body = document.querySelector("body");

        if (isContrast) {
            body.classList.remove("contrast");
        } else {
            body.classList.add("contrast");
        }

        actions.setContrast();
    }

    toggleModal = () => {
        const { actions } = this.props;
        actions.resetAppAlert();
    }

    render() {
        const { isSetCookies, currentPath, query } = this.state;
        const { dictionary, langs, isAppError, currentLang, alerts, user, breadCrumbsName, isContrast } = this.props;

        if (isAppError) {
            return <AppError />;
        }

        if (!(currentPath && langs && dictionary)) {
            return null;
        }

        return (
            // <React.StrictMode>
            <div className={`app ${isContrast ? 'contrast' : ''}`}>
                <LangContext.Provider value={{ currentLang: currentLang, langs: langs, dict: dictionary }}>
                    <PathContext.Provider value={{ currentPath: currentPath, query: query }}>
                        <LoadingBar
                            showFastActions
                            style={{ backgroundColor: '#ce9756', height: '3px', position: 'fixed', top: '0', left: '0' }}
                            updateTime={100}
                            maxProgress={100}
                            progressIncrease={10}
                        />
                        <TokenTest />
                        <Header
                            langs={langs}
                            setLang={this.setLang}
                            setContrast={this.setContrast}
                            isContrast={isContrast}
                            dict={dictionary}
                            navItems={menuNav}
                            currentLang={currentLang}
                        />
                        <Suspense fallback={<Loading />}>
                            <Switch>
                                <Route exact path="/">
                                    <Home />
                                </Route>
                                <Route path="/results" exact={true}>
                                    <ResultsPage dict={dictionary} />
                                </Route>
                                <Route path="/words" exact={true}>
                                    <WordsPage dict={dictionary} currentLang={currentLang} mode={'WORDS'} />
                                </Route>
                                <Route path="/search/words" exact={true}>
                                    <WordsPage dict={dictionary} currentLang={currentLang} mode={'WORDS_SEARCH'} />
                                </Route>
                                <Route path="/grammar/single" exact={true}>
                                    <WordsPage dict={dictionary} currentLang={currentLang} mode={'GRAMMAR_SINGLE'} />
                                    {/* <GrammarsPage dict={dictionary} /> */}
                                </Route>
                                <Route path="/search/grammar/single" exact={true}>
                                    <WordsPage dict={dictionary} currentLang={currentLang} mode={'GRAMMAR_SEARCH_SINGLE'} />
                                </Route>
                                <Route path="/grammar/double" exact={true}>
                                    <WordsPage dict={dictionary} currentLang={currentLang} mode={'GRAMMAR_DOUBLE'}/>
                                    {/* <GrammarsPage dict={dictionary} /> */}
                                </Route>
                                <Route path="/search/grammar/double" exact={true}>
                                    <WordsPage dict={dictionary} currentLang={currentLang} mode={'GRAMMAR_SEARCH_DOUBLE'}/>
                                </Route>


                                <Route path="/texts" exact={true}>
                                    <Page>
                                        <Texts />
                                    </Page>
                                </Route>
                                <Route path="/texts/item" exact={true}>
                                    <Text />
                                </Route>
                                <Route path="/sources" exact={true}>
                                    <Page>
                                        <SourcesTree />
                                    </Page>
                                </Route>
                                <Route path="/sources/search/:id" exact={true}>
                                    <SourcesResultsPage />
                                </Route>
                                <Route path="/sources/compare/:id" exact={true}>
                                    <SourcesComparePage />
                                </Route>
                                <Route path="/themes" exact={true}>
                                    <Page>
                                        <ThemesPage />
                                    </Page>
                                </Route>
                                <Route path="/themes/search/event-themes/:id" exact={true}>
                                    <ThemesResultsPage isEventThemes={true} />
                                </Route>
                                <Route path="/themes/search/person-themes/:id" exact={true}>
                                    <ThemesResultsPage isEventThemes={false} />
                                </Route>

                                <PublicRoute path="/login">
                                    <ModalPage>
                                        <Login />
                                    </ModalPage>
                                </PublicRoute>
                                <Route path="/about" exact={true}>
                                    <AnchorWrapper>
                                        <TextTabs user={user} />
                                    </AnchorWrapper>
                                </Route>
                                <Route path="/elabo" exact={true}>
                                    <AnchorWrapper>
                                        <TextTabs user={user} />
                                    </AnchorWrapper>
                                </Route>
                                <Route path="/policy" exact={true}>
                                    <AnchorWrapper>
                                        <TextTabs user={user} />
                                    </AnchorWrapper>
                                </Route>
                                <Route path="/sitemap" exact={true}>
                                    <Page>
                                        <Sitemap />
                                    </Page>
                                </Route>
                                <AdminRoute path="/manage-texts" user={user} exact={true}>
                                    <Page>
                                        <ManageTexts />
                                    </Page>
                                </AdminRoute>
                                <AdminRoute path="/manage-sources" user={user} exact={true}>
                                    <Page>
                                        <ManageSources />
                                    </Page>
                                </AdminRoute>
                                <AdminRoute path="/manage-texts/text" user={user} exact={true}>
                                    <Page breadCrumbsName={breadCrumbsName}>
                                        <ManageText />
                                    </Page>
                                </AdminRoute>
                                <AdminRoute path="/manage-texts/cards" user={user} exact={true}>
                                    <Page breadCrumbsName={breadCrumbsName}>
                                        <Cards />
                                    </Page>
                                </AdminRoute>
                                <AdminRoute path="/manage-texts/other-transcript" user={user} exact={true}>
                                    <Page breadCrumbsName={breadCrumbsName}>
                                        <OtherTrans />
                                    </Page>
                                </AdminRoute>
                                <AdminRoute path="/manage-texts/card" user={user} exact={true}>
                                    <Page breadCrumbsName={breadCrumbsName}>
                                        <Card />
                                    </Page>
                                </AdminRoute>
                                <AdminRoute path="/import-others" user={user} exact={true}>
                                    <Page breadCrumbsName={breadCrumbsName}>
                                        <ParallelsImport />
                                    </Page>
                                </AdminRoute>
                                <AdminRoute path="/annotation" user={user} exact={true}>
                                    <Page>
                                        <AnnotationSelectText dict={dictionary} />
                                    </Page>
                                </AdminRoute>
                                <AdminRoute path="/annotation/sentences" user={user} exact={true}>
                                    <Page>
                                        <AnnotationSelectSentence dict={dictionary} />
                                    </Page>
                                </AdminRoute>
                                <AdminRoute path="/annotation/editor" user={user} exact={true}>
                                    <AnnotationEditor dict={dictionary} />
                                </AdminRoute>
                                <PrivateRoute path="/users">
                                    <Page>
                                        <Users user={user} />
                                    </Page>
                                </PrivateRoute>
                                <Route component={() => <GeneralError errMsg="404" dict={dictionary} />} />
                            </Switch>
                        </Suspense>
                        <Footer dict={dictionary} navItems={menuNavSecondary} />
                        <Modal onClose={this.toggleModal} alerts={alerts} currentPath={currentPath} />
                        {!isSetCookies &&
                            <CookiesBar setCookie={this.setCookie} />
                        }
                    </PathContext.Provider>
                </LangContext.Provider>
            </div>
            // </React.StrictMode>
        );
    }
}

App.propTypes = {
    title: PropTypes.string,
    langs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        lang: PropTypes.string,
        active: PropTypes.bool,
    })),
    dictionary: PropTypes.shape({}),
    isAppError: PropTypes.bool.isRequired,
    isContrast: PropTypes.bool.isRequired,
    currentLang: PropTypes.string,
    cookies: instanceOf(Cookies).isRequired,
    alerts: PropTypes.shape({ isModal: PropTypes.bool.isRequired }),
    user: PropTypes.shape({ id: PropTypes.number, login: PropTypes.string, email: PropTypes.string, active: PropTypes.bool, role: PropTypes.shape({}) }),
};

const mapStateToProps = state => ({
    title: state.app.title,
    langs: state.app.langs,
    dictionary: state.app.dict,
    isAppError: state.app.isAppError,
    currentLang: state.app.currentLang,
    alerts: state.app.alerts,
    isContrast: state.app.isContrast,
    user: state.user.user,
    breadCrumbsName: state.app.breadCrumbsName,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch)
});

export default withRouter(withCookies(connect(mapStateToProps, mapDispatchToProps)(App)));
