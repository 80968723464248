const initialState = {
  texts: [],
  textsNames: [],
  error: null,
};

export default function manageTexts(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_LOAD_MANAGE_TEXTS_LIST':
      return {
        ...state,
        texts: action.data
      };
    case 'RECEIVE_TEXTS_NAMES':
      return {
        ...state,
        textsNames: action.data
      };
    case 'SET_TEXTS_ERROR':
      return {
        ...state,
        error: action.err
      };
    case 'RESET_TEXTS_ERROR':
      return {
        ...state,
        error: null
      };
    default:
      return state
  }
}
