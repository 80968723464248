export default [
    {
        id: 1,
        name: 'main',
        link: '/',
    },
    {
        id: 2,
        name: 'texts',
        link: '/texts',
    },
    {
        id: 3,
        name: 'sources',
        link: '/sources',
    },
    {
        id: 4,
        name: 'themes',
        link: '/themes',
    },
    {
        id: 5,
        name: 'words',
        link: '/words',
    },
    {
        id: 6,
        name: 'grammatical_forms',
        link: '/grammar/single',
    },
    {
        id: 7,
        name: 'policy',
        link: '/policy',
    },
    {
        id: 8,
        name: 'accessibility',
        link: '/policy?id=2',
    },
    {
        id: 9,
        name: 'about',
        link: '/about',
    },
    {
        id: 10,
        name: 'elabo',
        link: '/elabo',
    },
]
