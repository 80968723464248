import React from 'react';
import { useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import ModalContent from './ModalContent.js';

const Modal = ({ onClose, alerts, currentPath }) => {
    const open = alerts.isModal;
    const path = currentPath.split('/')[1];

    const { modalName, modalClassName } = useSelector(state => state.app.alerts);

    return open
        ? ReactDOM.createPortal(
            <div className={`modal ${path}`}>
                <div className={`modal__box${modalClassName ? modalClassName : ''}`}>

                    <div className={`modal__top${modalName ? ' hasName' : ''}`}>
                        {modalName && (
                            <div className="modal__top--title">{modalName}</div >
                        )}

                        <button className="modal__close" onClick={onClose}>
                            <span className="icon-close"></span>
                        </button>
                    </div>
                    <div className="modal__inner">
                        <ModalContent alerts={alerts} />
                    </div>
                </div>
            </div>, document.body)
        : null
}

export default Modal;
