import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

const AnchorWrapper = ({ children }) => {
    const location = useLocation();
    const checkIfHashNodeExistst = () => {
        const element = document.getElementsByName(location.hash.replace('#', ''))[0];
        if (element) {
            document.removeEventListener('DOMSubtreeModified', checkIfHashNodeExistst);
            // window.scrollTo({
            //     behavior: element ? "smooth" : "auto",
            //     top: element ? element.offsetTop : 0
            // });
            element.scrollIntoView();
        }
    }

    useEffect(() => {
        if (location && location.hash) {
            document.addEventListener('DOMSubtreeModified', checkIfHashNodeExistst);
            checkIfHashNodeExistst();
        }
    }, [location]);

    return (
        <div className={'page__dynamic--wrapper'}>
            { children }
        </div>
    )
}
export default AnchorWrapper