import React from 'react';
import {withRouter} from 'react-router';
import withContexts from 'helpers/contextsMerge';
import {Link} from 'react-router-dom';

const Cookies = ({setCookie, langContext: {
    dict
  }}) => {
  return (
    <div className="cookies">
      <h3 className="cookies__header">
        <span className="icon-cookies"></span>
        {dict[`cookies.header`]}
      </h3>
      <div className="cookies__desc">
        {dict[`cookies.desc_1`]}
      </div>
      <div className="cookies__desc">
        <span>{dict[`cookies.desc_2`]}
        </span>
        <Link to={`/policy`} className="cookies__desc__link">
          {dict[`cookies.link`]}
        </Link>.
      </div>
      <div className="cookies__accept">
        <button className="cookies__accept__button" onClick={setCookie}>
          {dict[`cookies.accept`]}
        </button>
      </div>
    </div>
  );
}

export default withContexts(withRouter(Cookies));
