const initialState = {
    isPageError: false,
    breadCrumbs: [],
    alternativePageTitle: null,
    pageTitleComment: null,
};

export default function page(state = initialState, action) {
    switch (action.type) {
        case 'SET_PAGE_ERROR':
            return {
                ...state,
                isPageError: true,
                pageErr: action.err
            };
        case 'RESET_PAGE_ERROR':
            return {
                ...state,
                isPageError: false,
            };
        case 'SET_BREADCRUMBS':
            return {
                ...state,
                breadCrumbs: action.data
            };
        case 'RESET_BREADCRUMBS':
            return {
                ...state,
                breadCrumbs: []
            };
        case 'UPDATE_BREADCRUMBS':
            return {
                ...state,
                breadCrumbs: state.breadCrumbs.concat(action.data)
            };

        case 'SET_ALTERNATIVE_PAGE_TITLE':
            return {
                ...state,
                alternativePageTitle: action.data
            };

        case 'RESET_ALTERNATIVE_PAGE_TITLE':
            return {
                ...state,
                alternativePageTitle: null
            };

        case 'SET_PAGE_TITLE_COMMENT':
            return {
                ...state,
                pageTitleComment: action.data
            };

        case 'RESET_PAGE_TITLE_COMMENT':
            return {
                ...state,
                pageTitleComment: null
            };

        default:
          return state
    }
}
