import {LangContext, PathContext} from './contexts';
import React from 'react';

const withContexts = Component => (
    props => (
        <LangContext.Consumer>
          {lang => (
            <PathContext.Consumer>
              {currentPath => (
                <Component langContext={lang} pathcontext={currentPath} {...props} />
              )}
            </PathContext.Consumer>
          )}
        </LangContext.Consumer>
    )
)

export default withContexts;