import React from 'react';
import {withRouter} from 'react-router';
import loader from 'images/loader.svg';
import loaderContrast from 'images/loaderContrast.svg';

const Loading = ({full = false, isContrast = false}) => {
  if (full) {
    return (
      <div className="page__loader--full">
          <img className="page__loader" src={isContrast ? loaderContrast : loader} alt=""/>
      </div>
    )
  }

  return (
    <div className="page">
      <div className="page__inner">
        <img className="page__loader" src={isContrast ? loaderContrast : loader} alt=""/>
      </div>
    </div>
  )
}



export default withRouter(Loading);
