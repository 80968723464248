import React, { useEffect } from 'react';

const FormInputComponentColumn = ({
    field, // { name, value, onChange, onBlur }
    form: {
        touched,
        errors,
        validateForm
    }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    additionalClassName = '',
    onChangeValue = null,
    dict,
    ...props
}) => {
    useEffect(() => {
        validateForm();
    }, []);

    useEffect(() => {
        if (onChangeValue){
            onChangeValue(field.value)
        }
    }, [field.value]);

    return (
        <div className={`form__field--column ${additionalClassName}`}>
            <label htmlFor={field.name} className="form__field__label">
                {label ? <span dangerouslySetInnerHTML={{ __html: label }}></span> : `${dict[`form.${field.name}`]}:`}
            </label>
            <input {...field} {...props} className="form__field__input" />
            { touched[field.name] && errors && errors[field.name] &&
                <div className="form__field__error">
                    {errors[field.name]}
                </div>}
        </div>
    )
};

export default FormInputComponentColumn;
