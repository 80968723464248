const initialState = {
  user: {},
  error: null,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_USER_DATA':
      return {
        ...state,
        user: {
          isSet: true,
          ...action.data
        },
        error: null
      };
    case 'SET_USER_ERROR':
      return {
        ...state,
        error: action.err
      };
    case 'RESET_USER_ERROR':
      return {
        ...state,
        error: null
      };
    default:
      return state
  }
}
