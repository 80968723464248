import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Actions from 'actions/index';

const ModalDeleteTransliteration = ({dict}) => {
    const dispatch = useDispatch();
    const {modalAdditionalInfo} = useSelector(state => state.manageSources);
    return (
        <div className="modal--form__inner">
            <div className="modal--form__info">
                {dict[`manage_text.delete_transliteration`]} "{modalAdditionalInfo.name}"?
            </div>
            <button
                onClick={() => {
                    dispatch(Actions.resetAppAlert());
                    dispatch(Actions.modalAdditionalInfo({}));
                }}
                className="modal--form__button--clear" type="button">
                {dict[`form.cancel`]}
            </button>
            <button type="submit" className="modal--form__button"
                onClick={() => dispatch(Actions.deleteTransilterationConfirmed(modalAdditionalInfo.code))}
            >
                {dict[`manage_text.delete`]}
            </button>
        </div>
    )
}

export default ModalDeleteTransliteration;
