import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withContexts from 'helpers/contextsMerge'
import * as Actions from 'actions';
import { Formik, Field, Form } from 'formik';
import FormInputComponent from 'components/form/InputField.js';
import FileUploadField from 'components/form/FileUploadField.js';
import queryString from 'query-string'

class ModalAddNewTrans extends Component {
    submitForm = (values) => {
        const { actions, pathcontext: { query } } = this.props;
        const code = queryString.parse(query).code;

        actions.addNewOtherTrans(values, code);
    }

    componentWillUnmount() {
        const { actions } = this.props;
        actions.resetOtherTransError();
    }

    render() {
        const { langContext: {
            dict
        }, actions, error } = this.props;

        const initialValues = {
            name: '',
            file: ''
        };

        return (
            <div className="modal--form__inner">
                <div className="modal--form__header">
                    {dict[`cards.new_transcription`]}
                </div>
                <div className="modal--form__content">
                    <div className="modal--form__info">
                        {dict[`cards.new_transcription.add`]}
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validate={values => {
                            let errors = {};
                            if (!values.name) {
                                errors.name = dict[`form.required`];
                            }
                            if (!values.file) {
                                errors.file = dict[`form.required`];
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            this.submitForm(values);
                            setSubmitting(false);
                        }}>
                        {({ resetForm, isSubmitting, errors, setFieldValue }) => (
                            <Form>
                                {error && <div className="modal--form__error">{error}</div>}
                                {errors.form && <div className="modal--form__error">{errors.form}</div>}
                                <div className="modal--form__field">
                                    <Field
                                        name={'name'}
                                        dict={dict}
                                        component={FormInputComponent}
                                    />
                                </div>
                                <div className="modal--form__field">
                                    <Field type="file" name="file"
                                        label={dict[`manage_text.form.file`]}
                                        component={FileUploadField}
                                        handleUpload={(event) => {
                                            setFieldValue("file", event.currentTarget.files[0]);
                                        }}
                                    />
                                </div>
                                <div className="modal--form__buttons">
                                    <button
                                        onClick={() => {
                                            resetForm();
                                            // actions.resetUsersError();
                                            actions.resetAppAlert();
                                        }}
                                        className="modal--form__button--clear" type="button">
                                        {dict[`form.cancel`]}
                                    </button>
                                    <button type="submit" disabled={isSubmitting} className="modal--form__button">
                                        {dict[`form.create`]}
                                    </button>
                                </div>
                            </Form>)}
                    </Formik>
                </div>
            </div>
        );
    }
}

ModalAddNewTrans.propTypes = {
    error: PropTypes.string,
};

const mapStateToProps = state => ({
    error: state.otherTrans.error,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
});

export default withContexts(connect(mapStateToProps, mapDispatchToProps)(ModalAddNewTrans));
