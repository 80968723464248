import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { setAppLoading, resetAppLoading } from 'actions/index.js';

const loadingBarMiddleware = store => next => action => {
    if (action.isLoading === true) {
        store.dispatch(showLoading())
        store.dispatch(setAppLoading())
    } else if (action.isLoading === false) {
        store.dispatch(resetAppLoading())
        store.dispatch(hideLoading())
    }
    return next(action)
}

export default loadingBarMiddleware;