import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FormikReduxValidator = ({ errors }) => {
    const formik = useFormikContext();

    useEffect(() => {
        if (errors) {
            // formik.setTouched({ splittedToken: true });
            formik.setErrors(errors);
            formik.validateForm();
        }
    }, [errors]);
    return null
}

export default FormikReduxValidator;
