function getNodeById(id, node){
    var reduce = [].reduce;
    function runner(result, node){
        if(result || !node) return result;
        return (node.id === id && node) || 
            runner(null, node.children) || 
            reduce.call(Object(node), runner, result);
    }
    return runner(null, node);
}

export default getNodeById;