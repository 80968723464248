import React, { memo, useContext, useState, useEffect, useCallback, useRef, createContext } from 'react';
import Select, { components } from 'react-select';
const ClickContext = createContext(null);


const MenuList = (props) => {
    const menuRef = useRef(null);
    // useEffect(() => {
    //     console.log("MENULIST");
    //     menuRef.current.addEventListener('scroll', (ev) => console.log(ev));
    // }, []);

    return (
        <components.MenuList
            {...props}
            innerRef={menuRef}
        />
    )
}

const Option = (props) => {
    // const element = document.getElementById(props.innerProps.id);
    const { onOptionClick } = useContext(ClickContext);

    useEffect(() => {
        if (props.isSelected) {
            const element = document.getElementById(props.innerProps.id);
            element.scrollIntoView({ behavior: 'smooth', block: "center" });
        }
    }, [props.isSelected]);

    return (
        <components.Option
            {...props}
            innerProps={{
                ...props.innerProps,
                onClick: (ev) => {
                    onOptionClick(props.data);
                    props.selectProps.onMenuClose();
                    // props.innerProps.onClick(ev);
                },
                onMouseOver: (ev) => {
                    // override default method?
                },
                onMouseMove: (ev) => {
                    // override default method?
                }
            }}
        />
    )
}

const KeyboardSelectField = (props) => {
    const {
        field,
        tabIndex,
        disabled,
        options,
        handleChange,
        autoFocus = false,
        propName = null,
        defaultValue = null,
        additionalClassName = '',
        dict,
    } = props;
    // const getMenuIsOpened = useCallback(() => menuIsOpen ? menuIsOpen : false, []);
    const customStyles = {
        control: () => ({}),
        menu: () => ({}),
        option: () => ({}),
        menuList: () => ({}),
        valueContainer: () => ({}),
        dropdownIndicator: () => ({}),
        singleValue: () => ({}),
        input: () => ({}),
    }
    const [currentSelectedElement, setCurrentSelectedElement] = useState(null);

    useEffect(() => {
        // has initial value
        if (field.value) {
            setCurrentSelectedElement(field.value);
        }
    }, [field.value]);

    const setOption = (option) => {
        setCurrentSelectedElement(option);
        handleChange(option);
    }

    const selectKeyDown = (event) => {
        const lowerKey = event.key.toLowerCase();

        // if (lowerKey.length === 1 && (/[a-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]/i.test(lowerKey))) return setCurrentLetter({ keyCode: lowerKey });
        if (lowerKey.length === 1 && (/[a-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9]/i.test(lowerKey))) {
            const mappedOptions = options.map(item => item.label.charAt(0).toLowerCase() === lowerKey ? item : null).filter(it => it);
            if (mappedOptions.length === 0) return
            const indexInMapped = mappedOptions.findIndex(mapOption => mapOption.value === currentSelectedElement.value && mapOption.label === currentSelectedElement.label);
            if (indexInMapped + 1 === mappedOptions.length) {
                setOption(mappedOptions[0]);
                return
            }
            setOption(mappedOptions[indexInMapped + 1]);
        }

        if (lowerKey !== 'arrowdown' && lowerKey !== 'arrowup' && lowerKey !== 'enter' && lowerKey !== 'tab') return
        if (!currentSelectedElement) {
            console.log("no currentSelected element");
            return;
        }

        const index = options.findIndex(item => {
            return item.value === currentSelectedElement.value && item.label === currentSelectedElement.label
        });
        switch (lowerKey) {
            case 'arrowdown':
                event.preventDefault();
                if (index === options.length - 1) {
                    setOption(options[0]);
                    return
                }
                setOption(options[index + 1]);
                break;
            case 'arrowup':
                event.preventDefault();
                if (index === 0) {
                    setOption(options[options.length - 1]);
                    return
                }
                setOption(options[index - 1]);
                break;
            case 'enter':
                event.nativeEvent.target.dispatchEvent(new KeyboardEvent("keydown", {
                    altKey: false,
                    bubbles: true,
                    cancelBubble: false,
                    cancelable: true,
                    charCode: 0,
                    which: 27,
                    code: "Escape",
                    composed: true,
                    ctrlKey: false,
                    currentTarget: null,
                    defaultPrevented: true,
                    detail: 0,
                    eventPhase: 0,
                    isComposing: false,
                    isTrusted: true,
                    key: "Escape",
                    keyCode: 27,
                    location: 0,
                    metaKey: false,
                    repeat: false,
                    returnValue: false,
                    shiftKey: false,
                    type: "keydown"
                }));
                break;
            // element.dispatchEvent(new KeyboardEvent("keydown", {
            //     key: "e",
            //     keyCode: 69, // example values.
            //     code: "KeyE", // put everything you need in this object.
            //     which: 69,
            //     shiftKey: false, // you don't need to include values
            //     ctrlKey: false,  // if you aren't going to use them.
            //     metaKey: false   // these are here for example's sake.
            //   }));
            default:
                break
        }
    }

    const onLocalChange = () => {
        setOption(currentSelectedElement);
    }
    const onOptionClick = useCallback((data) => {
        setOption(data);
    }, []);

    return (
        <div className={`form__field ${additionalClassName}`} >
            <ClickContext.Provider value={{ onOptionClick }}>
                <label htmlFor={field.name} className={`form__field__label`}>
                    {/* {dict[`form.${field.name}`]}: */}
                    {propName ? (
                        <>{propName}</>
                    ) : (
                        <>{dict[`form.${field.name}`]}:</>
                    )}

                </label>
                <Select
                    {...field}
                    {...(options.filter(option => option.value === field.value)[0] ? { value: options.filter(option => option.value === field.value)[0] } : {})}

                    onChange={onLocalChange}
                    styles={customStyles}
                    options={options}
                    autoFocus={autoFocus}
                    isDisabled={disabled}
                    isSearchable={false}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    tabIndex={tabIndex}
                    classNamePrefix="select--table"
                    className={`${disabled ? 'disabled' : ''} form__field__select`}
                    defaultValue={defaultValue}
                    openMenuOnFocus={true}
                    onKeyDown={selectKeyDown}
                    tabSelectsValue={false}
                    // openOnFocus

                    components={{ Option, MenuList }}
                // closeMenuOnSelect={false}
                />
                {/* {touched[field.name] && errors[field.name] &&
				<div className="form__field__error">
					{errors[field.name]}
				</div>} */}
            </ClickContext.Provider>
        </div>

    );
}

export default memo(KeyboardSelectField);
