import React from 'react';

const TableCheckboxFieldLabelRight = ({
    field,
    form: {
        touched,
        errors
    },
    checked,
    handleChange,
    disabled,
    setValues,
    label,
    onSwitch = null,
}) => {

    const onClicked = () => {
        if (onSwitch) onSwitch(!field.value);
        setValues({ [`${field.name}`]: !field.value });
    }

    return (
        <div className="table__form__field__checkbox-container">
            <input
                {...field}
                type="checkbox"
                className="table__form__field__checkbox--hidden"
                checked={field.value}
                onChange={(e) => { field.onChange(e); handleChange(e) }}
                disabled={disabled} />
            {disabled ?
                <div className={`${field.value ? 'checked' : ''} table__form__field__checkbox--visible`}>
                    {field.value && <span className="icon-accept icon"></span>}
                </div>
                : <div className={`${field.value ? 'checked' : ''} table__form__field__checkbox--visible`}
                    onClick={onClicked}
                    >
                    {field.value && <span className="icon-accept icon"></span>}
                </div>}
            {touched[field.name] && errors[field.name] &&
                <div className="table__form__field__error">
                    {errors[field.name]}
                </div>}
            <label
                htmlFor={field.name}
                className='form__field__label--right'
                onClick={onClicked}
            >
                {label}
            </label>
        </div>
    )
};

export default TableCheckboxFieldLabelRight;
