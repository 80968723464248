import fetch from 'isomorphic-fetch';
import getNodeById from 'helpers/getNodeById';

import { setAppAlert, setPageError, resetOtherTransError, resetAppAlert } from './index'

const SET_SOURCE_TREE = 'SET_SOURCE_TREE';
export const setSourceTree = (data) => ({ type: SET_SOURCE_TREE, isLoading: false, data });

const SET_SOURCE_LOADING = 'SET_SOURCE_LOADING';
export const setSourceLoading = (data) => ({ type: SET_SOURCE_LOADING, isLoading: false, data });


const MODAL_ADDITIONAL_INFO = 'MODAL_ADDITIONAL_INFO';
export const modalAdditionalInfo = (data) => {
	return ({ type: MODAL_ADDITIONAL_INFO, isLoading: false, data });
}

export const sourceNewPackageModal = (id) => {
	return (dispatch) => {
		dispatch(modalAdditionalInfo(id));
		dispatch(setAppAlert('source_new_package'));
	}
}

export const sourceChooseVerses = (id) => {
	return (dispatch, getState) => {
		dispatch(setAppAlert('source_choose_verses'));
		fetch(`/api/source-nodes/${id}/verses`,
			{
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'X-CSRF-TOKEN': getState().app.CSRFToken
				},
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				dispatch(modalAdditionalInfo({ verses: json, id: id }));
			})
			.catch(err => dispatch(setPageError(err)));
	}
}

export const sourceNewFolderModal = (id) => {
	return (dispatch) => {
		dispatch(modalAdditionalInfo(id));
		dispatch(setAppAlert('source_new_folder'));
	}
}

export const sourceMoveDown = ({ mypath, index }) => {
	return (dispatch, getState) => {
		dispatch(setSourceLoading(true));
		fetch(`/api/source-nodes/${mypath[mypath.length - 1]}/down`,
			{
				method: 'PUT',
				headers: {
					'X-CSRF-TOKEN': getState().app.CSRFToken
				},
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				const newOb = { ...getState().manageSources.tree };
				const node = (getNodeById(mypath[mypath.length - 2], newOb));
				node.children.splice(index + 1, 0, node.children.splice(index, 1)[0]);
				dispatch(setSourceTree(newOb));
				dispatch(setSourceLoading(false));
			})
			.catch(err => dispatch(setPageError(err)));
	}
}
export const sourceMoveUp = ({ mypath, index }) => {
	return (dispatch, getState) => {
		dispatch(setSourceLoading(true));
		fetch(`/api/source-nodes/${mypath[mypath.length - 1]}/up`,
			{
				method: 'PUT',
				headers: {
					'X-CSRF-TOKEN': getState().app.CSRFToken
				},
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				const newOb = { ...getState().manageSources.tree };
				const node = (getNodeById(mypath[mypath.length - 2], newOb));
				node.children.splice(index - 1, 0, node.children.splice(index, 1)[0]);
				dispatch(setSourceTree(newOb));
				dispatch(setSourceLoading(false));
			})
			.catch(err => dispatch(setPageError(err)));
	}
}

export const getSourceNodes = () => {
	return (dispatch, getState) => {
		if (!getState().manageSources.tree.length) {
			dispatch(setSourceLoading(true));
			fetch(`/api/source-nodes`,
				{ accept: "application/json" }).then(response => {
					if (response.status >= 200 && response.status < 300) {
						return response.json();
					}
					throw new Error(response.status);
				}).then(json => {
					dispatch(setSourceTree(json));
					dispatch(setSourceLoading(false));
				}).catch(err => dispatch(setPageError(err)));
		}
	}
}

export const getSourceNodesChildren = (nodeId) => {
	return (dispatch, getState) => {
		dispatch(setSourceLoading(true));
		fetch(`api/source-nodes/${nodeId}/children`,
			{
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'X-CSRF-TOKEN': getState().app.CSRFToken
				},
			}).then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				dispatch(pushChildrenToSources(json));
				dispatch(setSourceLoading(false));
			});
	}
}

export const sourcesUpload = (data, parentId, isEditing) => {
	return (dispatch, getState) => {
		dispatch(resetOtherTransError);
		dispatch(modalAdditionalInfo({
			...getState().manageSources.modalAdditionalInfo,
			isLoading: true
		}));
		var formdata = new FormData();
		if (data.file) {
			formdata.append("zip", data.file);
		}
		formdata.append("json", JSON.stringify({
			name: {
				pl: data.namePl,
				en: data.nameEn
			},
			"abbr": {
				pl: data.abbrPl,
				en: data.abbrEn
			},
			show: data.show
		}));
		fetch(`api/source-nodes/${parentId}/package`, {
			method: (isEditing ? 'PUT' : 'POST'),
			body: formdata,
			redirect: 'follow',
			headers: {
				'X-CSRF-TOKEN': getState().app.CSRFToken
			}
		}).then(response => {
			if (response.status >= 200 && response.status < 300) {
				return response.json();
			}
			throw new Error(response.status);
		}).then(json => {
			dispatch(setSourceLoading(false));
			if (json.error) {
				dispatch(modalAdditionalInfo({
					...getState().manageSources.modalAdditionalInfo,
					error: json.error,
					isLoading: false
				}));
			} else {
				const newOb = { ...getState().manageSources.tree };
				if (!isEditing) {
					const node = (getNodeById(json.path[json.path.length - 1], newOb));
					node.children = json.children;
					node.hasChildren = true;
				} else {
					const node = (getNodeById(parentId, newOb));
					node.name = json.node.name;
					node.hasChildren = json.node.hasChildren;
					node.abbr = json.node.abbr;
					node.hasVerses = json.node.hasVerses;
					node.children = json.children
				}
				dispatch(modalAdditionalInfo({}));
				dispatch(setSourceTree(newOb));
				dispatch(resetAppAlert());
			}
		}).catch(err => {
			dispatch(setPageError(err));
			dispatch(modalAdditionalInfo({}));
			dispatch(resetAppAlert());
		});
	}
}

export const sourceEditFolder = (data, editId) => {
	return (dispatch, getState) => {
		fetch(`api/source-nodes/${editId}/subfolder`,
			{
				method: 'PUT',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'X-CSRF-TOKEN': getState().app.CSRFToken
				},
				body: JSON.stringify(data)
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				if (json.error) {
					dispatch(modalAdditionalInfo({
						...getState().manageSources.modalAdditionalInfo,
						error: json.error
					}));
				} else {
					dispatch(resetAppAlert());
					const newOb = { ...getState().manageSources.tree };
					const node = (getNodeById(editId, newOb));
					node.name = data.name;
					node.abbr = data.abbr;
					node.show = data.show;
					dispatch(setSourceTree(newOb));
					dispatch(resetAppAlert());
				}
			});
	}
}

export const sourceAddFolder = (data, parentId, isEdit = false) => {
	const editString = isEdit ? 'PUT' : 'POST';
	return (dispatch, getState) => {
		dispatch(setSourceLoading(true));
		fetch(`api/source-nodes/${parentId}/subfolder`,
			{
				method: editString,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'X-CSRF-TOKEN': getState().app.CSRFToken
				},
				body: JSON.stringify(data)
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				dispatch(setSourceLoading(false));
				if (json.error) {
					dispatch(modalAdditionalInfo({
						...getState().manageSources.modalAdditionalInfo,
						error: json.error
					}));
				} else {
					dispatch(resetAppAlert());
					dispatch(pushChildrenToSources(json));
				}
			});
	};
}
const pushChildrenToSources = (json) => {
	return (dispatch, getState) => {
		const newOb = { ...getState().manageSources.tree };
		const node = (getNodeById(json.path[json.path.length - 1], newOb));
		node.children = json.children;
		node.hasChildren = true;
		node.type = json.type;
		dispatch(setSourceTree(newOb));
	}
}


export const sourceDeleteModal = (id) => {
	return (dispatch) => {
		dispatch(modalAdditionalInfo({ id: id }));
		dispatch(setAppAlert('source_delete'));
	}
}
export const sourceDeleteNode = (nodeId) => {
	return (dispatch, getState) => {
		dispatch(setSourceLoading(true));
		fetch(`api/source-nodes/${nodeId}`,
			{
				method: 'DELETE',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'X-CSRF-TOKEN': getState().app.CSRFToken
				},
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				const newOb = { ...getState().manageSources.tree };
				const node = (getNodeById(json.path[json.path.length - 1], newOb));
				node.hasChildren = !!(json.children.length);
				node.type = json.type;
				node.children = node.children.filter(node => node.id !== nodeId);
				dispatch(setSourceTree(newOb));
				dispatch(setSourceLoading(false));
				dispatch(resetAppAlert());
			});
	}
}


const SOURCES_RESULT = 'SOURCES_RESULT';
const setSourceResult = (data) => {
	return ({ type: SOURCES_RESULT, isLoading: false, data });
}

export const getSourceResult = (query) => {
	return (dispatch, getState) => {
		fetch(`/api/search/sources/${query}`,
			{
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'X-CSRF-TOKEN': getState().app.CSRFToken
				},
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				dispatch(setSourceResult(json));

				// const newOb = { ...getState().manageSources.tree };
				// const node = (getNodeById(json.path[json.path.length - 1], newOb));
				// node.hasChildren = !!(json.children.length);
				// node.type = json.type;
				// node.children = node.children.filter(node => node.id !== nodeId);
				// dispatch(setSourceTree(newOb));
				// dispatch(setSourceLoading(false));
				// dispatch(resetAppAlert());
			});
	}
}


const SET_SOURCES_COMPARISON = 'SET_SOURCES_COMPARISON';
export const setSourcesComparison = (data) => ({ type: SET_SOURCES_COMPARISON, isLoading: false, data });
export const compareSources = (query) => {
	return (dispatch, getState) => {
		fetch(`/api${query}`,
			{
				method: 'GET',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'X-CSRF-TOKEN': getState().app.CSRFToken
				},
			})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				}
				throw new Error(response.status);
			}).then(json => {
				dispatch(setSourcesComparison(json));
			});
	}
}