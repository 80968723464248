import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withContexts from 'helpers/contextsMerge'
import * as Actions from 'actions/index';
import { Formik, Field, Form } from 'formik';
import FormInputComponent from 'components/form/InputField.js';

class ModalResetPassword extends Component {
	submitForm = (values) => {
		const { actions, userToChange } = this.props;
		actions.changeUsersData(userToChange.login, 'password', values);
	}

	componentWillUnmount() {
		const { actions } = this.props;
		actions.resetUserToChange();
		actions.resetUsersError();
	}

	render() {
		const { langContext: {
			dict
		}, error, actions, userToChange } = this.props;

		const initialValues = {
			newPassword: '',
			repeatPassword: ''
		};

		return (
			<div className="modal--form__inner">
				<div className="modal--form__header">
					{dict[`user.reset_password`]}
				</div>
				<div className="modal--form__content">
					<Formik
						initialValues={initialValues}
						validate={values => {
							let errors = {};
							if (!values.newPassword) {
								errors.newPassword = dict[`form.required`];
							}
							if (!values.repeatPassword) {
								errors.repeatPassword = dict[`form.required`];
							}

							return errors;
						}}
						onSubmit={(values, { setSubmitting, setErrors }) => {
							if (values.newPassword !== values.repeatPassword) {
								setErrors({ form: dict[`form.not_equal`] });
							} else {
								this.submitForm(values);
							}
							setSubmitting(false);
						}}>
						{({ resetForm, isSubmitting, errors }) => (
							<Form>
								{error && <div className="modal--form__error">{error}</div>}
								{errors.form && <div className="modal--form__error">{errors.form}</div>}
								<div className="modal--form__field">
									<div className="form__field">
										<label className="form__field__label">
											{dict[`user.login`]}:
                    </label>
										<div className="form__field--filled">
											{userToChange.login}
										</div>
									</div>
								</div>
								<div className="modal--form__field">
									<Field type="password" name="newPassword" dict={dict} component={FormInputComponent} />
								</div>
								<div className="modal--form__field">
									<Field type="password" name="repeatPassword" dict={dict} component={FormInputComponent} />
								</div>
								<div className="modal--form__buttons">
									<button
										onClick={() => {
											resetForm();
											actions.resetUsersError();
										}}
										className="modal--form__button--clear" type="button">
										{dict[`form.clear`]}
									</button>
									<button type="submit" disabled={isSubmitting} className="modal--form__button">
										{dict[`form.save`]}
									</button>
								</div>
							</Form>)}
					</Formik>
				</div>
			</div>
		);
	}
}

ModalResetPassword.propTypes = {
	error: PropTypes.string,
	userToChange: PropTypes.object,
};

const mapStateToProps = state => ({
	userToChange: state.users.userToChange,
	error: state.users.error,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withContexts(connect(mapStateToProps, mapDispatchToProps)(ModalResetPassword));
