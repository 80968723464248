import { useRef, useEffect } from 'react';

const useInterval =(callback, delay, remove = false) => {
    const savedCallback = useRef();
    const savedInterval = useRef();

    useEffect(() => {
      if (remove) clearInterval(savedInterval.current);
    }, [remove]);

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      const tick = () => {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        savedInterval.current = id;
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  export default useInterval
