const initialState = {
    cards: {},
    card: {},
    error: null,
};

export default function cards(state = initialState, action) {
  switch (action.type) {
    case 'RECEIVE_CARDS':
      return {
        ...state,
        cards: {
          ...state.cards,
          ...action.data
        }
      };
    case 'RECEIVE_CARD':
      return {
        ...state,
        card: {
          ...action.data
        }
      };
    case 'RESET_CARDS':
      return {
        ...state,
        cards: {}
      };
    case 'RESET_CARD':
      return {
        ...state,
        card: {}
      };
    case 'SET_CARD_ERROR':
      return {
        ...state,
        error: action.data
      };
    case 'RESET_CARD_ERROR':
      return {
        ...state,
        error: null
      };
    default:
      return state
  }
}
  