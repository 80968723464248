import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withContexts from 'helpers/contextsMerge'
import * as Actions from 'actions';
import { history } from 'helpers/history.js';

import HeaderMainNav from './HeaderMainNav';
import HeaderLogoSection from './HeaderLogoSection';
import HeaderTopNav from './HeaderTopNav';
import HeaderMenuOpen from './HeaderMenuOpen';
import menuNav from 'constants/menuNav.js';
import * as UserActions from 'services/userService.js';

class Header extends Component {
    static defaultProps = {
        navItems: menuNav
    }

    constructor(props) {
        super(props);
        this.state = { isSearchOpened: false, isMobileMenu: false, fontSize: '' };
    }

    componentDidUpdate(prevProps) {
        const { currentKey, currentPage, pathcontext: { currentPath } } = this.props;

        if (currentPath !== prevProps.pathcontext.currentPath) {
            this.setState((state) => { return { isSearchOpened: false, isMobileMenu: false } })
        }

        const params = new URLSearchParams(this.props.pathcontext.query);
        const currentFiltersFromUrl = params.get("texts");

        if (!!currentKey && currentKey !== prevProps.currentKey && currentKey.length) {
            if (currentPath !== '/results') {
                this.setState((state) => { return { isSearchOpened: false, isMobileMenu: false } });
                history.push({
                    pathname: '/results',
                    search: `?q=${currentKey}&page=1`
                })
            } else {
                this.setState((state) => { return { isSearchOpened: false, isMobileMenu: false } });
                history.push({
                    pathname: '/results',
                    search: `?q=${currentKey}${(currentFiltersFromUrl && currentFiltersFromUrl.length) ? `&texts=${currentFiltersFromUrl}` : ''}&page=${currentPage}`
                })
            }
        }
    }

    toggleSearch = () => {
        this.setState((state) => { return { isSearchOpened: !state.isSearchOpened } })
    }

    toggleMenu = () => {
        this.setState((state) => { return { isMobileMenu: !state.isMobileMenu } })
    }

    changeFontSize = () => {
        const html = document.querySelector("html");

        if (!html.classList.contains("medium") && !html.classList.contains("big")) {
            html.classList.add("medium");
            this.setState((state) => { return { fontSize: 'medium' } })
        } else {
            if (html.classList.contains("medium")) {
                html.classList.remove("medium");
                html.classList.add("big");
                this.setState((state) => { return { fontSize: 'big' } })
            } else {
                html.classList.remove("big");
                this.setState((state) => { return { fontSize: '' } })
            }
        }
    }

    render() {
        const { navItems, setLang, userActions, user, pathcontext: { currentPath }, currentFilters, setContrast, isContrast, currentKey, dict, currentLang, langs } = this.props;
        const isMainPage = currentPath === '/';
        const { isMobileMenu, fontSize } = this.state;

        return (
            <header className="header">
                <div className={`header__top ${!isMainPage ? 'subpage' : ''}`}>
                    <HeaderLogoSection dict={dict} currentLang={currentLang} />
                    <HeaderMenuOpen toggleMenu={this.toggleMenu} />
                    <div className={`header__top__mobile ${isMobileMenu ? 'opened' : ''}`}>
                        <HeaderTopNav
                            setLang={setLang}
                            navItems={navItems} isMainPage={isMainPage}
                            logout={userActions.logout}
                            toggleSearch={this.toggleSearch}
                            isSearchOpened={this.state.isSearchOpened}
                            changeFontSize={this.changeFontSize}
                            setContrast={setContrast}
                            isContrast={isContrast}
                            fontSize={fontSize}
                            currentKey={currentKey}
                            user={user}
                            currentFilters={currentFilters}
                            dict={dict}
                            langs={langs}

                        />
                    </div>
                </div>
                <HeaderMainNav
                    list={navItems}
                    isMainPage={isMainPage}
                    dict={dict}
                />
            </header>
        );
    }
}

Header.propTypes = {
    navItems: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number.isRequired, name: PropTypes.string, link: PropTypes.array, disabled: PropTypes.boolean })),
    user: PropTypes.shape({
        id: PropTypes.number,
        login: PropTypes.string,
        email: PropTypes.string,
        active: PropTypes.bool,
        role: PropTypes.shape({
            code: PropTypes.string,
            dictType: PropTypes.string,
            ord: PropTypes.number,
            value: PropTypes.string,
        })
    }),
    currentFilters: PropTypes.string,
    currentKey: PropTypes.string,
    currentPage: PropTypes.number,
};

const mapStateToProps = state => ({
    user: state.user.user,
    currentKey: state.results.params.q,
    currentFilters: state.results.params.filters,
    currentPage: state.results.params.page,
});

const mapDispatchToProps = dispatch => ({
    userActions: bindActionCreators(UserActions, dispatch),
    actions: bindActionCreators(Actions, dispatch)
});

export default withContexts(connect(mapStateToProps, mapDispatchToProps)(Header));
