import React, {Component} from 'react';
import withContexts from 'helpers/contextsMerge'
import { Link } from 'react-router-dom';
import {history} from 'helpers/history.js';

class Error extends Component {
  refreshPage = (e) => { 
    e.preventDefault();
    window.location.reload(); 
  }

  getGeneralInfo = () => {
    const { langContext } = this.props; //errMsg

    if (langContext) {
      const dict = langContext.dict;
      const langs = langContext.langs;

      if (dict && langs) {
        return <>
          <div className="error__apo">{dict[`error.apologize`]}</div>
          <div className="error__links">
              <div className="error__links__what">
                  {dict[`error.what_now`]}
              </div>
              <a href="/" onClick={(e) => {e.preventDefault(); history.goBack()}} className="error__link">
                  {dict[`error.go_back`]}
              </a>
              <Link to={`/`} className="error__link">
                  {dict[`error.main_page`]}
              </Link>
              <Link to={`/sitemap`} className="error__link">
                  {dict[`error.page_map`]}
              </Link>
          </div>
        </>;

        // return <div>
        //   <button className="error__link" onClick={(e) => this.refreshPage(e)}>
        //       {dict[`error.refresh`]}
        //   </button>
        //   <span> {dict[`error.try_again`]}</span>
        // </div>;
      }
    }

    return <div className="error__global">
      <button className="error__button" onClick={(e) => this.refreshPage(e)}>
          Odśwież stronę
      </button>
      <span> lub spróbuj ponownie później.</span>
    </div>;
  }

  render() {
    const {children, title} = this.props;

    return (
      <section className="error">
        <div className="error__inner">
            <h1 className="error__header">{title}</h1>
            <div className="error__content">
                {children}
                {this.getGeneralInfo()}
            </div>
        </div>
      </section>
    );
  }
}

export default withContexts(Error);
