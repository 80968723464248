import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';

import { LangContext } from 'helpers/contexts';

import InputFieldColumn from 'components/form/InputFieldColumn.js';

import { putAnnotationModalSplit, annotationModalReset } from 'actions/annotation.actions';

import Loading from 'components/Loading.js';

import FormikReduxValidator from 'helpers/FormikReduxValidator';


const ModalAnnotationSplit = () => {
    const dispatch = useDispatch();
    const { dict } = useContext(LangContext);
    const { modalData } = useSelector(state => state.annotation);

    const { data: { singleAnnotations, singleTokens, doubleAnnotations, doubleTokens }, code, sentenceNo } = useSelector(state => state.annotation.editorData);

    const [tokenId, setTokenId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(false);

    const [wordNo, setWordNo] = useState(null);
    const [tokenName, setTokenName] = useState(null);
    const [tokens, setTokens] = useState(null);

    const [currentTokens, setCurrentTokens] = useState(null);
    const [currentAnnotations, setCurrentAnnotations] = useState(null);

    useEffect(() => {
        setCurrentAnnotations(!singleAnnotations ? doubleAnnotations : singleAnnotations);
    }, [singleAnnotations, doubleAnnotations]);

    useEffect(() => {
        setCurrentTokens(!singleTokens ? doubleTokens : singleTokens);
    }, [singleTokens, doubleTokens]);

    useEffect( () => {
        if (modalData){
            setTokenId(modalData.tokenId);
            setLoading(modalData.loading);
            setErrors(modalData.errors);
        }
    }, [modalData]);


    useEffect(() => {
        if (tokenId && currentAnnotations){
            setWordNo(currentAnnotations[tokenId].tokenNos[0].wordNo);
        }
    }, [tokenId, currentAnnotations]);

    useEffect(() => {
        if (wordNo && currentTokens) {
            setTokenName(currentTokens[`${wordNo}|0`].token);
            const derivedTokens = currentTokens[`${wordNo}|0`].derivedTokensIds;
            setTokens(derivedTokens ? derivedTokens.map(item => singleTokens[item].token) : [singleTokens[`${wordNo}|0`].token]);
        }
    }, [wordNo]);

    useEffect(() => {
        // remove annotation modal data on modal close
        return () => dispatch(annotationModalReset());
    }, []);

    const handleSubmit = (values) => {
        dispatch(putAnnotationModalSplit({
            code,
            sentenceNo,
            wordNo,
            partition: values.splittedToken
        }));
    }

    if (!tokenId) {
        return <Loading />
    }


    return (
        <div className="modal--form__inner">
            <div className="ann-modal__header">
                <div className="ann-modal__header--name">Token: </div>
                <div className="ann-modal__header--content">{tokenName ? tokenName : ''}</div>
            </div>
            { (loading || !wordNo || !tokens || !tokenName) ? (
                <Loading />
            ) : (
                <div className="ann-modal__edit-form">
                    { errors && errors.annotationSplit &&
                        <div className="ann-modal__edit-form__error">{errors.annotationSplit}</div>
                    }
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            splittedToken: tokens.join('|'),
                        }}
                        // validate={validate}
                        onSubmit={handleSubmit}
                    >
                        {() => (
                            <Form className="ann-modal__split__form" style={(loading) ? { display: 'none' } : {}}>

                                <Field
                                    type="text"
                                    name="splittedToken"
                                    component={InputFieldColumn}
                                    label={dict[`annotation.modal.splitComment`]}
                                    autoFocus
                                    tabIndex="101"
                                />

                                <button type="submit"
                                    // disabled={isSubmitting}
                                    // tabIndex={5}
                                    tabIndex="102"
                                    className="modal--form__button"
                                >
                                    {dict[`form.save`]}
                                </button>
                                <FormikReduxValidator errors={errors} />
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </div>
    )
}
export default ModalAnnotationSplit
