import React, { memo } from 'react';
import Select from 'react-select';

const SelectField = ({
	field,
	tabIndex,
	disabled,
	options,
	handleChange,
	autoFocus = false,
    propName = null,
    defaultValue = null,
    additionalClassName = '',
    openMenuOnClick = true,
    dict,

    // getInitialValue = false,
    // menuIsOpen,
    // form: {
	// 	touched,
	// 	errors
	// },

}) => {
	// const getMenuIsOpened = useCallback(() => menuIsOpen ? menuIsOpen : false, []);
	const customStyles = {
		// option: (provided, state) => ({
		// 	...provided,
		// }),
		control: () => ({}),
		menu: () => ({}),
		option: () => ({}),
		//menuList: () => ({}),
		valueContainer: () => ({}),
		dropdownIndicator: () => ({}),
		singleValue: () => ({}),
		input: () => ({}),
	}

	return (
		<div className={`form__field ${additionalClassName}`} >
			<label htmlFor={field.name} className={`form__field__label`}>
                { propName ? (
                    <>{propName}</>
                ) : (
                    <>{dict[`form.${field.name}`]}:</>
                )}

    		</label>
			<Select
				{...field}
				{...(options.filter(option => option.value === field.value)[0] ? {value: options.filter(option => option.value === field.value)[0]} : {})}

				clearable={false}
				deleteRemoves={false}
				backspaceRemoves={false}
				onChange={handleChange}
				styles={customStyles}
				placeholder={""}
				options={options}
				autoFocus={autoFocus}
				isDisabled={disabled}
				tabIndex={tabIndex}
				classNamePrefix="select--table"
				className={`${disabled ? 'disabled' : ''} form__field__select`}
				noOptionsMessage={() => <span className="select--nooption">Brak opcji</span>}
                defaultValue={defaultValue}
                openMenuOnClick={openMenuOnClick}
			/>
			{/* {touched[field.name] && errors[field.name] &&
				<div className="form__field__error">
					{errors[field.name]}
				</div>} */}
		</div>
	);
}

export default memo(SelectField);
