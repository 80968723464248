import React, { useState, useEffect } from 'react';
import { Progress } from 'react-sweet-progress';

const FileUploadLoader = ({file}) => {

    // const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [reader, setReader] = useState();

    useEffect( () => {
        if (file) {
            setReader(new FileReader());
        }
        
    }, [file]);

    useEffect( () => {
        if (reader && file){
            reader.onloadstart = () => {
                // setLoading(true);
                setProgress(0);
            }
            reader.onprogress = (ev) => {
                setProgress(Math.round((ev.loaded / ev.total ) * 100));
            }
            reader.onloadend = () => {
            //    setLoading(false);
               setProgress(100);
            };
            reader.readAsDataURL(file);
        }
    }, [reader, file])

    return ( 
        ( !! progress && (
            <Progress percent={progress} />
        ))        
    ); 
}
export default FileUploadLoader;