const initialState = {
    modalAdditionalInfo: {},
    tree: [],
    isLoading: false,
    sourcesResult: null,
    deleteIsPending: false,
}
export default function manageSources(state = initialState, action) {
    switch (action.type) {
        case 'SET_SOURCE_TREE':
            return {
                ...state,
                tree: action.data
            }
        case 'SOURCES_RESULT':
            return {
                ...state,
                sourcesResult: action.data
            }

        case 'SET_SOURCES_COMPARISON':
            return {
                ...state,
                sourcesComparison: action.data
            }

        case 'SET_SOURCE_LOADING':
            return {
                ...state,
                isLoading: action.data
            }

        case 'MODAL_ADDITIONAL_INFO':
            return {
                ...state,
                modalAdditionalInfo: action.data
            }
        case 'RECEIVE_TRANSCRIPTION_DELETION_CONFIRMATION':
            return {
                ...state,
                modalAdditionalInfo: {info :'transcription-deleted'}
            }
        case 'SET_DELETION_IS_PENDING':
            return {
                ...state,
                deletionIsPending: action.isPending
            }
        default:
            return state
    }
}
