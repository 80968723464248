import React, {memo} from 'react';
import Dropdown from 'components/elements/Dropdown.js';
import HeaderUserPanelLink from 'containers/header/HeaderUserPanelLink.js';

const HeaderUserPanel = ({ logout, user, dict }) => {
	if (localStorage.getItem('user') && user.role) {
		return (
			<div className="header__user-panel">
				<Dropdown btnText={user.login}>
					<div className="header__user-panel__inner">
						<ul className="header__user-panel__list">
							{(user.role.code === 'admin' || user.role.code === 'editor') &&
								<HeaderUserPanelLink path="/manage-texts" name={dict[`user.manage_texts`]} />}

							{user.role.code === 'admin' &&
								<HeaderUserPanelLink path="/users" name={dict[`user.manage_users`]} />}

							{(user.role.code === 'admin' || user.role.code === 'editor') &&
								<HeaderUserPanelLink path="/annotation" name={dict[`user.annotationtations`]} />}

							{(user.role.code === 'admin' || user.role.code === 'editor') &&
								<HeaderUserPanelLink path="/manage-sources" name={dict[`user.manage_sources`]} />}

							{user.role.code !== 'admin' &&
								<HeaderUserPanelLink path="/users" name={dict[`user.account`]} />}

							{(user.role.code === 'admin' || user.role.code === 'editor') &&
								<HeaderUserPanelLink path="/import-others" name={dict[`parallels.import`]} />}

						</ul>
						<div className="header__user-panel__buttons">
							<button onClick={() => logout()} className="header__user-panel__button" title={dict[`user.logout`]}>
								{dict[`user.logout`]}
							</button>
						</div>
					</div>
				</Dropdown>
			</div>
		)
	}
	return null;
}

export default memo(HeaderUserPanel);
