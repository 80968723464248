import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import withContexts from 'helpers/contextsMerge'
import * as Actions from 'actions';
import {Formik, Field, Form} from 'formik';
import FileUploadField from '../../components/form/FileUploadField.js';

class ModalUpdateOtherTrans extends Component {
  submitForm = (values) => {
    const {actions, transToUpdate} = this.props;
    actions.updateOtherTrans(values.file, transToUpdate.code, transToUpdate.designation);
  }

  componentWillUnmount() {
    const {actions} = this.props;
    actions.resetTextError();
  }

  render() {
    const {langContext: {
        dict
      }, actions, error, transToUpdate: {designation}} = this.props;

    const initialValues = {
        file: ''
    };

    return (
        <div className="modal--form__inner">
          <div className="modal--form__header">
            {dict[`manage_text.update_text`]}
          </div>
          <div className="modal--form__content">
            <div className="modal--form__info">
              {dict[`manage_text.update_other_trans`]} {designation}.
            </div>
            <Formik
              initialValues={initialValues}
              validate={values => {
                let errors = {};
                if (!values.file) {
                  errors.file = dict[`form.required`];
                }

                return errors;
              }}
              onSubmit={(values, {setSubmitting}) => {
                this.submitForm(values);
                setSubmitting(false);
             }}>
            {({resetForm, isSubmitting, errors, setFieldValue}) => (
              <Form>
                {error && <div className="modal--form__error">{error}</div>}
                {errors.form && <div className="modal--form__error">{errors.form}</div>}
                <div className="modal--form__field">
                  <Field type="file" name="file"
                    label={dict[`manage_text.form.file`]}
                    component={FileUploadField}
                    handleUpload={(event) => {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }}
                    />
                </div>
                <div className="modal--form__buttons">
                  <button
                    onClick={() => {
                      resetForm();
                      actions.resetUsersError();
                      actions.resetAppAlert();
                    }}
                    className="modal--form__button--clear" type="button">
                    {dict[`form.cancel`]}
                  </button>
                  <button type="submit" disabled={isSubmitting} className="modal--form__button">
                    {dict[`form.update`]}
                  </button>
                </div>
              </Form>)}
            </Formik>
          </div>
        </div>
    );
  }
}

ModalUpdateOtherTrans.propTypes = {
  error: PropTypes.string,
  transToUpdate: PropTypes.shape({
    code: PropTypes.string,
    designation: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  transToUpdate: state.otherTrans.transToUpdate,
  error: state.otherTrans.error,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default withContexts(connect(mapStateToProps, mapDispatchToProps)(ModalUpdateOtherTrans));
