export default [
	{
		id: 1,
		name: 'texts',
		link: ['/texts', '/texts/item'],
		disabled: false
	},
	{
		id: 2,
		name: 'sources',
		link: ['/sources', '/sources/compare', '/sources/search'],
		disabled: false
	},
	{
		id: 3,
		name: 'themes',
		link: ['/themes', '/themes/search'],
		disabled: false
	},
	{
		id: 4,
		name: 'words',
		link: ['/words', '/search/words'],
		disabled: false
	},
	{
		id: 5,
		name: 'grammatical_forms',
		link: ['/grammar/single', '/search/grammar', '/grammar/double'],
		disabled: false
	}
]
