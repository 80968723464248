import React from 'react';
import withContexts from 'helpers/contextsMerge';

class Tooltip extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpened: false
		};
	}

	toggleOpened = () => {
		this.setState((state, props) => ({
			isOpened: !state.isOpened
		}))
	}
	onClose = () => {
		this.setState((state, props) => ({
			isOpened: false
		}))
	}

	render() {
		const { triggerClass, triggerIcon, window } = this.props;
		const { isOpened } = this.state;

		return (
			<div className="tooltip">
				<button type="button" className={triggerClass} onClick={this.toggleOpened}>
					{triggerIcon}
				</button>
				{isOpened &&
					<div className="tooltip__box">
						<div className="tooltip__box__header">
							{triggerIcon}
							<button className="modal__close" onClick={this.onClose}>
								<span className="icon-close"></span>
							</button>
						</div>
						<div className="tooltip__box__content">
							{window}
						</div>
					</div>}
			</div>
		);
	}
}

export default withContexts(Tooltip);