import React from 'react';
import logo from 'images/logo_pl.png';
import logoEn from 'images/logo_en.png';
import logoUAM from 'images/logo_UAM.svg';
import {Link} from 'react-router-dom';

const HeaderLogoSection = ({dict, currentLang='pl'}) => {
  return (
    <div className="header__logo-section">
      <div className="header__logo-section__main-link">
        <Link to={`/`} className="header__logo-section__main-link--underlined" title={dict[`breadcrumbs.main`]}>
          {currentLang === 'pl' && <img src={logo} alt={dict[`home.logo.cmwl`]} className="header__logo-section__main-logo"/>}
          {currentLang === 'en' && <img src={logoEn} alt={dict[`home.logo.cmwl`]} className="header__logo-section__main-logo"/>}
        </Link>
      </div>
      <div className="header__logo-section__separator"></div>
      <div className="header__logo-section__sup-link">
        <a
          href={dict[`addresses.uam`]}
          target="_blank"
          rel="noopener noreferrer"
          className="header__logo-section__main-link--underlined" title={dict[`addresses.uam.label`]}>
          <img src={logoUAM} alt={dict[`home.logo.uam`]} className="header__logo-section__sup-logo"/>
        </a>
      </div>
    </div>
  )
}

export default React.memo(HeaderLogoSection);
