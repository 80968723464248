const initialState = {
    text: {},
  };
  
  export default function text(state = initialState, action) {
    switch (action.type) {
      case 'RECEIVE_LOAD_TEXT':
        return {
          ...state,
          text: {...action.data}
        };   
      default:
        return state
    }
  }
  