import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { Link } from 'react-router-dom';

import HeaderSearchPanelInput from './HeaderSearchPanelInput';

const HeaderSearchPanel = ({ setSearchKey, isSearchOpened, currentKey, dict }) => {
    const initialValues = {
        // key: (!!currentKey ? currentKey : ""),
        key: ''
    };
    return (
        <div className={`header__top__search ${isSearchOpened ? 'opened' : ''}`}>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validate={values => {
                    let errors = {};
                    // if (!values.key) {
                    // errors.key = dict[`form.required`];
                    // }
                    return errors;
                }}
                // onSubmit={(values, { setSubmitting, resetForm }) => {
                onSubmit={(values, { setSubmitting }) => {
                    setSearchKey(values.key);
                    setSubmitting(false);
                    // resetForm();
                }}>
                {({ values, isSubmitting }) => (
                    <Form className="header__top__search__form">
                        {/* {error && <div className="login__error">{error}</div>} */}
                        <div className="header__top__form__field">
                            <Field
                                type="text"
                                name="key"
                                component={HeaderSearchPanelInput}
                                placeholder={dict[`results.search.phrase`]}
                                label={dict[`results.search.phrase`]}
                                dict={dict}
                            />
                        </div>
                        <div className="header__top__form__buttons">
                            <button type="submit" disabled={isSubmitting || (!values.key || !values.key.length)} className="header__top__form__button">
                                <span className="icon-search"></span>
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
            <Link to={"/about?id=2"} className="header__top__form__button">
                <span className="icon-question"></span>
            </Link>
        </div>
    )
}

HeaderSearchPanel.propTypes = {
    currentKey: PropTypes.string,
    isSearchOpened: PropTypes.bool,
    setSearchKey: PropTypes.func,
    dict: PropTypes.object,
}

export default React.memo(HeaderSearchPanel);
