import 'babel-polyfill';
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import {
    Router,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import loadingBarMiddleware from 'helpers/loadingBarMiddleware'
import thunkMiddleware from 'redux-thunk';
import { CookiesProvider } from 'react-cookie'
import { history } from 'helpers/history.js'
import ErrorBoundary from './components/errors/ErrorBoundary';
import "es6-promise/auto";
import "normalize.css";
import 'icheck/skins/square/_all.css';
import "react-sweet-progress/lib/style.css";
import "codemirror/lib/codemirror.css";

import App from './containers/App';
import './styles/main.sass';

import * as serviceWorker from './serviceWorker';

const composeEnhancers =
    typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const enhancer = composeEnhancers(
    applyMiddleware(thunkMiddleware, loadingBarMiddleware)
);

// const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, loadingBarMiddleware));
export const store = createStore(rootReducer, enhancer);

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <CookiesProvider>
                <Router history={history}>
                    <App />
                </Router>
            </CookiesProvider>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root'));

serviceWorker.unregister();
