import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withContexts from 'helpers/contextsMerge'
import * as Actions from 'actions/index';

class ModalDeleteOtherTrans extends Component {

	deleteTrans = () => {
		const { actions, transToDelete } = this.props;

		actions.deleteOtherTrans(transToDelete.code, transToDelete.designation);
	}

	render() {
		const { actions, langContext: { dict }, transToDelete } = this.props;

		return (
			<div className="modal--form__inner">
				<div className="modal--form__info">
					{dict[`manage_text.delete_other_transcript`]} "{transToDelete.designation}"?
            </div>
				<button onClick={() => actions.resetAppAlert()}
					className="modal--form__button--clear" type="button">
					{dict[`form.cancel`]}
				</button>
				<button type="submit" className="modal--form__button" onClick={() => this.deleteTrans()}>
					{dict[`manage_text.delete`]}
				</button>
			</div>
		);
	}
}

ModalDeleteOtherTrans.propTypes = {
	transToDelete: PropTypes.shape({
		code: PropTypes.string,
		designation: PropTypes.string,
	}),
};

const mapStateToProps = state => ({
	transToDelete: state.otherTrans.transToDelete,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withContexts(connect(mapStateToProps, mapDispatchToProps)(ModalDeleteOtherTrans));
